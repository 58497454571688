import React, { createContext, useState, useEffect } from 'react';

// Define translations
const translations = {
  ru: {
    // Navigation
    locations: 'Локации',
    about: 'О нас',
    contact: 'Контакты',
    viewProperties: 'Смотреть объекты',
    realEstateInBulgaria: 'Недвижимость в Болгарии',
    
    // Contact form
    yourName: 'Ваше имя',
    yourEmail: 'Ваш Email',
    yourMessage: 'Ваше сообщение',
    send: 'Отправить',
    contactUs: 'Связаться с нами',
    
    // Property details
    description: 'Описание',
    contactInfo: 'Контактная информация',
    callUs: 'Позвоните нам',
    emailUs: 'Напишите нам',
    backToLocations: 'Обратно к локациям',
    backTo: 'Обратно к ',
    propertiesInLocation: 'объектов в этой локации',
    searchProperties: 'Поиск объектов',
    clearSearch: 'Очистить поиск',
    noPropertiesFound: 'Нет найденных объектов',
    noPropertiesInLocation: 'В этой локации пока нет объектов',
    viewDetails: 'Смотреть детали',
    photos: 'Фотографии',
    viewFullGallery: 'Смотреть галерею',
    
    // Contact section
    contactSection: 'Контакты',
    workingHours: 'Рабочее время',
    mondayToFriday: 'Понедельник - Пятница',
    saturday: 'Суббота',
    sunday: 'Воскресенье',
    byAppointment: 'По записи',
    contactForMoreInfo: 'Свяжитесь с нами для получения дополнительной информации об этом объекте или для организации просмотра.',
    
    // Footer
    home: 'Главная',
    allRightsReserved: 'Все права защищены',
    
    // About section
    yearsOfExperience: 'Лет опыта',
    locationsCount: 'Локаций',
    propertiesCount: 'Объектов',
    aboutPerson: 'Ирина Бервина',
    aboutContentP1: 'С 2010 года БИЛДВИНД объединяет группу компаний, включая в себя несколько строительных фирм, фирму недвижимости, консалтинговую компанию, а также фирму по сопровождению финансовых, бухгалтерских и юридических вопросов. Мы строим качественное жилье, подходящее для постоянного проживания в Болгарии. Стараемся подобрать каждому нашему клиенту оптимальный вариант, обрести "второй дом" в Болгарии. Будем Вам очень рады!',
    aboutContentP2: 'Мы напрямую, без посредников, работаем со строителями и инвесторами, реализующими девелоперские проекты в курортных зонах Болгарии, тем самым предлагая Вам наиболее привлекательные цены, условия и варианты покупки. Все застройщики, с которыми работает компания «БИЛДВИНД» обладают хорошей репутацией и предлагают большой выбор объектов недвижимости.',
    aboutContentP3: 'На нашем сайте представлена обширная база предложений по новостройкам, вторичным объектам, гостиницам, земельным участкам различного назначения, кроме того, задействовав наших партнеров, мы всегда поможем найти именно ту недвижимость, которую Вы так долго искали.',
    
    // Admin panel
    adminPanel: 'Административная панель',
    addLocation: 'Добавить локацию',
    addProperty: 'Добавить объект',
    totalLocations: 'Всего локаций',
    totalProperties: 'Всего объектов',
    dashboard: 'Главная',
    uploads: 'Загрузки файлов',
    contactSubmissions: 'Заявки на связь',
    totalSubmissions: 'Всего заявок',
    newSubmissions: 'Новые заявки',
    name: 'Имя',
    email: 'Email',
    message: 'Сообщение',
    date: 'Дата',
    actions: 'Действия',
    markAsRead: 'Отметить как прочитанное',
    delete: 'Удалить',
    noSubmissions: 'Нет заявок',
    confirmDelete: 'Вы уверены, что хотите удалить эту заявку?',
    logout: 'Выйти',
    back: 'Назад',
    cancel: 'Отмена',
    save: 'Сохранить',
    saving: 'Сохранение...',
    locationName: 'Название локации',
    propertyName: 'Название объекта',
    editLocation: 'Редактировать локацию',
    addNewLocation: 'Добавить новую локацию',
    editProperty: 'Редактировать объект',
    addNewProperty: 'Добавить новый объект',
    image: 'Изображение',
    username: 'Имя пользователя',
    password: 'Пароль',
    login: 'Войти',
    enterYourUsername: 'Введите имя пользователя',
    enterYourPassword: 'Введите пароль',
    errorOccurred: 'Произошла ошибка. Пожалуйста, попробуйте снова.'
  },
  bg: {
    // Navigation
    locations: 'Локации',
    about: 'За нас',
    contact: 'Контакти',
    viewProperties: 'Преглед на имоти',
    realEstateInBulgaria: 'Недвижими имоти в България',
    
    // Contact form
    yourName: 'Вашето име',
    yourEmail: 'Вашият имейл',
    yourMessage: 'Вашето съобщение',
    send: 'Изпрати',
    contactUs: 'Свържете се с нас',
    
    // Property details
    description: 'Описание',
    contactInfo: 'За контакт',
    callUs: 'Обадете ни се',
    emailUs: 'Изпратете имейл',
    backToLocations: 'Обратно към локациите',
    backTo: 'Обратно към ',
    propertiesInLocation: 'имота в тази локация',
    searchProperties: 'Търсене на имоти',
    clearSearch: 'Изчисти търсенето',
    noPropertiesFound: 'Няма намерени имоти',
    noPropertiesInLocation: 'Все още няма имоти в тази локация',
    viewDetails: 'Виж детайли',
    photos: 'Снимки',
    viewFullGallery: 'Преглед на галерия',
    
    // Contact section
    contactSection: 'За контакт',
    workingHours: 'Работно време',
    mondayToFriday: 'Понеделник - Петък',
    saturday: 'Събота',
    sunday: 'Неделя',
    byAppointment: 'По уговорка',
    contactForMoreInfo: 'Свържете се с нас за повече информация относно този имот или за да уговорите оглед.',
    
    // Footer
    home: 'Начало',
    allRightsReserved: 'Всички права запазени',
    
    // About section
    yearsOfExperience: 'Години опит',
    locationsCount: 'Локации',
    propertiesCount: 'Имота',
    aboutPerson: 'Ирина Бервина',
    aboutContentP1: 'От 2010 година BUILDVIND обединява група компании, включваща няколко строителни фирми, фирма за недвижими имоти, консултантска компания, както и фирма за подпомагане по финансови, счетоводни и правни въпроси. Ние строим качествени жилища, подходящи за постоянно пребиваване в България. Стремим се да подберем оптимален вариант за всеки наш клиент, да намери "втори дом" в България. Ще се радваме да Ви посрещнем!',
    aboutContentP2: 'Ние работим директно, без посредници, със строители и инвеститори, реализиращи проекти в курортните зони на България, като по този начин Ви предлагаме най-атрактивните цени, условия и варианти за покупка. Всички строители, с които работи компания "BUILDVIND", имат добра репутация и предлагат голям избор от имоти.',
    aboutContentP3: 'На нашия сайт е представена обширна база от оферти за нови строежи, вторични имоти, хотели, парцели с различно предназначение, освен това, чрез нашите партньори, винаги ще Ви помогнем да намерите точно този имот, който отдавна търсите.',
    
    // Admin panel
    adminPanel: 'Административен панел',
    addLocation: 'Добави локация',
    addProperty: 'Добави имот',
    totalLocations: 'Общо локации',
    totalProperties: 'Общо имоти',
    dashboard: 'Начало',
    uploads: 'Качване на файлове',
    contactSubmissions: 'Заявки за връзка',
    totalSubmissions: 'Общо заявки',
    newSubmissions: 'Нови заявки',
    name: 'Име',
    email: 'Имейл',
    message: 'Съобщение',
    date: 'Дата',
    actions: 'Действия',
    markAsRead: 'Маркирай като прочетено',
    delete: 'Изтрий',
    noSubmissions: 'Няма заявки',
    confirmDelete: 'Сигурни ли сте, че искате да изтриете тази заявка?',
    logout: 'Излез',
    back: 'Назад',
    cancel: 'Отказ',
    save: 'Запази',
    saving: 'Запазване...',
    locationName: 'Име на локация',
    propertyName: 'Име на имот',
    editLocation: 'Редактирай локация',
    addNewLocation: 'Добави нова локация',
    editProperty: 'Редактирай имот',
    addNewProperty: 'Добави нов имот',
    image: 'Изображение',
    username: 'Потребителско име',
    password: 'Парола',
    login: 'Вход',
    enterYourUsername: 'Въведете потребителско име',
    enterYourPassword: 'Въведете парола',
    errorOccurred: 'Възникна грешка. Моля, опитайте отново.'
  }
};

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('ru'); // Default to Russian
  
  // Try to load language preference from localStorage on initial load
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage && (savedLanguage === 'ru' || savedLanguage === 'bg')) {
      setLanguage(savedLanguage);
    }
  }, []);
  
  // Save language preference to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);
  
  const toggleLanguage = () => {
    setLanguage(prevLang => prevLang === 'ru' ? 'bg' : 'ru');
  };
  
  // Modified translation function to handle fallback
  const t = (key, customTranslations = null) => {
    // If custom translations are provided, check them first
    if (customTranslations) {
      // If Bulgarian is selected and we have a non-empty Bulgarian translation, use it
      if (language === 'bg' && customTranslations.bg && customTranslations.bg[key]) {
        return customTranslations.bg[key];
      }
      
      // If Russian is selected and we have a Russian translation, use it
      if (language === 'ru' && customTranslations.ru && customTranslations.ru[key]) {
        return customTranslations.ru[key];
      }
    }
    
    // Standard translation lookup for built-in translations
    const currentTranslation = translations[language][key];
    
    // If we're in Bulgarian mode but the translation is empty/undefined, fall back to Russian
    if (language === 'bg' && (!currentTranslation || currentTranslation === '')) {
      return translations['ru'][key] || key;
    }
    
    // Otherwise return the current language translation or the key itself as fallback
    return currentTranslation || key;
  };
  
  return (
    <LanguageContext.Provider value={{ language, toggleLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
}; 