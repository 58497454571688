import { createClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';

const supabaseUrl = 'https://dquiazpzwpnuphchjslo.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImRxdWlhenB6d3BudXBoY2hqc2xvIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDM3OTE0OTQsImV4cCI6MjA1OTM2NzQ5NH0.uN3XRNOCpN_sze6DbmZZ6LE7gVh3MD8QyFmLCaHtS2E';

export const supabase = createClient(supabaseUrl, supabaseKey, {
  realtime: {
    params: {
      eventsPerSecond: 10
    }
  }
});

export const STORAGE_BUCKET = 'images';

// Setup and test realtime subscriptions
export const setupRealtimeSubscriptions = async () => {
  console.log('Setting up realtime subscriptions...');
  
  try {
    // Test if realtime is properly enabled in the database
    const realtimeChannel = supabase.channel('realtime-test');
    
    // Test general schema changes
    const schemaSubscription = supabase.channel('schema-db-changes')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
      }, payload => {
        console.log('Schema change detected:', payload);
      })
      .subscribe(status => {
        console.log('Schema subscription status:', status);
      });
    
    // Setup specific table subscriptions
    const locationsSubscription = supabase.channel('public:locations')
      .on('postgres_changes', { 
        event: '*', 
        schema: 'public', 
        table: 'locations' 
      }, payload => {
        console.log('Location change detected:', payload);
      })
      .subscribe(status => {
        console.log('Locations subscription status:', status);
      });
    
    const realEstatesSubscription = supabase.channel('public:real_estates')
      .on('postgres_changes', { 
        event: '*', 
        schema: 'public', 
        table: 'real_estates' 
      }, payload => {
        console.log('Real estate change detected:', payload);
      })
      .subscribe(status => {
        console.log('Real estates subscription status:', status);
      });
    
    const contactSubmissionsSubscription = supabase.channel('public:contact_submissions')
      .on('postgres_changes', { 
        event: '*', 
        schema: 'public', 
        table: 'contact_submissions' 
      }, payload => {
        console.log('Contact submission change detected:', payload);
      })
      .subscribe(status => {
        console.log('Contact submissions subscription status:', status);
      });
    
    return () => {
      schemaSubscription.unsubscribe();
      locationsSubscription.unsubscribe();
      realEstatesSubscription.unsubscribe();
      contactSubmissionsSubscription.unsubscribe();
    };
  } catch (error) {
    console.error('Error setting up realtime subscriptions:', error);
    return () => {};
  }
};

// Helper functions for database operations

// Locations
export const fetchLocations = async () => {
  const { data, error } = await supabase
    .from('locations')
    .select('*')
    .order('order');
    
  if (error) {
    console.error('Error fetching locations:', error);
    return [];
  }
  return data || [];
};

export const createLocation = async (location) => {
  try {
    // Validate input
    if (!location || !location.name) {
      console.error('Invalid location data:', location);
      throw new Error('Неверные данные локации');
    }

    // Add required fields if not present
    const locationToCreate = {
      ...location,
      id: location.id || uuidv4(),
      created_at: new Date().toISOString()
    };

    // Create the location in Supabase
    const { data, error } = await supabase
      .from('locations')
      .insert(locationToCreate)
      .select()
      .single();
      
    if (error) {
      console.error('Error creating location:', error);
      if (error.code === '23505') {
        throw new Error('Такая локация уже существует');
      }
      throw new Error(`Ошибка сохранения локации: ${error.message}`);
    }
    
    // Check if we got data back
    if (!data) {
      console.error('No data returned from Supabase after location creation');
      throw new Error('Ошибка сохранения: нет ответа от сервера');
    }
    
    console.log('Successfully created location:', data);
    return data;
  } catch (error) {
    console.error('Error in createLocation:', error);
    // Re-throw the error to be handled by the caller
    throw error;
  }
};

export const updateLocation = async (id, location) => {
  try {
    // Validate input
    if (!id || !location) {
      console.error('Invalid location update data:', { id, location });
      throw new Error('Неверные данные для обновления локации');
    }

    // Add updated_at timestamp
    const locationToUpdate = {
      ...location,
      updated_at: new Date().toISOString()
    };

    // Update the location in Supabase
    const { data, error } = await supabase
      .from('locations')
      .update(locationToUpdate)
      .eq('id', id)
      .select()
      .single();
      
    if (error) {
      console.error('Error updating location:', error);
      if (error.code === '23505') {
        throw new Error('Локация с таким названием уже существует');
      }
      throw new Error(`Ошибка обновления локации: ${error.message}`);
    }
    
    // Check if we got data back
    if (!data) {
      console.error('No data returned from Supabase after location update');
      throw new Error('Ошибка обновления: нет ответа от сервера');
    }
    
    console.log('Successfully updated location:', data);
    return data;
  } catch (error) {
    console.error('Error in updateLocation:', error);
    // Re-throw the error to be handled by the caller
    throw error;
  }
};

export const deleteLocation = async (id) => {
  const { error } = await supabase
    .from('locations')
    .delete()
    .eq('id', id);
    
  if (error) {
    console.error('Error deleting location:', error);
    return false;
  }
  return true;
};

// Real Estates
export const fetchRealEstates = async () => {
  const { data, error } = await supabase
    .from('real_estates')
    .select('*')
    .order('order');
    
  if (error) {
    console.error('Error fetching real estates:', error);
    return [];
  }
  
  return data || [];
};

// Direct insertion using RPC (most reliable method)
export const directInsertRealEstate = async (realEstate) => {
  try {
    // Ensure images is properly formatted as an array
    let formattedImages = realEstate.images || [];
    if (!Array.isArray(formattedImages)) {
      if (typeof formattedImages === 'string') {
        formattedImages = [formattedImages];
      } else {
        formattedImages = [];
      }
    }
    
    // Filter out empty strings in the images array
    formattedImages = formattedImages.filter(img => img && img.trim() !== '');
    
    // Create a direct object with only the fields in the database schema
    const realEstateData = {
      p_title: realEstate.title,
      p_description: realEstate.description,
      p_price: realEstate.price || '',
      p_area: realEstate.area || '',
      p_location_id: realEstate.location_id,
      p_images: formattedImages,
      p_order: realEstate.order || 0
    };
    
    console.log('Calling RPC with data:', realEstateData);
    
    // Call the SQL function via RPC
    const { data, error } = await supabase.rpc('direct_insert_real_estate', realEstateData);
    
    if (error) {
      console.error('Error in direct insert RPC:', error);
      throw new Error(`Failed to insert real estate: ${error.message}`);
    }
    
    console.log('Successfully inserted real estate via RPC:', data);
    return data;
  } catch (error) {
    console.error('Error in directInsertRealEstate:', error);
    throw error;
  }
};

export const createRealEstate = async (realEstate) => {
  try {
    // Validate input
    if (!realEstate || !realEstate.title) {
      console.error('Invalid real estate data:', realEstate);
      throw new Error('Неверные данные объекта недвижимости');
    }

    // Ensure images is properly formatted as an array
    let formattedImages = realEstate.images || [];
    if (!Array.isArray(formattedImages)) {
      if (typeof formattedImages === 'string') {
        formattedImages = [formattedImages];
      } else {
        formattedImages = [];
      }
    }
    
    // Filter out empty strings in the images array
    formattedImages = formattedImages.filter(img => img && img.trim() !== '');

    // Create a clean object that matches the database schema EXACTLY
    // Only include fields that actually exist in the database
    const realEstateToCreate = {
      title: realEstate.title,
      description: realEstate.description,
      price: realEstate.price || '',
      area: realEstate.area || '',
      location_id: realEstate.location_id,
      images: formattedImages,
      order: realEstate.order || 0,
      created_at: new Date().toISOString()
    };

    console.log('Creating real estate with data:', realEstateToCreate);

    // Create the real estate in Supabase
    const { data, error } = await supabase
      .from('real_estates')
      .insert(realEstateToCreate)
      .select()
      .single();
      
    if (error) {
      console.error('Error creating real estate:', error);
      throw new Error(`Ошибка создания объекта: ${error.message}`);
    }
    
    // Check if we got data back
    if (!data) {
      console.error('No data returned from Supabase after real estate creation');
      throw new Error('Ошибка сохранения: нет ответа от сервера');
    }
    
    console.log('Successfully created real estate:', data);
    return data;
  } catch (error) {
    console.error('Error in createRealEstate:', error);
    throw error;
  }
};

export const updateRealEstate = async (id, realEstate) => {
  try {
    // Validate input
    if (!id || !realEstate) {
      console.error('Invalid real estate update data:', { id, realEstate });
      throw new Error('Неверные данные для обновления объекта');
    }

    // Ensure images is properly formatted as an array
    let formattedImages = realEstate.images || [];
    if (!Array.isArray(formattedImages)) {
      if (typeof formattedImages === 'string') {
        formattedImages = [formattedImages];
      } else {
        formattedImages = [];
      }
    }
    
    // Filter out empty strings in the images array
    formattedImages = formattedImages.filter(img => img && img.trim() !== '');

    // Create a clean object that matches the database schema EXACTLY
    const realEstateToUpdate = {
      title: realEstate.title,
      description: realEstate.description,
      price: realEstate.price || '',
      area: realEstate.area || '',
      location_id: realEstate.location_id,
      images: formattedImages,
      updated_at: new Date().toISOString()
    };

    // Add optional fields if they exist
    if (realEstate.order !== undefined) realEstateToUpdate.order = realEstate.order;

    console.log('Updating real estate with data:', realEstateToUpdate);

    // Update the real estate in Supabase
    const { data, error } = await supabase
      .from('real_estates')
      .update(realEstateToUpdate)
      .eq('id', id)
      .select()
      .single();
      
    if (error) {
      console.error('Error updating real estate:', error);
      throw new Error(`Ошибка обновления объекта: ${error.message}`);
    }
    
    // Check if we got data back
    if (!data) {
      console.error('No data returned from Supabase after real estate update');
      throw new Error('Ошибка обновления: нет ответа от сервера');
    }
    
    console.log('Successfully updated real estate:', data);
    return data;
  } catch (error) {
    console.error('Error in updateRealEstate:', error);
    throw error;
  }
};

export const deleteRealEstate = async (id) => {
  const { error } = await supabase
    .from('real_estates')
    .delete()
    .eq('id', id);
    
  if (error) {
    console.error('Error deleting real estate:', error);
    return false;
  }
  return true;
};

// Contact Submissions
export const fetchContactSubmissions = async () => {
  const { data, error } = await supabase
    .from('contact_submissions')
    .select('*')
    .order('created_at', { ascending: false });
    
  if (error) {
    console.error('Error fetching contact submissions:', error);
    return [];
  }
  return data || [];
};

export const createContactSubmission = async (submission) => {
  const { data, error } = await supabase
    .from('contact_submissions')
    .insert(submission)
    .select()
    .single();
    
  if (error) {
    console.error('Error creating contact submission:', error);
    return null;
  }
  return data;
};

export const updateContactSubmission = async (id, submission) => {
  const { data, error } = await supabase
    .from('contact_submissions')
    .update(submission)
    .eq('id', id)
    .select()
    .single();
    
  if (error) {
    console.error('Error updating contact submission:', error);
    return null;
  }
  return data;
};

export const deleteContactSubmission = async (id) => {
  const { error } = await supabase
    .from('contact_submissions')
    .delete()
    .eq('id', id);
    
  if (error) {
    console.error('Error deleting contact submission:', error);
    return false;
  }
  return true;
};

// Authentication
export const login = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  
  if (error) {
    console.error('Error logging in:', error);
    return null;
  }
  return data;
};

export const logout = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.error('Error logging out:', error);
    return false;
  }
  return true;
};

// File storage
export const uploadImage = async (file, filePath) => {
  let retryCount = 0;
  const maxRetries = 3;
  
  const performUpload = async () => {
    try {
      // First ensure we're authenticated for storage operations
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        console.warn('No authenticated session found, attempting anonymous upload');
      }
      
      // Check if bucket exists
      const { data: buckets, error: bucketsError } = await supabase.storage.listBuckets();
      
      if (bucketsError) {
        console.error('Error checking buckets:', bucketsError);
        throw new Error(`Storage error: ${bucketsError.message}`);
      }
      
      console.log('Available buckets:', buckets.map(b => b.name));
      
      // Check if our bucket exists (case-insensitive)
      const exactBucket = buckets.find(bucket => bucket.name === STORAGE_BUCKET);
      const caseInsensitiveBucket = buckets.find(bucket => 
        bucket.name.toLowerCase() === STORAGE_BUCKET.toLowerCase()
      );
      
      let actualBucketName = null;
      
      // If bucket doesn't exist, try to create it
      if (!exactBucket && !caseInsensitiveBucket) {
        console.warn(`Bucket '${STORAGE_BUCKET}' not found, trying to create it...`);
        
        try {
          const { data, error } = await supabase.storage.createBucket(STORAGE_BUCKET, {
            public: true,
            fileSizeLimit: 10485760 // 10MB
          });
          
          if (error) {
            console.error('Failed to create bucket:', error);
            throw new Error(`Failed to create bucket: ${error.message}`);
          }
          
          console.log('Successfully created bucket:', data);
          actualBucketName = STORAGE_BUCKET;
        } catch (createError) {
          console.error('Error creating bucket:', createError);
          throw new Error(`Failed to create bucket: ${createError.message}`);
        }
      } else {
        // Use existing bucket
        actualBucketName = exactBucket ? exactBucket.name : caseInsensitiveBucket.name;
        
        if (actualBucketName !== STORAGE_BUCKET) {
          console.warn(`Case mismatch in bucket name. Expected: '${STORAGE_BUCKET}', Found: '${actualBucketName}'`);
        }
        
        // Update bucket to ensure it's public
        try {
          await supabase.storage.updateBucket(actualBucketName, {
            public: true,
            fileSizeLimit: 10485760 // 10MB
          });
          console.log('Updated bucket permissions to public');
        } catch (updateError) {
          console.warn('Could not update bucket permissions:', updateError);
          // Continue anyway
        }
      }
      
      // Ensure filePath is properly formatted
      let cleanFilePath = filePath;
      if (cleanFilePath.startsWith('/')) {
        cleanFilePath = cleanFilePath.substring(1);
      }
      
      // Proceed with upload
      console.log(`Uploading file to bucket: ${actualBucketName}, path: ${cleanFilePath}`);
      
      // Make sure file is actually a File or Blob object
      if (!(file instanceof Blob || file instanceof File)) {
        throw new Error('Invalid file object provided for upload');
      }
      
      const { data, error } = await supabase.storage
        .from(actualBucketName)
        .upload(cleanFilePath, file, {
          cacheControl: '3600',
          upsert: true
        });
        
      if (error) {
        console.error('Error uploading file:', error);
        throw error;
      }
      
      if (!data || !data.path) {
        console.error('Upload returned no data or path');
        throw new Error('Upload failed: No data returned from server');
      }
      
      console.log('Upload successful:', data);
      
      // Get public URL
      const { data: publicUrlData } = supabase.storage
        .from(actualBucketName)
        .getPublicUrl(data.path);
      
      if (!publicUrlData || !publicUrlData.publicUrl) {
        console.error('Failed to get public URL');
        throw new Error('Failed to get public URL for uploaded file');
      }
      
      console.log('Public URL:', publicUrlData.publicUrl);
      return publicUrlData.publicUrl;
    } catch (error) {
      console.error('Upload error:', error);
      
      // Check if we should retry
      if (retryCount < maxRetries) {
        retryCount++;
        console.log(`Retrying upload (attempt ${retryCount} of ${maxRetries})...`);
        
        // Exponential backoff: wait longer between each retry
        const delay = Math.pow(2, retryCount) * 1000;
        await new Promise(resolve => setTimeout(resolve, delay));
        
        return performUpload();
      } else {
        throw error;
      }
    }
  };
  
  return performUpload();
};

export const deleteImage = async (filePath) => {
  try {
    // First ensure we're authenticated for storage operations
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) {
      console.warn('No authenticated session found, attempting anonymous deletion');
    }
    
    // Check if bucket exists
    const { data: buckets, error: bucketsError } = await supabase.storage.listBuckets();
    
    if (bucketsError) {
      console.error('Error checking buckets:', bucketsError);
      throw new Error(`Storage error: ${bucketsError.message}`);
    }
    
    // Check if our bucket exists (case-insensitive)
    const exactBucket = buckets.find(bucket => bucket.name === STORAGE_BUCKET);
    const caseInsensitiveBucket = buckets.find(bucket => 
      bucket.name.toLowerCase() === STORAGE_BUCKET.toLowerCase()
    );
    
    let actualBucketName = null;
    
    // If bucket doesn't exist, try to create it
    if (!exactBucket && !caseInsensitiveBucket) {
      console.warn(`Bucket '${STORAGE_BUCKET}' not found, trying to create it...`);
      
      try {
        const { data, error } = await supabase.storage.createBucket(STORAGE_BUCKET, {
          public: true,
          fileSizeLimit: 10485760 // 10MB
        });
        
        if (error) {
          console.error('Failed to create bucket:', error);
          throw new Error(`Failed to create bucket: ${error.message}`);
        }
        
        console.log('Successfully created bucket:', data);
        actualBucketName = STORAGE_BUCKET;
      } catch (createError) {
        console.error('Error creating bucket:', createError);
        throw new Error(`Failed to create bucket: ${createError.message}`);
      }
    } else {
      // Use existing bucket
      actualBucketName = exactBucket ? exactBucket.name : caseInsensitiveBucket.name;
      
      if (actualBucketName !== STORAGE_BUCKET) {
        console.warn(`Case mismatch in bucket name. Expected: '${STORAGE_BUCKET}', Found: '${actualBucketName}'`);
      }
    }
    
    // Proceed with deletion
    console.log(`Deleting file from bucket: ${actualBucketName}, path: ${filePath}`);
    const { error } = await supabase.storage
      .from(actualBucketName)
      .remove([filePath]);
      
    if (error) {
      console.error('Error deleting file:', error);
      return false;
    }
    
    console.log('Deletion successful');
    return true;
  } catch (error) {
    console.error('Error in deleteImage:', error);
    return false;
  }
};

// Initialize storage on load
export const setupStorage = async () => {
  try {
    console.log('Setting up Supabase storage...');
    
    // Check authentication
    const { data: authData } = await supabase.auth.getSession();
    const isAuthenticated = !!authData.session;
    console.log('Authentication status:', isAuthenticated ? 'Authenticated' : 'Not authenticated');
    
    // List buckets
    const { data: buckets, error: bucketsError } = await supabase.storage.listBuckets();
    
    if (bucketsError) {
      console.error('Error checking buckets:', bucketsError);
      return false;
    }
    
    // Check if our bucket exists
    const bucketExists = buckets.some(bucket => 
      bucket.name.toLowerCase() === STORAGE_BUCKET.toLowerCase()
    );
    
    if (!bucketExists) {
      console.log(`Bucket '${STORAGE_BUCKET}' not found, creating...`);
      
      // Create the bucket
      const { error: createError } = await supabase.storage.createBucket(STORAGE_BUCKET, {
        public: true,
        fileSizeLimit: 10485760 // 10MB
      });
      
      if (createError) {
        console.error('Failed to create bucket:', createError);
        return false;
      }
      
      console.log(`Bucket '${STORAGE_BUCKET}' created successfully`);
      
      // Add permissive policies for the new bucket
      await createStoragePolicies();
    } else {
      console.log(`Bucket '${STORAGE_BUCKET}' already exists`);
      
      // Update bucket to ensure it's public
      try {
        const { error: updateError } = await supabase.storage.updateBucket(STORAGE_BUCKET, {
          public: true,
          fileSizeLimit: 10485760 // 10MB
        });
        
        if (updateError) {
          console.warn('Could not update bucket permissions:', updateError);
        } else {
          console.log('Updated bucket permissions to public');
          
          // Check if we need to update policies
          await createStoragePolicies();
        }
      } catch (error) {
        console.warn('Error updating bucket:', error);
      }
    }
    
    console.log('Storage setup complete');
    return true;
  } catch (error) {
    console.error('Error setting up storage:', error);
    return false;
  }
};

// Create storage policies that allow anyone to upload
const createStoragePolicies = async () => {
  try {
    console.log('Setting up permissive storage policies...');
    
    // We need to execute SQL to create the policies
    // This requires admin privileges, so we'll use REST API to do this
    // instead of the SQL editor
    
    // First, try to directly use the bucket with anonymous access
    const testFile = new Blob(['test'], { type: 'text/plain' });
    const { error: uploadError } = await supabase.storage
      .from(STORAGE_BUCKET)
      .upload('test-permissions.txt', testFile, {
        cacheControl: '0',
        upsert: true
      });
      
    if (uploadError) {
      console.warn('Anonymous upload test failed:', uploadError.message);
      console.log('You may need to set the following storage policies in the Supabase dashboard:');
      console.log(`1. Allow public SELECT access to bucket '${STORAGE_BUCKET}'`);
      console.log(`2. Allow public INSERT access to bucket '${STORAGE_BUCKET}'`);
      console.log(`3. Allow public UPDATE access to bucket '${STORAGE_BUCKET}'`);
      console.log(`4. Allow public DELETE access to bucket '${STORAGE_BUCKET}'`);
    } else {
      console.log('Anonymous upload test succeeded!');
      
      // Clean up test file
      await supabase.storage
        .from(STORAGE_BUCKET)
        .remove(['test-permissions.txt']);
    }
    
    return true;
  } catch (error) {
    console.warn('Error creating storage policies:', error);
    return false;
  }
};

// Run storage setup when this module is loaded
setupStorage().catch(error => {
  console.error('Failed to setup storage:', error);
}); 