import React, { useState, useEffect } from 'react';
import { getUploadedFiles } from '../../utils/fileUploadService';
import { FolderIcon, FileIcon, RefreshCw, Trash, Download, Save } from 'lucide-react';
import { deleteFile } from '../../utils/fileUploadService';
import { exportFilesToBuild, exportFilesToJson } from '../../utils/buildHelper';

const UploadMonitorPage = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [directories, setDirectories] = useState({});
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [exportStatus, setExportStatus] = useState('');

  useEffect(() => {
    const fetchFiles = async () => {
      setLoading(true);
      try {
        const uploadedFiles = await getUploadedFiles();
        setFiles(uploadedFiles);
        
        // Group files by directory
        const dirs = {};
        uploadedFiles.forEach(file => {
          if (!dirs[file.directory]) {
            dirs[file.directory] = [];
          }
          dirs[file.directory].push(file);
        });
        
        setDirectories(dirs);
      } catch (error) {
        console.error('Error fetching uploaded files:', error);
      } finally {
        setLoading(false);
      }
    };
    
    fetchFiles();
  }, [refreshTrigger]);
  
  const handleRefresh = () => {
    setRefreshTrigger(prev => prev + 1);
  };
  
  const handleDelete = async (filePath) => {
    try {
      await deleteFile(filePath);
      handleRefresh();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };
  
  const handleClearAll = async () => {
    try {
      const confirmClear = window.confirm('Вы уверены, что хотите удалить все загруженные файлы?');
      if (!confirmClear) return;
      
      // Clear localStorage
      localStorage.removeItem('uploadedFiles');
      handleRefresh();
    } catch (error) {
      console.error('Error clearing files:', error);
    }
  };
  
  const handleExportToBuild = async () => {
    try {
      setExportStatus('exporting');
      await exportFilesToBuild();
      setExportStatus('success');
      
      // Reset status after 3 seconds
      setTimeout(() => {
        setExportStatus('');
      }, 3000);
    } catch (error) {
      console.error('Error exporting files to build:', error);
      setExportStatus('error');
      
      // Reset status after 3 seconds
      setTimeout(() => {
        setExportStatus('');
      }, 3000);
    }
  };
  
  const handleDownloadExport = () => {
    exportFilesToJson();
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-800">Загруженные файлы</h1>
        <div className="flex items-center space-x-3">
          <button
            onClick={handleRefresh}
            className="p-2 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
            title="Обновить"
          >
            <RefreshCw size={18} />
          </button>
          
          <button
            onClick={handleClearAll}
            className="px-3 py-2 text-sm rounded-lg border border-red-200 text-red-500 hover:bg-red-50 transition-colors"
            title="Удалить все"
          >
            Очистить все
          </button>
          
          <button
            onClick={handleExportToBuild}
            disabled={exportStatus === 'exporting' || files.length === 0}
            className={`px-3 py-2 text-sm rounded-lg flex items-center ${
              exportStatus === 'success' 
                ? 'bg-green-500 text-white' 
                : exportStatus === 'error'
                  ? 'bg-red-500 text-white'
                  : 'border border-emerald-200 text-emerald-600 hover:bg-emerald-50'
            } transition-colors ${
              (exportStatus === 'exporting' || files.length === 0) ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            title="Экспортировать файлы для сборки"
          >
            <Download size={16} className="mr-1" />
            {exportStatus === 'exporting' 
              ? 'Экспорт...' 
              : exportStatus === 'success'
                ? 'Экспорт успешен!'
                : exportStatus === 'error'
                  ? 'Ошибка экспорта'
                  : 'Экспортировать для сборки'}
          </button>
          
          <button
            onClick={handleDownloadExport}
            disabled={files.length === 0}
            className={`px-3 py-2 text-sm rounded-lg flex items-center border border-blue-200 text-blue-600 hover:bg-blue-50 transition-colors ${
              files.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            title="Скачать файл экспорта"
          >
            <Save size={16} className="mr-1" />
            Скачать экспорт
          </button>
        </div>
      </div>
      
      {loading ? (
        <div className="text-center py-12">
          <p className="text-gray-500">Загрузка...</p>
        </div>
      ) : files.length === 0 ? (
        <div className="text-center py-12 border border-dashed border-gray-200 rounded-lg">
          <p className="text-gray-500">Нет загруженных файлов</p>
        </div>
      ) : (
        <div className="space-y-8">
          {Object.entries(directories).map(([directory, directoryFiles]) => (
            <div key={directory} className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
              <div className="flex items-center mb-4">
                <FolderIcon size={20} className="text-emerald-500 mr-2" />
                <h2 className="text-lg font-medium text-gray-800">{directory}</h2>
                <span className="ml-2 text-sm text-gray-500">({directoryFiles.length} файлов)</span>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {directoryFiles.map((file, index) => (
                  <div 
                    key={`${file.path}-${index}`} 
                    className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow"
                  >
                    <div className="flex justify-between items-start mb-2">
                      <div className="flex items-center">
                        <FileIcon size={16} className="text-blue-500 mr-2" />
                        <span className="font-medium text-gray-700 truncate" title={file.name}>
                          {file.name}
                        </span>
                      </div>
                      <button
                        onClick={() => handleDelete(file.path)}
                        className="text-red-500 hover:text-red-700 p-1"
                      >
                        <Trash size={14} />
                      </button>
                    </div>
                    
                    <div className="text-xs text-gray-500">
                      <p>Путь: <span className="font-mono">{file.path}</span></p>
                      <p>Размер: {(file.size / 1024).toFixed(2)} KB</p>
                      <p>Тип: {file.type}</p>
                      <p>Загружен: {new Date(file.uploadedAt).toLocaleString()}</p>
                    </div>
                    
                    {file.dataUrl && (
                      <div className="mt-2">
                        <img 
                          src={file.dataUrl} 
                          alt={file.name}
                          className="w-full h-32 object-cover rounded border border-gray-200"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UploadMonitorPage; 