import React, { createContext, useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  supabase,
  fetchLocations,
  createLocation,
  updateLocation as updateLocationInDb,
  deleteLocation as deleteLocationFromDb,
  fetchRealEstates,
  createRealEstate,
  directInsertRealEstate,
  updateRealEstate as updateRealEstateInDb,
  deleteRealEstate as deleteRealEstateFromDb,
  fetchContactSubmissions,
  createContactSubmission,
  updateContactSubmission,
  deleteContactSubmission as deleteContactSubmissionFromDb,
  setupRealtimeSubscriptions
} from '../utils/supabaseClient';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [locations, setLocations] = useState([]);
  const [realEstates, setRealEstates] = useState([]);
  const [contactSubmissions, setContactSubmissions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [language, setLanguage] = useState('ru');
  // Add a ref to track if we're currently saving to avoid race conditions
  const isSaving = useRef(false);
  const dataLoaded = useRef(false); // Track if data has been loaded before
  // Add state for tracking data version
  const [dataVersion, setDataVersion] = useState(Date.now());
  const lastVersionCheck = useRef(Date.now());

  // Load data from Supabase on component mount
  useEffect(() => {
    // Don't load data again if it's already been loaded
    if (dataLoaded.current) {
      console.log('Data already loaded, skipping');
      return;
    }
    
    const loadData = async () => {
      // Prevent multiple simultaneous data loads
      if (isSaving.current) {
        console.log('Already loading data, skipping');
        return;
      }
      
      isSaving.current = true;
      setIsLoading(true);
      
      try {
        console.log('Loading data from Supabase...');
        
        // Initialize realtime subscriptions to ensure they're working
        const unsubscribeTest = await setupRealtimeSubscriptions();
        
        // Load locations
        const locationsData = await fetchLocations();
        console.log('Loaded locations:', locationsData);
        
        // Only create default locations if explicitly empty AND we haven't loaded data before
        if (Array.isArray(locationsData) && locationsData.length > 0) {
          setLocations(locationsData);
        } else if (Array.isArray(locationsData) && locationsData.length === 0 && !dataLoaded.current) {
          console.log('No locations found, creating defaults');
          // Create default locations if none exist
          const defaultLocations = [
            {
              id: uuidv4(),
              name: 'Созополь',
              image: '',
              order: 0
            },
            {
              id: uuidv4(),
              name: 'Черноморец',
              image: '',
              order: 1
            },
            {
              id: uuidv4(),
              name: 'Равда',
              image: '',
              order: 2
            },
            {
              id: uuidv4(),
              name: 'Бургас',
              image: '',
              order: 3
            },
            {
              id: uuidv4(),
              name: 'Каблешково',
              image: '',
              order: 4
            },
            {
              id: uuidv4(),
              name: 'Приморско',
              image: '',
              order: 5
            }
          ];
          
          // Double-check once more that no locations exist before inserting
          const doubleCheckLocations = await fetchLocations();
          if (Array.isArray(doubleCheckLocations) && doubleCheckLocations.length === 0) {
            console.log('Double-check confirms no locations, proceeding with creation');
            // Insert default locations into Supabase
            for (const location of defaultLocations) {
              await createLocation(location);
            }
            
            setLocations(defaultLocations);
          } else {
            console.log('Double-check found locations, using those instead');
            setLocations(doubleCheckLocations || []);
          }
        } else {
          // If we get here, something is wrong with the data fetch, but we don't want to create duplicates
          console.warn('Locations data is not valid:', locationsData);
          setLocations([]);
        }
        
        // Load real estates
        const realEstatesData = await fetchRealEstates();
        console.log('Loaded real estates:', realEstatesData);
        
        setRealEstates(Array.isArray(realEstatesData) ? realEstatesData : []);
        
        // Load contact submissions
        const contactSubmissionsData = await fetchContactSubmissions();
        console.log('Loaded contact submissions:', contactSubmissionsData);
        setContactSubmissions(Array.isArray(contactSubmissionsData) ? contactSubmissionsData : []);
        
        // Clean up test subscriptions
        unsubscribeTest();
        
        // Mark that data has been loaded
        dataLoaded.current = true;
      } catch (error) {
        console.error('Error loading data from Supabase:', error);
      } finally {
        setIsLoading(false);
        isSaving.current = false;
        // Mark that data has been loaded
        dataLoaded.current = true;
      }
    };

    loadData();
    
    // Set up real-time listeners with improved channel names and status logging
    const locationsSubscription = supabase
      .channel('locations-channel')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'locations' }, (payload) => {
        console.log('Location change detected:', payload);
        // For any change, refresh all data to ensure consistency
        fetchLocations().then(data => {
          console.log('Refreshed locations:', data);
          if (data) setLocations(data);
        });
      })
      .subscribe(status => {
        console.log('Locations subscription status:', status);
      });
      
    const realEstatesSubscription = supabase
      .channel('real-estates-channel')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'real_estates' }, (payload) => {
        console.log('Real estate change detected:', payload);
        // For any change, refresh all data to ensure consistency
        fetchRealEstates().then(data => {
          console.log('Refreshed real estates:', data);
          if (data) setRealEstates(data);
        });
      })
      .subscribe(status => {
        console.log('Real estates subscription status:', status);
      });
      
    const contactSubscription = supabase
      .channel('contact-submissions-channel')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'contact_submissions' }, (payload) => {
        console.log('Contact submission change detected:', payload);
        // For any change, refresh all data to ensure consistency
        fetchContactSubmissions().then(data => {
          console.log('Refreshed contact submissions:', data);
          if (data) setContactSubmissions(data);
        });
      })
      .subscribe(status => {
        console.log('Contact submissions subscription status:', status);
      });
    
    // Cleanup subscriptions on unmount
    return () => {
      locationsSubscription.unsubscribe();
      realEstatesSubscription.unsubscribe();
      contactSubscription.unsubscribe();
    };
  }, []);

  // Add location
  const addLocation = async (location) => {
    try {
      // Set the order to be the last position
      const newLocation = {
        ...location,
        id: location.id || uuidv4(),
        order: locations.length
      };
      
      const createdLocation = await createLocation(newLocation);
      if (createdLocation) {
        setLocations(prev => [...prev, createdLocation]);
      }
      return createdLocation;
    } catch (error) {
      console.error('Error adding location:', error);
      // Re-throw the error to be handled by the caller
      throw error;
    }
  };

  // Update location
  const updateLocation = async (id, updatedLocation) => {
    try {
      const updated = await updateLocationInDb(id, updatedLocation);
      if (updated) {
        setLocations(prev => prev.map(loc => loc.id === id ? updated : loc));
      }
      return updated;
    } catch (error) {
      console.error('Error updating location:', error);
      // Re-throw the error to be handled by the caller
      throw error;
    }
  };

  // Delete location
  const deleteLocation = async (id) => {
    try {
      // First, find all real estates associated with this location
      const locationRealEstates = realEstates.filter(estate => estate.location_id === id);
      
      // Delete all real estates associated with this location
      for (const estate of locationRealEstates) {
        await deleteRealEstateFromDb(estate.id);
      }
      
      // Now delete the location itself
      const success = await deleteLocationFromDb(id);
      
      if (success) {
        // Remove deleted real estates from state
        if (locationRealEstates.length > 0) {
          setRealEstates(prev => prev.filter(estate => estate.location_id !== id));
        }
        
        // Also need to update the order of remaining locations
        const remainingLocations = locations.filter(loc => loc.id !== id);
        setLocations(remainingLocations);
        
        // Update orders for all remaining locations
        for (let i = 0; i < remainingLocations.length; i++) {
          if (remainingLocations[i].order !== i) {
            await updateLocationInDb(remainingLocations[i].id, { order: i });
          }
        }
      }
      return success;
    } catch (error) {
      console.error('Error deleting location:', error);
      return false;
    }
  };

  // Move location up in the order
  const moveLocationUp = async (id) => {
    try {
      const index = locations.findIndex(loc => loc.id === id);
      if (index <= 0) return false;
      
      const updatedLocations = [...locations];
      // Swap with the previous item
      const temp = updatedLocations[index];
      updatedLocations[index] = updatedLocations[index - 1];
      updatedLocations[index - 1] = temp;
      
      // Update order property
      updatedLocations[index].order = index;
      updatedLocations[index - 1].order = index - 1;
      
      // Save to database
      await updateLocationInDb(updatedLocations[index].id, { order: index });
      await updateLocationInDb(updatedLocations[index - 1].id, { order: index - 1 });
      
      setLocations(updatedLocations);
      return true;
    } catch (error) {
      console.error('Error moving location up:', error);
      return false;
    }
  };

  // Move location down in the order
  const moveLocationDown = async (id) => {
    try {
      const index = locations.findIndex(loc => loc.id === id);
      if (index < 0 || index >= locations.length - 1) return false;
      
      const updatedLocations = [...locations];
      // Swap with the next item
      const temp = updatedLocations[index];
      updatedLocations[index] = updatedLocations[index + 1];
      updatedLocations[index + 1] = temp;
      
      // Update order property
      updatedLocations[index].order = index;
      updatedLocations[index + 1].order = index + 1;
      
      // Save to database
      await updateLocationInDb(updatedLocations[index].id, { order: index });
      await updateLocationInDb(updatedLocations[index + 1].id, { order: index + 1 });
      
      setLocations(updatedLocations);
      return true;
    } catch (error) {
      console.error('Error moving location down:', error);
      return false;
    }
  };

  // Add real estate
  const addRealEstate = async (realEstate) => {
    try {
      // Set the order to be the last position
      const newRealEstate = {
        ...realEstate,
        id: realEstate.id || uuidv4(),
        order: realEstates.length
      };
      
      console.log('Trying direct insert method for real estate:', newRealEstate);
      
      // Try the direct RPC method first, which is more reliable
      try {
        const createdRealEstate = await directInsertRealEstate(newRealEstate);
        if (createdRealEstate) {
          console.log('Successfully created real estate using RPC:', createdRealEstate);
          setRealEstates(prev => [...prev, createdRealEstate]);
          return createdRealEstate;
        }
      } catch (rpcError) {
        console.error('Direct RPC insert failed, falling back to regular method:', rpcError);
      }
      
      // Fall back to the regular method if RPC fails
      const createdRealEstate = await createRealEstate(newRealEstate);
      if (createdRealEstate) {
        setRealEstates(prev => [...prev, createdRealEstate]);
      }
      return createdRealEstate;
    } catch (error) {
      console.error('Error adding real estate:', error);
      throw error; // Re-throw to be handled by the caller
    }
  };

  // Update real estate
  const updateRealEstate = async (id, updatedRealEstate) => {
    try {
      const updated = await updateRealEstateInDb(id, updatedRealEstate);
      if (updated) {
        setRealEstates(prev => prev.map(estate => estate.id === id ? updated : estate));
      }
      return updated;
    } catch (error) {
      console.error('Error updating real estate:', error);
      return null;
    }
  };

  // Delete real estate
  const deleteRealEstate = async (id) => {
    try {
      const success = await deleteRealEstateFromDb(id);
      if (success) {
        // Also need to update the order of remaining estates
        const remainingEstates = realEstates.filter(estate => estate.id !== id);
        setRealEstates(remainingEstates);
        
        // Update orders for all remaining estates
        for (let i = 0; i < remainingEstates.length; i++) {
          if (remainingEstates[i].order !== i) {
            await updateRealEstateInDb(remainingEstates[i].id, { order: i });
          }
        }
      }
      return success;
    } catch (error) {
      console.error('Error deleting real estate:', error);
      return false;
    }
  };

  // Move real estate up in the order
  const moveRealEstateUp = async (id) => {
    try {
      const filteredEstates = realEstates.filter(
        estate => estate.location_id === realEstates.find(e => e.id === id)?.location_id
      );
      
      const index = filteredEstates.findIndex(estate => estate.id === id);
      if (index <= 0) return false;
      
      // Find global indices
      const globalIndexCurrent = realEstates.findIndex(estate => estate.id === filteredEstates[index].id);
      const globalIndexPrev = realEstates.findIndex(estate => estate.id === filteredEstates[index - 1].id);
      
      // Swap orders
      const updatedEstates = [...realEstates];
      const tempOrder = updatedEstates[globalIndexCurrent].order;
      updatedEstates[globalIndexCurrent].order = updatedEstates[globalIndexPrev].order;
      updatedEstates[globalIndexPrev].order = tempOrder;
      
      // Save to database
      await updateRealEstateInDb(updatedEstates[globalIndexCurrent].id, { order: updatedEstates[globalIndexCurrent].order });
      await updateRealEstateInDb(updatedEstates[globalIndexPrev].id, { order: updatedEstates[globalIndexPrev].order });
      
      // Update state
      setRealEstates([...updatedEstates].sort((a, b) => a.order - b.order));
      return true;
    } catch (error) {
      console.error('Error moving real estate up:', error);
      return false;
    }
  };

  // Move real estate down in the order
  const moveRealEstateDown = async (id) => {
    try {
      const filteredEstates = realEstates.filter(
        estate => estate.location_id === realEstates.find(e => e.id === id)?.location_id
      );
      
      const index = filteredEstates.findIndex(estate => estate.id === id);
      if (index < 0 || index >= filteredEstates.length - 1) return false;
      
      // Find global indices
      const globalIndexCurrent = realEstates.findIndex(estate => estate.id === filteredEstates[index].id);
      const globalIndexNext = realEstates.findIndex(estate => estate.id === filteredEstates[index + 1].id);
      
      // Swap orders
      const updatedEstates = [...realEstates];
      const tempOrder = updatedEstates[globalIndexCurrent].order;
      updatedEstates[globalIndexCurrent].order = updatedEstates[globalIndexNext].order;
      updatedEstates[globalIndexNext].order = tempOrder;
      
      // Save to database
      await updateRealEstateInDb(updatedEstates[globalIndexCurrent].id, { order: updatedEstates[globalIndexCurrent].order });
      await updateRealEstateInDb(updatedEstates[globalIndexNext].id, { order: updatedEstates[globalIndexNext].order });
      
      // Update state
      setRealEstates([...updatedEstates].sort((a, b) => a.order - b.order));
      return true;
    } catch (error) {
      console.error('Error moving real estate down:', error);
      return false;
    }
  };

  // Get real estates by location ID
  const getRealEstatesByLocation = (locationId) => {
    if (!locationId) return [];
    
    // Look for both location_id and locationId matches
    const locationEstates = realEstates.filter(estate => 
      estate.location_id === locationId || estate.locationId === locationId
    );
    
    // Normalize the property objects to have both location_id and locationId
    return locationEstates.map(estate => {
      const normalized = { ...estate };
      
      // Ensure both property fields exist
      if (estate.location_id && !estate.locationId) {
        normalized.locationId = estate.location_id;
      } else if (estate.locationId && !estate.location_id) {
        normalized.location_id = estate.locationId;
      }
      
      return normalized;
    });
  };

  // Add contact submission
  const addContactSubmission = async (submission) => {
    try {
      const newSubmission = {
        name: submission.name,
        email: submission.email,
        message: submission.message,
        isRead: false,
        created_at: new Date().toISOString()
      };
      
      const createdSubmission = await createContactSubmission(newSubmission);
      if (createdSubmission) {
        setContactSubmissions(prev => [createdSubmission, ...prev]);
      }
      return createdSubmission;
    } catch (error) {
      console.error('Error adding contact submission:', error);
      return null;
    }
  };

  // Mark contact submission as read
  const markContactSubmissionAsRead = async (id) => {
    try {
      const updated = await updateContactSubmission(id, { isRead: true });
      if (updated) {
        setContactSubmissions(prev => prev.map(sub => sub.id === id ? { ...sub, isRead: true } : sub));
      }
      return updated;
    } catch (error) {
      console.error('Error marking contact submission as read:', error);
      return null;
    }
  };

  // Delete contact submission
  const deleteContactSubmission = async (id) => {
    try {
      const success = await deleteContactSubmissionFromDb(id);
      if (success) {
        setContactSubmissions(prev => prev.filter(sub => sub.id !== id));
      }
      return success;
    } catch (error) {
      console.error('Error deleting contact submission:', error);
      return false;
    }
  };

  return (
    <DataContext.Provider
      value={{
      locations,
      realEstates,
      contactSubmissions,
      isLoading,
        dataVersion,
      addLocation,
      updateLocation,
      deleteLocation,
      moveLocationUp,
      moveLocationDown,
      addRealEstate,
      updateRealEstate,
      deleteRealEstate,
      moveRealEstateUp,
      moveRealEstateDown,
      getRealEstatesByLocation,
      addContactSubmission,
      markContactSubmissionAsRead,
      deleteContactSubmission
      }}
    >
      {children}
    </DataContext.Provider>
  );
}; 