import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Plus, Edit, Trash2, ChevronUp, ChevronDown, ChevronRight, Home, AlertTriangle } from 'lucide-react';
import { useData } from '../../hooks/useData';
import { getImageUrl, getPlaceholderImage } from '../../utils/imageUtils';
import { useTranslation } from 'react-i18next';

const LocationsPage = () => {
  const { locations, deleteLocation, realEstates, moveLocationUp, moveLocationDown } = useData();
  const [expandedLocations, setExpandedLocations] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [locationToDelete, setLocationToDelete] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const openDeleteModal = (location) => {
    setLocationToDelete(location);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setLocationToDelete(null);
  };

  const handleDelete = () => {
    if (locationToDelete) {
      deleteLocation(locationToDelete.id);
      closeDeleteModal();
    }
  };

  const getEstateCount = (locationId) => {
    return realEstates.filter(estate => estate.location_id === locationId).length;
  };

  const getLocationEstates = (locationId) => {
    return realEstates.filter(estate => estate.location_id === locationId);
  };

  const toggleExpand = (locationId) => {
    setExpandedLocations(prev => ({
      ...prev,
      [locationId]: !prev[locationId]
    }));
  };

  // Index of first and last item for disabling buttons
  const lastIndex = locations.length - 1;
  
  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8 gap-4">
        <h1 className="text-2xl font-bold text-gray-800">Управление локациями</h1>
        <Link 
          to="/admin/locations/add" 
          className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
        >
          <Plus size={16} className="mr-1" />
          Добавить локацию
        </Link>
      </div>

      {locations.length === 0 ? (
        <div className="bg-white p-8 rounded-lg shadow-sm text-center">
          <p className="text-gray-500 mb-4">Локации не добавлены</p>
          <Link 
            to="/admin/locations/add" 
            className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
          >
            <Plus size={16} className="mr-1" />
            Добавить первую локацию
          </Link>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {locations.map((location, index) => {
            const locationEstates = getLocationEstates(location.id);
            const estateCount = locationEstates.length;
            const isExpanded = expandedLocations[location.id];
            
            return (
              <div key={location.id} className="bg-white rounded-lg shadow-sm overflow-hidden border border-gray-100 hover:shadow-md transition-shadow">
                <div className="relative h-52">
                  {location.image ? (
                    <img 
                      src={getImageUrl(location.image)} 
                      alt={location.name} 
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        e.target.src = getPlaceholderImage('location');
                      }}
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                      <span className="text-gray-400">Нет изображения</span>
                    </div>
                  )}
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent flex items-end">
                    <div className="p-4 text-white">
                      <h3 className="text-xl font-semibold">{location.name}</h3>
                      {location.name_bg && (
                        <p className="text-sm text-white/90 mt-1">БГ: {location.name_bg}</p>
                      )}
                      <p className="text-sm text-white/80 mt-2">{estateCount} объектов</p>
                    </div>
                  </div>
                  <button 
                    className="absolute top-2 right-2 p-1.5 bg-red-500/80 text-white rounded-full hover:bg-red-600/90 transition"
                    onClick={(e) => {
                      e.stopPropagation();
                      openDeleteModal(location);
                    }}
                    title="Удалить локацию"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <button
                      onClick={() => toggleExpand(location.id)}
                      className="text-sm text-emerald-600 hover:text-emerald-800 font-medium flex items-center"
                    >
                      <ChevronRight 
                        size={16} 
                        className={`mr-1 transition-transform ${isExpanded ? 'rotate-90' : ''}`} 
                      />
                      {isExpanded ? 'Скрыть объекты' : 'Показать объекты'}
                    </button>
                    <Link 
                      to={`/admin/real-estates?locationId=${location.id}`}
                      className="text-sm text-emerald-600 hover:text-emerald-800 font-medium"
                    >
                      Все объекты
                    </Link>
                  </div>
                  
                  {isExpanded && (
                    <div className="mt-2 mb-4 border-t border-gray-100 pt-2">
                      {estateCount > 0 ? (
                        <ul className="space-y-2 max-h-40 overflow-y-auto pr-2">
                          {locationEstates.map(estate => (
                            <li key={estate.id} className="text-sm">
                              <Link 
                                to={`/admin/real-estates/edit/${estate.id}`}
                                className="flex items-center py-1 px-2 hover:bg-gray-50 rounded"
                              >
                                <Home size={14} className="mr-2 text-gray-500" />
                                <span className="text-gray-700 truncate">{estate.title}</span>
                                <span className="ml-auto text-xs text-gray-500">{estate.price} €</span>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <div className="text-sm text-gray-500 py-2 text-center">
                          Нет объектов
                        </div>
                      )}
                      
                      <div className="flex justify-center mt-3">
                        <button 
                          className="rounded-full p-2 bg-emerald-100 hover:bg-emerald-200 transition text-emerald-600"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/admin/real-estates/add?locationId=${location.id}`);
                          }}
                          title="Добавить объект"
                        >
                          <Plus size={18} />
                        </button>
                      </div>
                    </div>
                  )}
                  
                  <div className="flex justify-end items-center space-x-2 mt-2">
                    <button
                      onClick={() => moveLocationUp(location.id)}
                      disabled={index === 0}
                      className={`p-1.5 rounded-md ${
                        index === 0 
                          ? 'text-gray-300 cursor-not-allowed' 
                          : 'text-gray-500 hover:text-emerald-600 hover:bg-gray-100'
                      }`}
                      title="Переместить вверх"
                    >
                      <ChevronUp size={18} />
                    </button>
                    <button
                      onClick={() => moveLocationDown(location.id)}
                      disabled={index === lastIndex}
                      className={`p-1.5 rounded-md ${
                        index === lastIndex 
                          ? 'text-gray-300 cursor-not-allowed' 
                          : 'text-gray-500 hover:text-emerald-600 hover:bg-gray-100'
                      }`}
                      title="Переместить вниз"
                    >
                      <ChevronDown size={18} />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteModal && locationToDelete && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <AlertTriangle className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Удаление локации
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Вы уверены, что хотите удалить локацию "{locationToDelete.name}"?
                      </p>
                      {getEstateCount(locationToDelete.id) > 0 && (
                        <div className="mt-2 p-2 bg-amber-50 border border-amber-200 rounded-md">
                          <p className="text-sm text-amber-800 font-medium">
                            Внимание! Вместе с этой локацией будут удалены все связанные объекты недвижимости ({getEstateCount(locationToDelete.id)} шт.).
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleDelete}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Удалить
                </button>
                <button
                  type="button"
                  onClick={closeDeleteModal}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Отмена
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationsPage; 