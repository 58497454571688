import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Building, Plus, Mail, AlertTriangle } from 'lucide-react';
import { useData } from '../../hooks/useData';
import { useLanguage } from '../../hooks/useLanguage';

const DashboardCard = ({ title, value, icon, linkTo, color, badge }) => (
  <Link to={linkTo} className="block bg-white rounded-lg shadow-sm border border-gray-100 hover:shadow-md transition-shadow">
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <div className={`${color} text-white p-3 rounded-full`}>
          {icon}
        </div>
        {badge && (
          <div className="bg-amber-500 text-white text-xs py-1 px-2 rounded-full">
            {badge}
          </div>
        )}
      </div>
      <h3 className="text-gray-500 text-sm">{title}</h3>
      <p className="text-3xl font-bold text-gray-800 mt-1">{value}</p>
    </div>
  </Link>
);

const AdminDashboard = () => {
  const { locations, realEstates, contactSubmissions } = useData();
  const { t } = useLanguage();

  // Count unread submissions
  const unreadSubmissions = contactSubmissions.filter(submission => !submission.isRead).length;

  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ru-RU', {
      day: '2-digit',
      month: '2-digit', 
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8 gap-4">
        <h1 className="text-2xl font-bold text-gray-800">{t('adminPanel')}</h1>
        <div className="space-x-3">
          <Link 
            to="/admin/locations/add" 
            className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
          >
            <Plus size={16} className="mr-1" />
            {t('addLocation')}
          </Link>
          <Link 
            to="/admin/real-estates/add" 
            className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
          >
            <Plus size={16} className="mr-1" />
            {t('addProperty')}
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <DashboardCard 
          title={t('totalLocations')} 
          value={locations.length} 
          icon={<MapPin size={24} />} 
          linkTo="/admin/locations"
          color="bg-blue-500"
        />
        <DashboardCard 
          title={t('totalProperties')} 
          value={realEstates.length} 
          icon={<Building size={24} />} 
          linkTo="/admin/real-estates"
          color="bg-emerald-500"
        />
        <DashboardCard 
          title={t('contactSubmissions')} 
          value={contactSubmissions.length} 
          icon={<Mail size={24} />} 
          linkTo="/admin/contact-submissions"
          color="bg-purple-500"
          badge={unreadSubmissions > 0 ? `${unreadSubmissions} ${t('newSubmissions')}` : null}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        {/* Recent Properties */}
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <h2 className="text-xl font-semibold mb-6">Последние добавленные объекты</h2>
          
          {realEstates.length === 0 ? (
            <div className="py-8 text-center">
              <p className="text-gray-500">Объекты не добавлены</p>
              <Link 
                to="/admin/real-estates/add"
                className="inline-flex items-center mt-4 text-emerald-600 hover:text-emerald-800"
              >
                <Plus size={16} className="mr-1" />
                Добавить первый объект
              </Link>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Название</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Локация</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Цена</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Действия</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {realEstates.slice(0, 5).map((estate) => {
                    const location = locations.find(l => l.id === estate.location_id);
                    
                    return (
                      <tr key={estate.id} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{estate.title || estate.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{location ? location.name : 'Неизвестная локация'}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">{estate.price} €</td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                          <Link to={`/admin/real-estates/edit/${estate.id}`} className="text-emerald-600 hover:text-emerald-800 font-medium">
                            Редактировать
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              
              {realEstates.length > 5 && (
                <div className="mt-6 text-right">
                  <Link to="/admin/real-estates" className="text-emerald-600 hover:text-emerald-800 font-medium">
                    Смотреть все объекты
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Recent Contact Submissions */}
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
          <h2 className="text-xl font-semibold mb-6">Последние заявки на связь</h2>
          
          {contactSubmissions.length === 0 ? (
            <div className="py-8 text-center">
              <p className="text-gray-500">{t('noSubmissions')}</p>
            </div>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('name')}</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t('date')}</th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">{t('status')}</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {contactSubmissions.slice(0, 5).map((submission) => (
                    <tr key={submission.id} className={!submission.isRead ? 'bg-emerald-50 hover:bg-emerald-100' : 'hover:bg-gray-50'}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">{submission.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatDate(submission.created_at)}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                        {!submission.isRead && (
                          <span className="flex items-center justify-end text-amber-600">
                            <AlertTriangle size={16} className="mr-1" />
                            Новая
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              
              <div className="mt-6 text-right">
                <Link to="/admin/contact-submissions" className="text-emerald-600 hover:text-emerald-800 font-medium">
                  Смотреть все заявки
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard; 