import React from 'react';
import { useLanguage } from '../hooks/useLanguage';

const LanguageSwitcher = () => {
  const { language, toggleLanguage } = useLanguage();
  
  return (
    <button 
      onClick={toggleLanguage}
      className="lang-switch px-3 py-1.5 rounded-md border border-gray-200 hover:bg-gray-50 text-sm transition-colors flex items-center space-x-1"
      title={language === 'ru' ? 'Превключи на български' : 'Переключить на русский'}
    >
      <span className={language === 'ru' ? 'text-emerald-600 font-medium' : 'text-gray-500'}>RU</span>
      <span className="text-gray-300 mx-1">|</span>
      <span className={language === 'bg' ? 'text-emerald-600 font-medium' : 'text-gray-500'}>БГ</span>
    </button>
  );
};

export default LanguageSwitcher; 