import React, { useState, useEffect, useRef } from 'react';
import { X, ChevronLeft, ChevronRight, ZoomIn, ZoomOut, RefreshCw } from 'lucide-react';
import { getImageUrl, getPlaceholderImage } from '../utils/imageUtils';
import { findWorkingImagePath, refreshImages } from '../utils/deploymentUtils';

/**
 * A full-screen modal component for viewing property images
 * 
 * @param {Object} props
 * @param {Array} props.images - Array of image paths to display
 * @param {number} props.initialIndex - Initial image index to display
 * @param {Function} props.onClose - Callback when modal is closed
 * @param {string} props.altPrefix - Prefix for image alt text
 */
const ImageGalleryModal = ({ 
  images = [], 
  initialIndex = 0, 
  onClose, 
  altPrefix = 'Property image'
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [zoomed, setZoomed] = useState(false);
  const [resolvedImages, setResolvedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);

  const mainImageRef = useRef(null);
  
  // Resolve all image paths when the component mounts or images change
  useEffect(() => {
    const resolveImagePaths = async () => {
      setIsLoading(true);
      try {
        // Use our path resolution utility to find the best working path for each image
        const resolved = await Promise.all(
          images.map(async (imagePath) => {
            if (!imagePath) return null;
            try {
              return await findWorkingImagePath(imagePath);
            } catch (error) {
              console.error('Error resolving image path:', error);
              return getImageUrl(imagePath);
            }
          })
        );
        setResolvedImages(resolved.filter(Boolean));
        setLoadError(false);
      } catch (error) {
        console.error('Error resolving image paths:', error);
        setLoadError(true);
        // Fall back to regular image paths
        setResolvedImages(images.map(imagePath => getImageUrl(imagePath)));
      } finally {
        setIsLoading(false);
      }
    };
    
    resolveImagePaths();
  }, [images]);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      } else if (e.key === 'ArrowLeft') {
        handlePrev();
      } else if (e.key === 'ArrowRight') {
        handleNext();
      } else if (e.key === 'z') {
        setZoomed(prev => !prev);
      } else if (e.key === 'r') {
        handleRefresh();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    
    // Lock body scroll when modal is open
    document.body.style.overflow = 'hidden';
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'auto';
    };
  }, [onClose]);

  // Listen for image path resolved events
  useEffect(() => {
    const handleImagePathResolved = (event) => {
      const { originalPath, resolvedPath } = event.detail;
      
      // Update our resolved images if the original path matches one of our images
      setResolvedImages(prev => {
        const imageIndex = images.findIndex(img => img === originalPath);
        if (imageIndex >= 0 && prev[imageIndex] !== resolvedPath) {
          const newResolved = [...prev];
          newResolved[imageIndex] = resolvedPath;
          return newResolved;
        }
        return prev;
      });
    };
    
    window.addEventListener('image-path-resolved', handleImagePathResolved);
    
    return () => {
      window.removeEventListener('image-path-resolved', handleImagePathResolved);
    };
  }, [images]);

  // Prevent clicks inside the modal from closing it
  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex => 
      prevIndex === resolvedImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex => 
      prevIndex === 0 ? resolvedImages.length - 1 : prevIndex - 1
    );
  };

  const toggleZoom = () => {
    setZoomed(prev => !prev);
  };
  
  const handleRefresh = () => {
    setIsLoading(true);
    setLoadError(false);
    
    // Refresh all images
    refreshImages(images);
    
    // Re-resolve paths after a short delay
    setTimeout(() => {
      const resolveImagePaths = async () => {
        try {
          const resolved = await Promise.all(
            images.map(imagePath => findWorkingImagePath(imagePath))
          );
          setResolvedImages(resolved.filter(Boolean));
        } catch (error) {
          console.error('Error resolving image paths on refresh:', error);
        } finally {
          setIsLoading(false);
        }
      };
      
      resolveImagePaths();
    }, 500);
  };

  if (!images || images.length === 0) {
    return null;
  }

  return (
    <div 
      className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center"
      onClick={onClose}
    >
      <div 
        className="relative w-full h-full flex flex-col" 
        onClick={handleModalClick}
      >
        {/* Header with controls */}
        <div className="absolute top-0 left-0 right-0 p-4 flex justify-between items-center z-10 bg-gradient-to-b from-black/80 to-transparent">
          <div className="text-white text-sm md:text-base">
            {currentIndex + 1} / {resolvedImages.length || images.length}
          </div>
          <div className="flex gap-2">
            <button 
              onClick={handleRefresh}
              className="p-2 rounded-full bg-black/50 hover:bg-black/70 text-white transition-colors"
              aria-label="Refresh images"
              disabled={isLoading}
            >
              <RefreshCw size={18} className={isLoading ? 'animate-spin' : ''} />
            </button>
            <button 
              onClick={toggleZoom}
              className="p-2 rounded-full bg-black/50 hover:bg-black/70 text-white transition-colors"
              aria-label={zoomed ? "Zoom out" : "Zoom in"}
            >
              {zoomed ? <ZoomOut size={20} /> : <ZoomIn size={20} />}
            </button>
            <button 
              onClick={onClose}
              className="p-2 rounded-full bg-black/50 hover:bg-black/70 text-white transition-colors"
              aria-label="Close gallery"
            >
              <X size={20} />
            </button>
          </div>
        </div>

        {/* Main image container */}
        <div className="flex-grow flex items-center justify-center overflow-hidden relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black/50 z-10">
              <div className="animate-spin mr-2">
                <RefreshCw size={24} className="text-white" />
              </div>
              <span className="text-white">Loading images...</span>
            </div>
          )}
          
          {loadError && !isLoading && (
            <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/50 z-10">
              <div className="text-red-400 mb-4">Error loading images</div>
              <button 
                onClick={handleRefresh}
                className="px-4 py-2 bg-emerald-600 text-white rounded hover:bg-emerald-700"
              >
                Try Again
              </button>
            </div>
          )}
          
          <img 
            ref={mainImageRef}
            src={resolvedImages[currentIndex] || getImageUrl(images[currentIndex])}
            alt={`${altPrefix} ${currentIndex + 1}`}
            className={`
              max-h-screen transition-transform duration-300
              ${zoomed ? 'cursor-zoom-out max-w-none max-h-none scale-150' : 'cursor-zoom-in max-w-full object-contain'}
            `}
            onClick={toggleZoom}
            onError={(e) => {
              console.error(`Error loading image: ${resolvedImages[currentIndex]}`);
              e.target.src = getPlaceholderImage('properties');
              setLoadError(true);
            }}
            onLoad={() => {
              setLoadError(false);
            }}
          />

          {/* Navigation buttons */}
          {resolvedImages.length > 1 && (
            <>
              <button 
                onClick={handlePrev}
                className="absolute left-4 p-3 rounded-full bg-black/50 hover:bg-black/70 text-white transition-colors"
                aria-label="Previous image"
              >
                <ChevronLeft size={24} />
              </button>
              <button 
                onClick={handleNext}
                className="absolute right-4 p-3 rounded-full bg-black/50 hover:bg-black/70 text-white transition-colors"
                aria-label="Next image"
              >
                <ChevronRight size={24} />
              </button>
            </>
          )}
        </div>

        {/* Thumbnails */}
        <div className="p-4 bg-black/80">
          <div className="flex justify-center">
            <div className="flex gap-2 overflow-x-auto pb-2 max-w-full">
              {resolvedImages.map((image, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`w-14 h-14 flex-shrink-0 rounded-md border ${
                    index === currentIndex 
                      ? 'border-2 border-emerald-500 opacity-100' 
                      : 'border border-gray-700 opacity-70 hover:opacity-100 hover:border-gray-400'
                  } transition-all duration-200 overflow-hidden`}
                  aria-label={`View image ${index + 1}`}
                >
                  <img
                    src={image || getImageUrl(images[index])}
                    alt={`Thumbnail ${index + 1}`}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      e.target.src = getPlaceholderImage('properties');
                    }}
                  />
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGalleryModal; 