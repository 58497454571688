import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase, STORAGE_BUCKET } from '../utils/supabaseClient';
import { uploadFile, deleteFile } from '../utils/fileUploadService';
import { testStorage } from '../utils/testStorage';
import { useAuth } from '../hooks/useAuth';
import { useData } from '../hooks/useData';

const TestSupabasePage = () => {
  // State for test results
  const [testResults, setTestResults] = useState({});
  const [isRunningTests, setIsRunningTests] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [storageTestDetails, setStorageTestDetails] = useState(null);
  
  // Get auth and data from context
  const { isAuthenticated, user } = useAuth();
  const { locations, realEstates, contactSubmissions } = useData();
  
  // Function to run all tests
  const runAllTests = async () => {
    setIsRunningTests(true);
    setTestResults({});
    
    try {
      // Test database connection
      await testDatabaseConnection();
      
      // Test authentication
      await testAuthentication();
      
      // Test storage
      await testStorage();
      
      // Test data contexts
      await testDataContexts();
    } catch (error) {
      console.error('Error running tests:', error);
      setTestResults(prev => ({
        ...prev,
        overall: {
          status: 'error',
          message: `Error running tests: ${error.message}`
        }
      }));
    } finally {
      setIsRunningTests(false);
    }
  };

  // Run direct storage test
  const runDirectStorageTest = async () => {
    try {
      setTestResults(prev => ({
        ...prev,
        directStorage: {
          status: 'pending',
          message: 'Running direct storage test...'
        }
      }));
      
      const result = await testStorage();
      setStorageTestDetails(result);
      
      if (result.success) {
        setTestResults(prev => ({
          ...prev,
          directStorage: {
            status: 'success',
            message: `Direct storage test passed. Found ${result.buckets.length} buckets and ${result.files.length} files in '${STORAGE_BUCKET}'.`
          }
        }));
      } else {
        setTestResults(prev => ({
          ...prev,
          directStorage: {
            status: 'error',
            message: `Direct storage test failed at step '${result.step}': ${result.error}`
          }
        }));
      }
    } catch (error) {
      console.error('Direct storage test error:', error);
      setTestResults(prev => ({
        ...prev,
        directStorage: {
          status: 'error',
          message: `Direct storage test exception: ${error.message}`
        }
      }));
    }
  };
  
  // Test database connection
  const testDatabaseConnection = async () => {
    try {
      // Test connection by running a simple query
      const { data, error } = await supabase
        .from('locations')
        .select('id')
        .limit(1);
      
      if (error) throw error;
      
      setTestResults(prev => ({
        ...prev,
        database: {
          status: 'success',
          message: 'Successfully connected to Supabase database'
        }
      }));
    } catch (error) {
      console.error('Database connection test failed:', error);
      setTestResults(prev => ({
        ...prev,
        database: {
          status: 'error',
          message: `Failed to connect to database: ${error.message}`
        }
      }));
    }
  };
  
  // Test authentication
  const testAuthentication = async () => {
    try {
      // Check if authentication is working by retrieving session
      const { data, error } = await supabase.auth.getSession();
      
      if (error) throw error;
      
      setTestResults(prev => ({
        ...prev,
        authentication: {
          status: 'success',
          message: `Authentication is working. ${data.session ? 'User is logged in.' : 'No active session.'}`
        }
      }));
    } catch (error) {
      console.error('Authentication test failed:', error);
      setTestResults(prev => ({
        ...prev,
        authentication: {
          status: 'error',
          message: `Authentication test failed: ${error.message}`
        }
      }));
    }
  };
  
  // Test storage
  const testStorage = async () => {
    try {
      // List buckets to test storage access
      const { data, error } = await supabase.storage.listBuckets();
      
      if (error) throw error;
      
      // Check if our bucket exists
      const bucketExists = data.some(bucket => bucket.name === STORAGE_BUCKET);
      
      setTestResults(prev => ({
        ...prev,
        storage: {
          status: 'success',
          message: `Storage is working. ${bucketExists ? `Bucket '${STORAGE_BUCKET}' exists.` : `Warning: Bucket '${STORAGE_BUCKET}' not found.`}`
        }
      }));
    } catch (error) {
      console.error('Storage test failed:', error);
      setTestResults(prev => ({
        ...prev,
        storage: {
          status: 'error',
          message: `Storage test failed: ${error.message}`
        }
      }));
    }
  };
  
  // Test data contexts
  const testDataContexts = async () => {
    try {
      // Check if data contexts are working
      setTestResults(prev => ({
        ...prev,
        dataContexts: {
          status: 'success',
          message: `Data contexts are working: Locations: ${locations.length}, Real Estates: ${realEstates.length}, Contact Submissions: ${contactSubmissions.length}`
        }
      }));
    } catch (error) {
      console.error('Data contexts test failed:', error);
      setTestResults(prev => ({
        ...prev,
        dataContexts: {
          status: 'error',
          message: `Data contexts test failed: ${error.message}`
        }
      }));
    }
  };
  
  // Handle file selection
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };
  
  // Handle file upload
  const handleFileUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first');
      return;
    }
    
    try {
      setTestResults(prev => ({
        ...prev,
        imageUpload: {
          status: 'pending',
          message: 'Uploading image...'
        }
      }));
      
      // Check if the bucket exists first
      const { data: buckets, error: bucketsError } = await supabase.storage.listBuckets();
      
      if (bucketsError) {
        throw new Error(`Storage error: ${bucketsError.message}`);
      }
      
      // Check if our bucket exists
      const bucketExists = buckets.some(bucket => bucket.name === STORAGE_BUCKET);
      
      if (!bucketExists) {
        throw new Error(`Bucket '${STORAGE_BUCKET}' not found. Please create it in the Supabase dashboard.`);
      }
      
      // Upload file using our service
      const result = await uploadFile(selectedFile, 'test');
      
      // Set the uploaded image URL
      setUploadedImageUrl(result.url);
      
      setTestResults(prev => ({
        ...prev,
        imageUpload: {
          status: 'success',
          message: 'Image uploaded successfully!',
          data: result
        }
      }));
    } catch (error) {
      console.error('Image upload failed:', error);
      setTestResults(prev => ({
        ...prev,
        imageUpload: {
          status: 'error',
          message: `Image upload failed: ${error.message}`
        }
      }));
    }
  };
  
  // Handle file deletion
  const handleFileDelete = async () => {
    if (!uploadedImageUrl) {
      alert('No image to delete');
      return;
    }
    
    try {
      setTestResults(prev => ({
        ...prev,
        imageDelete: {
          status: 'pending',
          message: 'Deleting image...'
        }
      }));
      
      // Get the path from the URL
      const path = testResults.imageUpload?.data?.path;
      
      if (!path) {
        throw new Error('Image path not found');
      }
      
      // Delete the file
      const success = await deleteFile(path);
      
      if (!success) {
        throw new Error('Failed to delete image');
      }
      
      // Clear the uploaded image URL
      setUploadedImageUrl('');
      
      setTestResults(prev => ({
        ...prev,
        imageDelete: {
          status: 'success',
          message: 'Image deleted successfully!'
        }
      }));
    } catch (error) {
      console.error('Image deletion failed:', error);
      setTestResults(prev => ({
        ...prev,
        imageDelete: {
          status: 'error',
          message: `Image deletion failed: ${error.message}`
        }
      }));
    }
  };
  
  useEffect(() => {
    // Run tests when the component mounts
    runAllTests();
  }, []);
  
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow overflow-hidden">
        <div className="px-6 py-8">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold text-gray-900">Supabase Connection Tests</h1>
            <Link to="/" className="text-emerald-600 hover:text-emerald-700">
              Back to Home
            </Link>
          </div>
          
          <div className="space-y-6">
            {/* Overall status */}
            {testResults.overall && (
              <div className={`p-4 rounded-md ${testResults.overall.status === 'error' ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'}`}>
                <h2 className="text-lg font-medium">Overall Status</h2>
                <p>{testResults.overall.message}</p>
              </div>
            )}
            
            {/* Direct Storage Test */}
            <div className="p-4 rounded-md bg-gray-50">
              <div className="flex justify-between items-center mb-2">
                <h2 className="text-lg font-medium">Direct Storage Test</h2>
                <button
                  onClick={runDirectStorageTest}
                  className="bg-blue-600 text-white text-sm py-1 px-3 rounded-md hover:bg-blue-700"
                >
                  Run Direct Test
                </button>
              </div>
              
              {testResults.directStorage ? (
                <div className={`p-3 rounded-md ${testResults.directStorage.status === 'error' ? 'bg-red-50 text-red-700' : testResults.directStorage.status === 'pending' ? 'bg-yellow-50 text-yellow-700' : 'bg-green-50 text-green-700'}`}>
                  <p>{testResults.directStorage.message}</p>
                </div>
              ) : (
                <p>Click "Run Direct Test" to test storage directly</p>
              )}
              
              {storageTestDetails && (
                <div className="mt-3 text-sm">
                  <details>
                    <summary className="cursor-pointer font-medium">View Test Details</summary>
                    <div className="mt-2 overflow-auto max-h-64 p-2 bg-gray-100 rounded-md">
                      <pre>{JSON.stringify(storageTestDetails, null, 2)}</pre>
                    </div>
                  </details>
                </div>
              )}
            </div>
            
            {/* Authentication status */}
            <div className="p-4 rounded-md bg-gray-50">
              <h2 className="text-lg font-medium mb-2">Authentication</h2>
              {testResults.authentication ? (
                <div className={`p-3 rounded-md ${testResults.authentication.status === 'error' ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'}`}>
                  <p>{testResults.authentication.message}</p>
                </div>
              ) : (
                <p>Testing authentication...</p>
              )}
              
              <div className="mt-3">
                <p>Current auth state: {isAuthenticated ? 'Authenticated' : 'Not authenticated'}</p>
                {user && (
                  <div className="mt-2">
                    <p>User: {user.email}</p>
                    <p>User ID: {user.id}</p>
                  </div>
                )}
              </div>
            </div>
            
            {/* Database status */}
            <div className="p-4 rounded-md bg-gray-50">
              <h2 className="text-lg font-medium mb-2">Database</h2>
              {testResults.database ? (
                <div className={`p-3 rounded-md ${testResults.database.status === 'error' ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'}`}>
                  <p>{testResults.database.message}</p>
                </div>
              ) : (
                <p>Testing database connection...</p>
              )}
            </div>
            
            {/* Storage status */}
            <div className="p-4 rounded-md bg-gray-50">
              <h2 className="text-lg font-medium mb-2">Storage</h2>
              {testResults.storage ? (
                <div className={`p-3 rounded-md ${testResults.storage.status === 'error' ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'}`}>
                  <p>{testResults.storage.message}</p>
                </div>
              ) : (
                <p>Testing storage access...</p>
              )}
            </div>
            
            {/* Data Context status */}
            <div className="p-4 rounded-md bg-gray-50">
              <h2 className="text-lg font-medium mb-2">Data Contexts</h2>
              {testResults.dataContexts ? (
                <div className={`p-3 rounded-md ${testResults.dataContexts.status === 'error' ? 'bg-red-50 text-red-700' : 'bg-green-50 text-green-700'}`}>
                  <p>{testResults.dataContexts.message}</p>
                </div>
              ) : (
                <p>Testing data contexts...</p>
              )}
              
              <div className="mt-3 grid grid-cols-3 gap-4">
                <div>
                  <h3 className="font-medium">Locations</h3>
                  <p>{locations.length} items</p>
                </div>
                <div>
                  <h3 className="font-medium">Real Estates</h3>
                  <p>{realEstates.length} items</p>
                </div>
                <div>
                  <h3 className="font-medium">Contact Submissions</h3>
                  <p>{contactSubmissions.length} items</p>
                </div>
              </div>
            </div>
            
            {/* Image upload test */}
            <div className="p-4 rounded-md bg-gray-50">
              <h2 className="text-lg font-medium mb-4">Image Upload Test</h2>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Select image to upload
                  </label>
                  <input 
                    type="file" 
                    onChange={handleFileChange} 
                    accept="image/*"
                    className="w-full"
                  />
                </div>
                
                <div className="flex space-x-3">
                  <button
                    onClick={handleFileUpload}
                    disabled={!selectedFile}
                    className="bg-emerald-600 text-white py-2 px-4 rounded-md hover:bg-emerald-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
                  >
                    Upload Image
                  </button>
                  
                  <button
                    onClick={handleFileDelete}
                    disabled={!uploadedImageUrl}
                    className="bg-red-600 text-white py-2 px-4 rounded-md hover:bg-red-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
                  >
                    Delete Image
                  </button>
                </div>
                
                {testResults.imageUpload && (
                  <div className={`p-3 rounded-md ${testResults.imageUpload.status === 'error' ? 'bg-red-50 text-red-700' : testResults.imageUpload.status === 'pending' ? 'bg-yellow-50 text-yellow-700' : 'bg-green-50 text-green-700'}`}>
                    <p>{testResults.imageUpload.message}</p>
                  </div>
                )}
                
                {testResults.imageDelete && (
                  <div className={`p-3 rounded-md ${testResults.imageDelete.status === 'error' ? 'bg-red-50 text-red-700' : testResults.imageDelete.status === 'pending' ? 'bg-yellow-50 text-yellow-700' : 'bg-green-50 text-green-700'}`}>
                    <p>{testResults.imageDelete.message}</p>
                  </div>
                )}
                
                {uploadedImageUrl && (
                  <div>
                    <h3 className="font-medium mb-2">Uploaded Image:</h3>
                    <div className="border rounded-md overflow-hidden p-2">
                      <img 
                        src={uploadedImageUrl} 
                        alt="Uploaded test"
                        className="max-w-full h-auto max-h-48 mx-auto"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            
            {/* Run tests button */}
            <div className="flex justify-center mt-6">
              <button
                onClick={runAllTests}
                disabled={isRunningTests}
                className="bg-blue-600 text-white py-2 px-6 rounded-md hover:bg-blue-700 disabled:bg-gray-300 disabled:cursor-not-allowed"
              >
                {isRunningTests ? 'Running Tests...' : 'Run All Tests Again'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestSupabasePage; 