import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { useData } from '../../hooks/useData';
import { useLanguage } from '../../hooks/useLanguage';
import MultipleFileUploader from '../../components/MultipleFileUploader';

const RealEstateFormPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { locations, realEstates, addRealEstate, updateRealEstate } = useData();
  const { t } = useLanguage();
  
  // Get locationId from URL query if it exists
  const queryParams = new URLSearchParams(location.search);
  const locationIdFromQuery = queryParams.get('locationId');
  
  // Note: title_bg and description_bg are kept in the form for future use
  // but they won't be saved to the database until those columns are added
  const [formData, setFormData] = useState({
    title: '',
    title_bg: '', // For UI only (not in database yet)
    location_id: locationIdFromQuery || '',
    description: '',
    description_bg: '', // For UI only (not in database yet)
    price: '',
    area: '',
    images: ['']
  });
  const [imageEntries, setImageEntries] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const isEditMode = !!id;
  
  useEffect(() => {
    if (isEditMode) {
      const estateToEdit = realEstates.find(estate => estate.id === id);
      if (estateToEdit) {
        setFormData({
          title: estateToEdit.title || estateToEdit.name || '',
          title_bg: estateToEdit.title_bg || '',
          location_id: estateToEdit.location_id || '',
          description: estateToEdit.description || '',
          description_bg: estateToEdit.description_bg || '',
          price: estateToEdit.price || '',
          area: estateToEdit.area || '',
          images: [...(estateToEdit.images || [])]
        });
        if (estateToEdit.images && estateToEdit.images.length > 0) {
          const entries = estateToEdit.images.map(imagePath => ({
            file: null,
            path: imagePath,
            id: Math.random().toString(36).substr(2, 9)
          }));
          setImageEntries(entries);
        }
      } else {
        navigate('/admin/real-estates', { replace: true });
      }
    } else if (locations.length > 0) {
      // Set default location based on query param or first location
      setFormData(prev => ({
        ...prev,
        location_id: prev.location_id || locations[0].id
      }));
    }
  }, [id, realEstates, locations, isEditMode, navigate]);
  
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.title.trim()) {
      newErrors.title = 'Название обязателно';
    }
    
    if (!formData.location_id) {
      newErrors.location_id = 'Пожалуйста, выберите локацию';
    }
    
    if (!formData.description.trim()) {
      newErrors.description = 'Описание обязателно';
    }
    
    if (!formData.price) {
      newErrors.price = 'Цена обязателна';
    } else if (isNaN(formData.price) || parseFloat(formData.price) <= 0) {
      newErrors.price = 'Пожалуйста, введите действительную цену';
    }
    
    // Simple image validation - check if at least one valid path exists
    const hasValidImage = formData.images && formData.images.some(img => img && img.trim() !== '');
    if (!hasValidImage) {
      newErrors.images = 'Хотя бы одно изображение обязателно';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setFormData(prev => {
      const updatedData = { ...prev, [name]: value };
      
      // Convert price to a number if it's a valid price
      if (name === 'price' && value && !isNaN(value)) {
        updatedData.price = parseFloat(value);
      }
      
      return updatedData;
    });
    
    // Clear error when field is being edited
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  const handleImagesChange = (images) => {
    // Validate input
    if (!images || !Array.isArray(images)) return;
    
    // Store all entries
    setImageEntries(images);
    
    // Extract valid paths from images
    const validImagePaths = images
      .filter(img => img.path && img.path.trim() !== '')
      .map(img => img.path);
    
    // Update form data with valid images
    setFormData(prev => ({
      ...prev,
      images: validImagePaths.length > 0 ? validImagePaths : ['']
    }));
    
    // Clear errors if we have valid images
    if (validImagePaths.length > 0 && errors.images) {
      setErrors(prev => ({
        ...prev,
        images: ''
      }));
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      setIsSubmitting(true);
      
      try {
        // Check if any images are still marked as uploading
        const stillUploading = imageEntries.some(entry => entry.isUploading);
        if (stillUploading) {
          setErrors(prev => ({
            ...prev,
            images: 'Дождитесь завершения загрузки изображений'
          }));
          setIsSubmitting(false);
          return;
        }

        // Log all form data to help with debugging
        console.log('Submitting form with data:', formData);
        
        // Manually check the location_id is valid
        const locationExists = locations.some(loc => loc.id === formData.location_id);
        if (!locationExists) {
          setErrors(prev => ({
            ...prev,
            location_id: 'Выбранная локация не существует'
          }));
          setIsSubmitting(false);
          return;
        }
        
        // Ensure images array is valid
        if (!formData.images || !Array.isArray(formData.images) || formData.images.length === 0) {
          setFormData(prev => ({
            ...prev,
            images: [''] // Set a default empty array with one empty string
          }));
        }
        
        // Create a data object with only the fields that are in the database
        const dataToSave = {
          title: formData.title,
          description: formData.description,
          price: formData.price,
          area: formData.area,
          location_id: formData.location_id,
          images: formData.images
        };
        
        if (isEditMode) {
          await updateRealEstate(id, dataToSave);
          navigate('/admin/real-estates');
        } else {
          try {
            const result = await addRealEstate(dataToSave);
            console.log('Real estate created successfully:', result);
            navigate('/admin/real-estates');
          } catch (createError) {
            console.error('Error during real estate creation:', createError);
            setErrors(prev => ({
              ...prev,
              form: `Ошибка создания объекта: ${createError.message || 'Неизвестная ошибка'}`
            }));
            setIsSubmitting(false);
          }
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        let errorMessage = 'Произошла ошибка при сохранении данных.';
        
        if (error.message) {
          errorMessage += ` Детали: ${error.message}`;
        }
        
        setErrors(prev => ({
          ...prev,
          form: errorMessage
        }));
        
        setIsSubmitting(false);
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  
  return (
    <div>
      <div className="mb-8">
        <Link 
          to="/admin/real-estates" 
          className="inline-flex items-center text-emerald-600 hover:text-emerald-800"
        >
          <ChevronLeft size={16} className="mr-1" />
          Назад к объектам
        </Link>
      </div>
      
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">
          {isEditMode ? 'Редактировать объект недвижимости' : 'Добавить новый объект недвижимости'}
        </h1>
        
        <form onSubmit={handleSubmit}>
          {errors.form && (
            <div className="mb-4 p-4 bg-red-50 text-red-600 rounded-md text-sm">
              {errors.form}
            </div>
          )}
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-4">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="title">
                {t('propertyName')} (RU)*
              </label>
              <input
                id="title"
                name="title"
                type="text"
                value={formData.title}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg border ${
                  errors.title ? 'border-red-500' : 'border-gray-200'
                } focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none`}
                placeholder="Например: 2-комнатная квартира у моря"
              />
              {errors.title && (
                <p className="mt-1 text-red-500 text-sm">{errors.title}</p>
              )}
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="title_bg">
                {t('propertyName')} (БГ)
              </label>
              <input
                id="title_bg"
                name="title_bg"
                type="text"
                value={formData.title_bg}
                onChange={handleChange}
                className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none"
                placeholder="Например: 2-стаен апартамент до морето (незадължително)"
              />
              <p className="mt-1 text-xs text-gray-500">
                Оставьте пустым, чтобы использовать русское название для болгарской версии
              </p>
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="price">
                Цена (€)*
              </label>
              <input
                id="price"
                name="price"
                type="text"
                value={formData.price}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg border ${
                  errors.price ? 'border-red-500' : 'border-gray-200'
                } focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none`}
                placeholder="Например: 50000"
              />
              {errors.price && (
                <p className="mt-1 text-red-500 text-sm">{errors.price}</p>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="area">
                Площадь (м²)
              </label>
              <input
                id="area"
                name="area"
                type="text"
                value={formData.area}
                onChange={handleChange}
                className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none"
                placeholder="Например: 75"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="location_id">
                Локация*
              </label>
              <select
                id="location_id"
                name="location_id"
                value={formData.location_id}
                onChange={handleChange}
                className={`w-full px-4 py-2 rounded-lg border ${
                  errors.location_id ? 'border-red-500' : 'border-gray-200'
                } focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none`}
              >
                <option value="" disabled>Выберите локацию</option>
                {locations.map(location => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </select>
              {errors.location_id && (
                <p className="mt-1 text-red-500 text-sm">{errors.location_id}</p>
              )}
            </div>
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="description">
              {t('description')} (RU)*
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              rows={5}
              className={`w-full px-4 py-2 rounded-lg border ${
                errors.description ? 'border-red-500' : 'border-gray-200'
              } focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none`}
              placeholder="Детальное описание объекта..."
            />
            {errors.description && (
              <p className="mt-1 text-red-500 text-sm">{errors.description}</p>
            )}
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="description_bg">
              {t('description')} (БГ)
            </label>
            <textarea
              id="description_bg"
              name="description_bg"
              value={formData.description_bg}
              onChange={handleChange}
              rows={5}
              className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none"
              placeholder="Подробно описание на имота... (незадължително)"
            />
            <p className="mt-1 text-xs text-gray-500">
              Оставьте пустым, чтобы использовать русское описание для болгарской версии
            </p>
          </div>
          
          <div className="mb-6">
            <MultipleFileUploader 
              onImagesChange={handleImagesChange}
              initialImages={formData.images}
              type="properties"
            />
            {errors.images && (
              <p className="mt-1 text-red-500 text-sm">{errors.images}</p>
            )}
          </div>
          
          <div className="flex justify-end space-x-3">
            <Link
              to="/admin/real-estates"
              className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
              onClick={(e) => {
                // Prevent default if the form is in process of submitting
                if (isSubmitting) {
                  e.preventDefault();
                }
              }}
            >
              Отмена
            </Link>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors disabled:bg-emerald-300 disabled:cursor-not-allowed"
            >
              {isSubmitting ? 'Сохранение...' : isEditMode ? 'Сохранить изменения' : 'Добавить объект'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RealEstateFormPage; 