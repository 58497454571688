import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ChevronLeft, MapPin, Search, Maximize } from 'lucide-react';
import { useData } from '../hooks/useData';
import { useLanguage } from '../hooks/useLanguage';
import { getImageUrl, getPlaceholderImage } from '../utils/imageUtils';
import { findLocationBySlug, getPropertyUrl } from '../utils/urlUtils';
import ImageGalleryModal from '../components/ImageGalleryModal';

const PropertyCard = ({ property, location }) => {
  const { t, language } = useLanguage();
  const { locations } = useData();
  
  // Use Bulgarian name/description if available and language is set to Bulgarian
  const displayName = language === 'bg' && property.name_bg ? property.name_bg : property.name;
  const displayDescription = language === 'bg' && property.description_bg ? property.description_bg : property.description;
  
  // For debugging
  console.log('PropertyCard rendering with property:', {
    id: property.id,
    name: property.name,
    title: property.title,
    locationId: property.locationId,
    location_id: property.location_id
  });
  
  const propertyUrl = getPropertyUrl(property, locations);
  console.log('Generated property URL:', propertyUrl);
  
  return (
    <Link to={propertyUrl} className="block">
      <div className="bg-white rounded-lg shadow-sm overflow-hidden transition-transform hover:translate-y-[-5px] hover:shadow-md">
        <div className="relative h-48 sm:h-56">
          {property.images && property.images.length > 0 && property.images[0] ? (
            <img 
              src={getImageUrl(property.images[0])} 
              alt={displayName} 
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.src = getPlaceholderImage('properties');
              }}
            />
          ) : (
            <div className="w-full h-full bg-gray-200 flex items-center justify-center">
              <span className="text-gray-400">Нет изображения</span>
            </div>
          )}
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent flex items-end">
            <div className="p-4 text-white">
              <h3 className="text-lg sm:text-xl font-semibold line-clamp-1">{displayName}</h3>
              <p className="text-base sm:text-lg font-medium mt-1">{property.price} €</p>
            </div>
          </div>
        </div>
        <div className="p-4">
          <p className="text-gray-600 text-sm line-clamp-3 mb-3">
            {displayDescription}
          </p>
          <div className="text-emerald-600 font-medium text-sm">{t('viewDetails')}</div>
        </div>
      </div>
    </Link>
  );
};

const LocationDetailPage = () => {
  const { id, locationSlug } = useParams();
  const { locations, getRealEstatesByLocation, isLoading } = useData();
  const { t, language } = useLanguage();
  const [location, setLocation] = useState(null);
  const [properties, setProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [showFullscreenGallery, setShowFullscreenGallery] = useState(false);
  const [locationChecked, setLocationChecked] = useState(false);
  
  useEffect(() => {
    let foundLocation = null;
    
    // Only check for location if we have loaded the data
    if (!isLoading && (locationSlug || id)) {
      // Try to find by slug first
      if (locationSlug) {
        foundLocation = findLocationBySlug(locationSlug, locations);
      }
      
      // If not found by slug, try to find by ID (for backward compatibility)
      if (!foundLocation && id) {
        foundLocation = locations.find(loc => loc.id === id);
      }
      
      if (foundLocation) {
        setLocation(foundLocation);
        
        // Get properties for this location, checking both location_id and locationId
        const locationProperties = getRealEstatesByLocation(foundLocation.id);
        console.log('Found properties for location:', foundLocation.id, locationProperties);
        
        // Ensure each property has locationId for consistent URL generation
        const normalizedProperties = locationProperties.map(property => {
          // Add locationId if it doesn't exist but location_id does
          if (!property.locationId && property.location_id) {
            return { ...property, locationId: property.location_id };
          }
          return property;
        });
        
        console.log('Normalized properties:', normalizedProperties);
        setProperties(normalizedProperties);
        setFilteredProperties(normalizedProperties);
      }
      
      // Mark that we've checked for the location
      setLocationChecked(true);
    }
  }, [id, locationSlug, locations, getRealEstatesByLocation, isLoading]);
  
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredProperties(properties);
    } else {
      const searchLower = searchTerm.toLowerCase();
      const filtered = properties.filter(property => 
        property.name.toLowerCase().includes(searchLower) ||
        property.description.toLowerCase().includes(searchLower)
      );
      setFilteredProperties(filtered);
    }
  }, [searchTerm, properties]);
  
  const openFullscreenGallery = () => {
    setShowFullscreenGallery(true);
  };

  const closeFullscreenGallery = () => {
    setShowFullscreenGallery(false);
  };
  
  // If still loading data and haven't checked for location yet, show a loading state
  if (isLoading || !locationChecked) {
    return (
      <div className="min-h-screen pt-16 flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <div className="w-12 h-12 border-4 border-emerald-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-600">{t('loading') || 'Loading...'}</p>
        </div>
      </div>
    );
  }
  
  // Only show not found after we've verified the location doesn't exist
  if (!location && locationChecked) {
    return (
      <div className="min-h-screen pt-16 flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">Локацията не е намерена</h2>
          <Link 
            to="/" 
            className="inline-flex items-center text-emerald-600 hover:text-emerald-800"
          >
            <ChevronLeft size={18} className="mr-1" />
            {t('backToLocations')}
          </Link>
        </div>
      </div>
    );
  }
  
  return (
    <div className="min-h-screen pb-16 bg-gray-50">
      {/* Fullscreen gallery modal */}
      {showFullscreenGallery && location.image && (
        <ImageGalleryModal
          images={[location.image]}
          initialIndex={0}
          onClose={closeFullscreenGallery}
          altPrefix={language === 'bg' && location.name_bg ? location.name_bg : location.name}
        />
      )}
      
      <div className="container mx-auto px-4">
        <div className="mb-6 pt-6">
          <Link 
            to="/" 
            className="inline-flex items-center text-emerald-600 hover:text-emerald-800"
          >
            <ChevronLeft size={18} className="mr-1" />
            {t('backToLocations')}
          </Link>
        </div>
        
        <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-8">
          <div className="relative h-64 md:h-80">
            {location.image ? (
              <img 
                src={getImageUrl(location.image)} 
                alt={language === 'bg' && location.name_bg ? location.name_bg : location.name} 
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.target.src = getPlaceholderImage('location');
                }}
              />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <span className="text-gray-400">Нет изображения</span>
              </div>
            )}
            
            {location.image && (
              <button 
                onClick={openFullscreenGallery}
                className="absolute top-4 right-4 p-2 rounded-full bg-white/70 hover:bg-white text-gray-800 shadow-sm"
                aria-label="View full-screen image"
              >
                <Maximize size={20} />
              </button>
            )}
            
            <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent flex items-end">
              <div className="p-6 md:p-8">
                <h1 className="text-3xl md:text-4xl font-bold text-white mb-2">
                  {language === 'bg' && location.name_bg ? location.name_bg : location.name}
                </h1>
                <div className="flex items-center text-white/90">
                  <MapPin size={18} className="mr-2" />
                  <span>{filteredProperties.length} {t('propertiesInLocation')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {properties.length > 0 && (
          <div className="mb-8">
            <div className="relative max-w-full sm:max-w-md mx-auto">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder={t('searchProperties') + "..."}
                className="w-full px-4 py-3 pl-10 rounded-lg border border-gray-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none"
              />
              <Search size={18} className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            </div>
          </div>
        )}
        
        {filteredProperties.length === 0 ? (
          <div className="text-center py-12">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              {properties.length === 0 
                ? t('noPropertiesInLocation')
                : t('noPropertiesFound')}
            </h2>
            {properties.length > 0 && (
              <button
                onClick={() => setSearchTerm('')}
                className="text-emerald-600 hover:text-emerald-800"
              >
                {t('clearSearch')}
              </button>
            )}
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {filteredProperties.map(property => (
              <PropertyCard key={property.id} property={property} location={location} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default LocationDetailPage; 