import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import FileUploader from './FileUploader';
import { getImageUrl } from '../utils/imageUtils';

/**
 * Component for handling multiple file uploads
 * 
 * @param {Object} props
 * @param {Function} props.onImagesChange - Callback when images change, passes array of {file, path} objects
 * @param {Array} props.initialImages - Initial image URLs (optional)
 * @param {string} props.type - Type of images ('locations' or 'properties')
 */
const MultipleFileUploader = ({ 
  onImagesChange, 
  initialImages = [''],
  type = 'properties'
}) => {
  // Normalize a path to ensure consistency
  const normalizeImagePath = (image) => {
    if (!image) return '';
    if (image.startsWith('http') || image.startsWith('data:') || image.startsWith('/')) {
      return image;
    }
    return `/${image}`;
  };

  // Initialize state from props - only once
  const [imageEntries, setImageEntries] = useState(() => 
    (initialImages || ['']).map(imageUrl => ({
      file: null,
      path: normalizeImagePath(imageUrl),
      id: `${Date.now()}-${Math.random().toString(36).substring(2, 7)}`,
      isUploading: false
    }))
  );
  
  // When initialImages change from the parent component
  useEffect(() => {
    if (initialImages && initialImages.length > 0) {
      const updatedEntries = initialImages.map(imageUrl => {
        // Check if this image already exists in our entries
        const existingEntry = imageEntries.find(entry => entry.path === normalizeImagePath(imageUrl));
        if (existingEntry) {
          return existingEntry;
        }
        
        // Create a new entry for this image
        return {
          file: null,
          path: normalizeImagePath(imageUrl),
          id: `${Date.now()}-${Math.random().toString(36).substring(2, 7)}`,
          isUploading: false
        };
      });
      
      // If the array has changed, update it
      if (JSON.stringify(updatedEntries.map(e => e.path)) !== 
          JSON.stringify(imageEntries.map(e => e.path))) {
        setImageEntries(updatedEntries);
      }
    }
  }, [initialImages]);

  // Handle file selection for a specific uploader
  const handleFileSelect = (index, file, path) => {
    const newEntries = [...imageEntries];
    newEntries[index] = { ...newEntries[index], file, path, isUploading: false };
    setImageEntries(newEntries);
    
    // Directly notify parent
    onImagesChange(newEntries);
  };
  
  // Mark an uploader as currently uploading
  const handleUploadStart = (index) => {
    const newEntries = [...imageEntries];
    newEntries[index] = { ...newEntries[index], isUploading: true };
    setImageEntries(newEntries);
    
    // Directly notify parent
    onImagesChange(newEntries);
  };
  
  // Handle upload errors
  const handleUploadError = (index, error) => {
    const newEntries = [...imageEntries];
    newEntries[index] = { ...newEntries[index], isUploading: false, error };
    setImageEntries(newEntries);
    
    // Directly notify parent
    onImagesChange(newEntries);
  };

  // Add a new empty upload field
  const addImageField = () => {
    const newEntry = {
      file: null,
      path: '',
      id: `${Date.now()}-${Math.random().toString(36).substring(2, 7)}`,
      isUploading: false
    };
    
    const newEntries = [...imageEntries, newEntry];
    setImageEntries(newEntries);
    
    // Directly notify parent
    onImagesChange(newEntries);
  };

  // Remove a specific upload field
  const removeImageField = (index) => {
    // If it's the only entry, just clear it instead of removing
    if (imageEntries.length <= 1) {
      const emptyEntry = [{
        file: null,
        path: '',
        id: `${Date.now()}-${Math.random().toString(36).substring(2, 7)}`,
        isUploading: false
      }];
      setImageEntries(emptyEntry);
      onImagesChange(emptyEntry);
      return;
    }
    
    // Otherwise remove the specified entry
    const newEntries = imageEntries.filter((_, i) => i !== index);
    setImageEntries(newEntries);
    
    // Directly notify parent
    onImagesChange(newEntries);
  };

  // Check if any uploader is currently uploading
  const isAnyUploading = imageEntries.some(entry => entry.isUploading);

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <label className="block text-gray-700 text-sm font-medium">
          Изображения*
        </label>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            addImageField();
          }}
          className={`inline-flex items-center text-sm text-emerald-600 hover:text-emerald-800 active:transform active:scale-95 transition-transform ${isAnyUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isAnyUploading}
          aria-label="Добавить ещё изображений"
        >
          <Plus size={14} className="mr-1" />
          Добавить ещё
        </button>
      </div>

      {imageEntries.map((entry, index) => (
        <div key={entry.id} className="mb-4">
          <div className="flex items-start gap-2">
            <div className="flex-grow">
              <FileUploader
                onFileSelect={(file, path) => handleFileSelect(index, file, path)}
                initialImage={entry.path}
                type={type}
                showRemoveButton={true}
                onRemove={() => !entry.isUploading && removeImageField(index)}
                onUploadStart={() => handleUploadStart(index)}
                onUploadError={(error) => handleUploadError(index, error)}
                isDisabled={false} // Each uploader can work independently
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultipleFileUploader; 