/**
 * Build helper functions
 * These functions are used during the build process to ensure uploaded files
 * are correctly included in the build output
 */

import { dataUrlToBlob } from './fileUploadService';

/**
 * Creates any directories needed for the target path
 * @param {string} directoryPath - The path to create
 */
export const ensureDirectoryExists = (directoryPath) => {
  // In the browser environment, we can't directly create directories
  // This is a no-op, but in a Node.js environment during build,
  // this would use fs.mkdirSync(directoryPath, { recursive: true })
  console.log(`[Build] Would create directory: ${directoryPath}`);
};

/**
 * Writes a file to the filesystem
 * @param {string} filePath - The path to write to
 * @param {Blob} data - The data to write
 */
export const writeFileToDisk = (filePath, data) => {
  // In the browser environment, we can't directly write files
  // This is a no-op, but in a Node.js environment during build,
  // this would use fs.writeFileSync(filePath, data)
  console.log(`[Build] Would write file: ${filePath} (${(data.size / 1024).toFixed(2)} KB)`);
};

/**
 * Exports all files from localStorage to the specified directories
 */
export const exportFilesToBuild = async () => {
  try {
    console.log('[Build] Starting file export process...');
    
    // Get all uploaded files from localStorage
    const uploadedFiles = JSON.parse(localStorage.getItem('uploadedFiles') || '[]');
    
    if (uploadedFiles.length === 0) {
      console.log('[Build] No files to export.');
      return;
    }
    
    console.log(`[Build] Found ${uploadedFiles.length} files to export.`);
    
    // Prepare data for export to JSON
    const exportData = [];
    
    // Process each file
    for (const file of uploadedFiles) {
      try {
        if (!file.dataUrl) {
          console.warn(`[Build] File ${file.path} has no data URL. Skipping.`);
          continue;
        }
        
        // Convert data URL to blob
        const blob = dataUrlToBlob(file.dataUrl);
        
        // Add file to our export data
        exportData.push({
          ...file,
          // Include everything except the actual data URL which is very large
          dataUrl: '[DATA_URL]' // Placeholder to indicate we had this
        });
        
        // Ensure directories exist
        ensureDirectoryExists(`public${file.directory}`);
        ensureDirectoryExists(`src${file.directory}`);
        
        // Write file to both public and src directories
        writeFileToDisk(`public${file.path}`, blob);
        writeFileToDisk(`src${file.path}`, blob);
        
        console.log(`[Build] Exported: ${file.path} to public and src directories`);
      } catch (error) {
        console.error(`[Build] Error exporting file ${file.path}:`, error);
      }
    }
    
    // Save the export data to a JSON file for the build process
    const exportJson = JSON.stringify(exportData, null, 2);
    const exportBlob = new Blob([exportJson], { type: 'application/json' });
    writeFileToDisk('public/exported-files.json', exportBlob);
    
    console.log('[Build] File export process complete.');
    console.log('[Build] Export data saved to public/exported-files.json');
    
    // In development, we can also export to a downloadable file
    if (process.env.NODE_ENV === 'development') {
      exportFilesToJson();
    }
  } catch (error) {
    console.error('[Build] Error exporting files:', error);
  }
};

/**
 * Exports all uploaded files data to a downloadable JSON file
 * This is useful for manually moving files between development and build environments
 */
export const exportFilesToJson = () => {
  try {
    // Get all uploaded files from localStorage
    const uploadedFiles = JSON.parse(localStorage.getItem('uploadedFiles') || '[]');
    
    if (uploadedFiles.length === 0) {
      alert('No files to export.');
      return;
    }
    
    // Create a JSON string with the files data
    const jsonData = JSON.stringify(uploadedFiles, null, 2);
    
    // Create a blob with the JSON data
    const blob = new Blob([jsonData], { type: 'application/json' });
    
    // Create a download link and trigger download
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'exported-files.json';
    document.body.appendChild(a);
    a.click();
    
    // Clean up
    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 100);
    
    console.log(`Successfully exported ${uploadedFiles.length} files to JSON.`);
    alert(`Successfully exported ${uploadedFiles.length} files. Save this file for the build process.`);
  } catch (error) {
    console.error('Error exporting files to JSON:', error);
    alert(`Error exporting files: ${error.message}`);
  }
};

/**
 * Helper function that can be called on app initialization to process any
 * files that need to be exported to the filesystem
 */
export const processStoredFiles = () => {
  // In development mode, we would check if we need to write files to disk
  // In production, this would be a no-op
  console.log('[Build] Processing stored files...');
  
  // If this is a development environment
  if (process.env.NODE_ENV === 'development') {
    console.log('[Build] Development mode - files will be displayed from data URLs');
    return;
  }
  
  // In production, we expect files to already be in the build
  console.log('[Build] Production mode - using pre-built files');
};

/**
 * Verifies that our upload directories exist in the public folder
 * Since we're in the browser, we can't directly create directories,
 * but we can check if the directories exist using fetch
 */
export const verifyPublicDirectories = async () => {
  const directories = [
    '/upload/locations',
    '/upload/properties'
  ];
  
  console.log('[Build] Verifying public directories...');
  
  for (const dir of directories) {
    try {
      // Try to fetch a test file in the directory
      const testFile = `${dir}/.directory`;
      const response = await fetch(testFile, { method: 'HEAD' });
      
      if (response.ok) {
        console.log(`[Build] Directory ${dir} exists.`);
      } else {
        console.warn(`[Build] Directory ${dir} may not exist or is not accessible.`);
        console.log(`[Build] Note: This is expected in development. The directories will be created at build time.`);
      }
    } catch (error) {
      console.warn(`[Build] Could not verify directory ${dir}: ${error.message}`);
    }
  }
}; 