import { v4 as uuidv4 } from 'uuid';
import { supabase } from './supabaseClient';

/**
 * Initialize database with default data if tables are empty
 */
export const initializeDatabase = async () => {
  console.log('Checking if database needs initialization...');
  
  try {
    // Check if locations table has data
    const { data: existingLocations, error: locationsError } = await supabase
      .from('locations')
      .select('id')
      .limit(1);
      
    if (locationsError) {
      throw new Error(`Error checking locations: ${locationsError.message}`);
    }
    
    // If locations table is empty, add default locations
    if (!existingLocations || existingLocations.length === 0) {
      console.log('Initializing locations table with default data...');
      
      const defaultLocations = [
        {
          id: uuidv4(),
          name: 'Созополь',
          image: '',
          "order": 0
        },
        {
          id: uuidv4(),
          name: 'Черноморец',
          image: '',
          "order": 1
        },
        {
          id: uuidv4(),
          name: 'Равда',
          image: '',
          "order": 2
        },
        {
          id: uuidv4(),
          name: 'Бургас',
          image: '',
          "order": 3
        },
        {
          id: uuidv4(),
          name: 'Каблешково',
          image: '',
          "order": 4
        },
        {
          id: uuidv4(),
          name: 'Приморско',
          image: '',
          "order": 5
        }
      ];
      
      // Insert locations into database
      const { error: insertError } = await supabase
        .from('locations')
        .insert(defaultLocations);
        
      if (insertError) {
        throw new Error(`Error inserting default locations: ${insertError.message}`);
      }
      
      console.log('Successfully initialized locations table!');
    } else {
      console.log('Locations table already has data, skipping initialization.');
    }
    
    return true;
  } catch (error) {
    console.error('Database initialization failed:', error);
    return false;
  }
}; 