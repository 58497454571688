/**
 * Utilities for handling deployment-specific functionality
 */
import { getUploadedFiles } from './storageUtils';

// Track which paths we've already verified, to avoid repetitive checks
const verifiedPaths = new Map();

/**
 * Check if an image URL exists by attempting to load it
 * @param {string} url - The URL to check
 * @returns {Promise<boolean>} - True if the image exists and can be loaded
 */
export const checkImageExists = async (url) => {
  if (!url || url.startsWith('data:')) return true;
  
  // If we've already verified this path, return the cached result
  if (verifiedPaths.has(url)) {
    return verifiedPaths.get(url);
  }
  
  // This Promise will help us timeout if the image takes too long to load
  const timeoutPromise = new Promise(resolve => {
    setTimeout(() => resolve(false), 3000); // 3 second timeout
  });
  
  // Promise to load the image
  const loadPromise = new Promise(resolve => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
  
  // Use Promise.race to handle timeouts
  const result = await Promise.race([loadPromise, timeoutPromise]);
  verifiedPaths.set(url, result);
  return result;
};

/**
 * Try to find a working image URL from multiple possible sources
 * @param {string} originalPath - The original image path
 * @returns {Promise<string>} - The first working URL or null if none work
 */
export const findWorkingImagePath = async (originalPath) => {
  if (!originalPath) return null;
  
  // If it's a data URL or absolute URL, use as is
  if (originalPath.startsWith('data:') || 
      originalPath.startsWith('http://') || 
      originalPath.startsWith('https://')) {
    return originalPath;
  }
  
  // Normalize the path
  const normalizedPath = originalPath.startsWith('/') ? originalPath : `/${originalPath}`;
  
  // Check if we've already verified this path
  if (verifiedPaths.has(normalizedPath) && verifiedPaths.get(normalizedPath)) {
    return normalizedPath;
  }
  
  // Array of possible paths to try
  const pathsToTry = [
    normalizedPath,
    normalizedPath.replace('/src/', '/'),
    `/upload${normalizedPath.split('/upload').pop()}`,
    normalizedPath.replace('/upload/', '/'),
  ];
  
  // Get stored files from localStorage
  try {
    const uploadedFiles = getUploadedFiles();
    const fileRecord = uploadedFiles.find(f => {
      const filePath = f.path && f.path.startsWith('/') ? f.path : `/${f.path || ''}`;
      return filePath === normalizedPath;
    });
    
    if (fileRecord) {
      // Add the productionPath if it exists
      if (fileRecord.productionPath) {
        pathsToTry.unshift(fileRecord.productionPath);
      }
      
      // Add the dataUrl if it exists
      if (fileRecord.dataUrl) {
        // Data URLs always work, so return immediately
        return fileRecord.dataUrl;
      }
    }
  } catch (error) {
    console.error('Error getting paths from localStorage:', error);
  }
  
  // Try each path in order until one works
  for (const path of pathsToTry) {
    const exists = await checkImageExists(path);
    if (exists) {
      // Cache the result for future use
      verifiedPaths.set(normalizedPath, true);
      verifiedPaths.set(path, true);
      return path;
    }
  }
  
  // If none work, return the original path and let the caller handle the error
  console.warn(`Could not find working path for: ${originalPath}`);
  return normalizedPath;
};

/**
 * Preload all images from localStorage to ensure they're available
 */
export const preloadImages = () => {
  try {
    const uploadedFiles = getUploadedFiles();
    let preloadedCount = 0;
    
    uploadedFiles.forEach(file => {
      if (file.type && file.type.startsWith('image/')) {
        let imagePath = null;
        
        // For data URLs, use them directly
        if (file.dataUrl) {
          imagePath = file.dataUrl;
        } 
        // For production paths, try those first 
        else if (file.productionPath) {
          imagePath = file.productionPath;
        }
        // Otherwise use the regular path
        else if (file.path) {
          imagePath = file.path.startsWith('/') ? file.path : `/${file.path}`;
        }
        
        if (imagePath) {
          // Create an image element to preload
          const img = new Image();
          
          // Set up event handlers
          img.onload = () => {
            preloadedCount++;
            // If it worked, cache this path
            verifiedPaths.set(imagePath, true);
          };
          
          img.onerror = () => {
            // If this path failed, mark it as bad
            verifiedPaths.set(imagePath, false);
            
            // Try the main path if that's different
            if (imagePath !== file.path && file.path) {
              const mainPath = file.path.startsWith('/') ? file.path : `/${file.path}`;
              const fallbackImg = new Image();
              fallbackImg.src = mainPath;
            }
          };
          
          // Start loading
          img.src = imagePath;
        }
      }
    });
    
    console.log(`Started preloading ${uploadedFiles.length} images`);
  } catch (error) {
    console.error('Error preloading images:', error);
  }
};

/**
 * Force refresh images that might be stale or not loading correctly
 * @param {Array<string>} imagePaths - Array of image paths to refresh
 */
export const refreshImages = (imagePaths = []) => {
  // Clear verified paths cache for these images
  if (imagePaths.length) {
    imagePaths.forEach(path => {
      if (path) {
        verifiedPaths.delete(path);
        verifiedPaths.delete(path.startsWith('/') ? path : `/${path}`);
      }
    });
  } else {
    // If no specific paths provided, clear the entire cache
    verifiedPaths.clear();
  }
  
  // Force browser to reload images
  const images = document.querySelectorAll('img');
  images.forEach(img => {
    if (!img.src) return;
    
    // Only refresh images matching our paths, or all if no paths provided
    if (imagePaths.length === 0 || imagePaths.some(path => img.src.includes(path))) {
      // Add a cache-busting query param
      const source = img.src;
      img.src = ''; // Clear source
      
      // Add a small delay before setting it back
      setTimeout(() => {
        img.src = source.includes('?') 
          ? `${source}&t=${Date.now()}`
          : `${source}?t=${Date.now()}`;
      }, 50);
    }
  });
  
  console.log(`Refreshed ${imagePaths.length || 'all'} images`);
}; 