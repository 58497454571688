import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Plus, Edit, Trash2, ArrowLeft, ChevronLeft, ChevronRight, ChevronUp, ChevronDown, Search, MapPin } from 'lucide-react';
import { useData } from '../../hooks/useData';
import { getImageUrl, getPlaceholderImage } from '../../utils/imageUtils';

const RealEstatesPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const locationIdFromQuery = queryParams.get('locationId');
  
  const { realEstates, locations, deleteRealEstate, moveRealEstateUp, moveRealEstateDown } = useData();
  const [filteredEstates, setFilteredEstates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterLocationId, setFilterLocationId] = useState(locationIdFromQuery || '');
  const [searchTerm, setSearchTerm] = useState('');
  
  const estatesPerPage = 9;
  
  // Apply filters and search
  useEffect(() => {
    let result = [...realEstates];
    
    // Filter by location
    if (filterLocationId) {
      result = result.filter(estate => estate.location_id === filterLocationId);
    }
    
    // Filter by search term
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      result = result.filter(estate => 
        estate.name.toLowerCase().includes(searchLower) ||
        estate.description.toLowerCase().includes(searchLower)
      );
    }
    
    setFilteredEstates(result);
    setCurrentPage(1); // Reset to page 1 when filters change
  }, [realEstates, filterLocationId, searchTerm]);
  
  // Set location filter from URL if present
  useEffect(() => {
    if (locationIdFromQuery) {
      setFilterLocationId(locationIdFromQuery);
    }
  }, [locationIdFromQuery]);
  
  // Pagination
  const totalPages = Math.ceil(filteredEstates.length / estatesPerPage);
  const currentEstates = filteredEstates.slice(
    (currentPage - 1) * estatesPerPage,
    currentPage * estatesPerPage
  );
  
  const handleDelete = (id) => {
    if (window.confirm('Вы уверены, что хотите удалить этот объект?')) {
      deleteRealEstate(id);
    }
  };
  
  const getLocationName = (locationId) => {
    const location = locations.find(loc => loc.id === locationId);
    return location ? location.name : 'Неизвестная локация';
  };

  // Check if an estate can be moved up within its location group
  const canMoveUp = (estate, estatesList) => {
    const allEstatesInLocation = estatesList.filter(e => e.location_id === estate.location_id);
    const index = allEstatesInLocation.findIndex(e => e.id === estate.id);
    return index > 0;
  };

  // Check if an estate can be moved down within its location group
  const canMoveDown = (estate, estatesList) => {
    const allEstatesInLocation = estatesList.filter(e => e.location_id === estate.location_id);
    const index = allEstatesInLocation.findIndex(e => e.id === estate.id);
    return index < allEstatesInLocation.length - 1;
  };
  
  return (
    <div>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8 gap-4">
        <h1 className="text-2xl font-bold text-gray-800">
          {filterLocationId ? `Объекты в ${getLocationName(filterLocationId)}` : 'Все объекты'}
        </h1>
        <Link 
          to="/admin/real-estates/add" 
          className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
        >
          <Plus size={16} className="mr-1" />
          Добавить объект
        </Link>
      </div>
      
      {filterLocationId && (
        <div className="mb-6">
          <Link 
            to="/admin/real-estates" 
            className="inline-flex items-center text-emerald-600 hover:text-emerald-800"
          >
            <ArrowLeft size={16} className="mr-1" />
            Назад ко всем объектам
          </Link>
        </div>
      )}
      
      <div className="bg-white p-5 rounded-lg shadow-sm border border-gray-100 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="col-span-2">
            <div className="relative">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Поиск по названию или описанию..."
                className="w-full px-4 py-2 pl-10 rounded-lg border border-gray-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none"
              />
              <Search size={18} className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            </div>
          </div>
          
          {!filterLocationId && (
            <div className="md:col-span-2">
              <select
                value={filterLocationId}
                onChange={(e) => setFilterLocationId(e.target.value)}
                className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none"
              >
                <option value="">Все локации</option>
                {locations.map(location => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
      
      {filteredEstates.length === 0 ? (
        <div className="bg-white p-8 rounded-lg shadow-sm text-center">
          <p className="text-gray-500 mb-4">Объекты не найдены</p>
          {!filterLocationId && !searchTerm ? (
            <Link 
              to="/admin/real-estates/add" 
              className="inline-flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
            >
              <Plus size={16} className="mr-1" />
              Добавить первый объект
            </Link>
          ) : (
            <button
              onClick={() => {
                setFilterLocationId('');
                setSearchTerm('');
              }}
              className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
            >
              Очистить фильтры
            </button>
          )}
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
            {currentEstates.map(estate => {
              const location = locations.find(loc => loc.id === estate.location_id);
              
              return (
                <div key={estate.id} className="bg-white rounded-lg shadow-sm overflow-hidden border border-gray-100 hover:shadow-md transition-shadow">
                  <div className="relative h-52">
                    {estate.images && estate.images.length > 0 && estate.images[0] ? (
                      <img 
                        src={getImageUrl(estate.images[0])} 
                        alt={estate.title || estate.name} 
                        className="w-full h-full object-cover"
                        onError={(e) => {
                          e.target.src = getPlaceholderImage('properties');
                        }}
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-400">Нет изображения</span>
                      </div>
                    )}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent flex items-end">
                      <div className="p-4 text-white">
                        <h3 className="text-xl font-semibold line-clamp-1">{estate.title || estate.name}</h3>
                        <p className="text-sm text-white/80 font-medium">{estate.price} €</p>
                      </div>
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="mb-2 text-sm text-gray-500 flex items-center">
                      <MapPin size={14} className="mr-1" />
                      {location ? location.name : 'Неизвестная локация'}
                    </div>
                    <p className="text-gray-600 text-sm line-clamp-2 mb-4">
                      {estate.description}
                    </p>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center space-x-1">
                        <button
                          onClick={() => moveRealEstateUp(estate.id)}
                          disabled={!canMoveUp(estate, realEstates)}
                          className={`p-1.5 rounded-md ${
                            !canMoveUp(estate, realEstates)
                              ? 'text-gray-300 cursor-not-allowed'
                              : 'text-gray-500 hover:text-emerald-600 hover:bg-gray-100'
                          }`}
                          title="Переместить выше в списке"
                        >
                          <ChevronUp size={16} />
                        </button>
                        <button
                          onClick={() => moveRealEstateDown(estate.id)}
                          disabled={!canMoveDown(estate, realEstates)}
                          className={`p-1.5 rounded-md ${
                            !canMoveDown(estate, realEstates)
                              ? 'text-gray-300 cursor-not-allowed'
                              : 'text-gray-500 hover:text-emerald-600 hover:bg-gray-100'
                          }`}
                          title="Переместить ниже в списке"
                        >
                          <ChevronDown size={16} />
                        </button>
                        <Link 
                          to={`/admin/real-estates/edit/${estate.id}`}
                          className="text-emerald-600 hover:text-emerald-800 font-medium"
                        >
                          Редактировать
                        </Link>
                      </div>
                      <button
                        onClick={() => handleDelete(estate.id)}
                        className="text-gray-500 hover:text-red-600 p-1.5 rounded-md hover:bg-gray-100"
                        title="Удалить"
                      >
                        <Trash2 size={18} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          
          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex justify-center mt-8">
              <div className="flex items-center space-x-4 bg-white rounded-lg shadow-sm border border-gray-100 px-4 py-2">
                <button
                  onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                  disabled={currentPage === 1}
                  className="p-2 rounded-lg border border-gray-200 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronLeft size={16} />
                </button>
                
                <div className="text-sm">
                  Страница {currentPage} из {totalPages}
                </div>
                
                <button
                  onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                  disabled={currentPage === totalPages}
                  className="p-2 rounded-lg border border-gray-200 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <ChevronRight size={16} />
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RealEstatesPage; 