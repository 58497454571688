import React, { useState, useEffect } from 'react';
import { supabase, STORAGE_BUCKET, setupStorage } from '../utils/supabaseClient';

const StorageTestPage = () => {
  const [status, setStatus] = useState('idle');
  const [message, setMessage] = useState('');
  const [buckets, setBuckets] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [storagePolicy, setStoragePolicy] = useState('unknown');
  
  useEffect(() => {
    // Run this when component mounts
    checkStorage();
  }, []);
  
  const checkStorage = async () => {
    try {
      setStatus('loading');
      setMessage('Checking Supabase storage...');
      
      // Check auth status
      const { data: authData } = await supabase.auth.getSession();
      const isAuthenticated = !!authData.session;
      
      // List buckets
      const { data: bucketsData, error: bucketsError } = await supabase.storage.listBuckets();
      
      if (bucketsError) {
        throw new Error(`Error listing buckets: ${bucketsError.message}`);
      }
      
      setBuckets(bucketsData || []);
      
      // Check if our target bucket exists
      const targetBucket = bucketsData.find(b => 
        b.name.toLowerCase() === STORAGE_BUCKET.toLowerCase()
      );
      
      if (!targetBucket) {
        setMessage(`Bucket '${STORAGE_BUCKET}' not found. Running setup...`);
        await setupStorage();
        
        // Refresh buckets after setup
        const { data: refreshedBuckets } = await supabase.storage.listBuckets();
        setBuckets(refreshedBuckets || []);
      }
      
      // Test if anonymous uploads work
      await testAnonymousUpload();
      
      setStatus('success');
      setMessage('Storage check complete');
    } catch (error) {
      console.error('Storage check failed:', error);
      setStatus('error');
      setMessage(`Error: ${error.message}`);
    }
  };
  
  const testAnonymousUpload = async () => {
    try {
      // Create a small test file
      const testFile = new Blob(['test'], { type: 'text/plain' });
      const testPath = `test-${Date.now()}.txt`;
      
      // Try to upload
      const { error: uploadError } = await supabase.storage
        .from(STORAGE_BUCKET)
        .upload(testPath, testFile, {
          cacheControl: '0',
          upsert: true
        });
      
      if (uploadError) {
        setStoragePolicy('restricted');
        throw new Error(`Anonymous upload test failed: ${uploadError.message}`);
      }
      
      // Try to fetch URL - this tests permissions
      const { data: urlData } = supabase.storage
        .from(STORAGE_BUCKET)
        .getPublicUrl(testPath);
      
      // Clean up the test file
      await supabase.storage
        .from(STORAGE_BUCKET)
        .remove([testPath]);
      
      setStoragePolicy('public');
      return true;
    } catch (error) {
      console.warn('Anonymous upload test failed:', error);
      return false;
    }
  };
  
  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };
  
  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first');
      return;
    }
    
    try {
      setStatus('uploading');
      setMessage('Uploading file...');
      
      // Generate unique file path
      const fileExt = selectedFile.name.split('.').pop();
      const filePath = `test-upload-${Date.now()}.${fileExt}`;
      
      // Direct upload to Supabase
      const { data, error } = await supabase.storage
        .from(STORAGE_BUCKET)
        .upload(filePath, selectedFile, {
          cacheControl: '3600',
          upsert: true
        });
      
      if (error) {
        throw error;
      }
      
      // Get URL
      const { data: urlData } = supabase.storage
        .from(STORAGE_BUCKET)
        .getPublicUrl(filePath);
      
      setUploadedUrl(urlData.publicUrl);
      setStatus('success');
      setMessage('File uploaded successfully!');
    } catch (error) {
      console.error('Upload failed:', error);
      setStatus('error');
      setMessage(`Upload failed: ${error.message}`);
    }
  };
  
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Supabase Storage Test</h1>
      
      <div className="mb-6 p-4 bg-gray-100 rounded-lg">
        <h2 className="text-xl font-semibold mb-2">Storage Status</h2>
        <p><strong>Status:</strong> {status}</p>
        <p><strong>Message:</strong> {message}</p>
        <p><strong>Storage Policy:</strong> {storagePolicy}</p>
        <p><strong>Target Bucket:</strong> {STORAGE_BUCKET}</p>
        <button 
          onClick={checkStorage}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Refresh Status
        </button>
      </div>
      
      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Available Buckets</h2>
        {buckets.length === 0 ? (
          <p>No buckets found</p>
        ) : (
          <ul className="list-disc pl-5">
            {buckets.map(bucket => (
              <li key={bucket.id} className="mb-1">
                {bucket.name} {bucket.name === STORAGE_BUCKET && '(target)'}
              </li>
            ))}
          </ul>
        )}
      </div>
      
      <div className="mb-6 p-4 bg-gray-100 rounded-lg">
        <h2 className="text-xl font-semibold mb-4">Test Direct Upload</h2>
        <div className="mb-4">
          <input 
            type="file" 
            onChange={handleFileChange} 
            className="mb-2"
          />
          <button 
            onClick={handleUpload}
            disabled={!selectedFile || status === 'uploading'}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 disabled:bg-gray-400"
          >
            {status === 'uploading' ? 'Uploading...' : 'Upload File'}
          </button>
        </div>
        
        {uploadedUrl && (
          <div>
            <p className="mb-2"><strong>Uploaded URL:</strong></p>
            <p className="break-all mb-2">{uploadedUrl}</p>
            
            {uploadedUrl.match(/\.(jpg|jpeg|png|gif|webp)$/i) && (
              <div>
                <p><strong>Image Preview:</strong></p>
                <img 
                  src={uploadedUrl} 
                  alt="Uploaded" 
                  className="max-w-full h-auto mt-2 border border-gray-300 rounded"
                  style={{ maxHeight: '200px' }} 
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StorageTestPage; 