import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, Search, ChevronDown, SlidersHorizontal, ArrowUpDown, Home, MapPin } from 'lucide-react';
import { useData } from '../hooks/useData';
import { useLanguage } from '../hooks/useLanguage';
import { getImageUrl, getPlaceholderImage } from '../utils/imageUtils';
import { getPropertyUrl } from '../utils/urlUtils';

// Properties per page for pagination
const ITEMS_PER_PAGE = 10;

const PropertyCard = ({ property, locations }) => {
  const { t, language } = useLanguage();
  
  // Get property details with language support
  const displayTitle = language === 'bg' && property.title_bg ? property.title_bg : (property.title || property.name);
  const displayDescription = language === 'bg' && property.description_bg 
    ? property.description_bg 
    : property.description || '';
  
  // Find location
  const location = locations.find(loc => loc.id === (property.locationId || property.location_id));
  const locationName = location ? (language === 'bg' && location.name_bg ? location.name_bg : location.name) : '';
  
  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden mb-6 h-[280px] md:h-[220px]">
      <div className="flex flex-col md:flex-row h-full">
        {/* Property Image - Fixed height */}
        <div className="md:w-1/3 h-[180px] md:h-full relative">
          {property.images && property.images.length > 0 ? (
            <img 
              src={getImageUrl(property.images[0])} 
              alt={displayTitle} 
              className="w-full h-full object-cover"
              onError={(e) => {
                e.target.src = getPlaceholderImage('properties');
              }}
            />
          ) : (
            <div className="w-full h-full bg-gray-200 flex items-center justify-center">
              <span className="text-gray-400">Нет изображения</span>
            </div>
          )}
          
          {/* Price tag */}
          <div className="absolute top-4 right-4 bg-emerald-600 text-white px-3 py-1 rounded-md font-semibold">
            {property.price ? `${property.price} €` : 'По запросу'}
          </div>
        </div>
        
        {/* Property Details - Fixed height with flex layout */}
        <div className="p-5 md:w-2/3 flex flex-col justify-between h-[100px] md:h-full overflow-hidden">
          <div>
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-xl font-bold text-gray-800 mb-2 line-clamp-1">{displayTitle}</h3>
                {locationName && (
                  <div className="flex items-center text-gray-600 mb-2">
                    <MapPin size={16} className="mr-1 flex-shrink-0" />
                    <span>{locationName}</span>
                  </div>
                )}
              </div>
              
              {/* Property area if available */}
              {property.area && (
                <div className="flex items-center text-gray-700">
                  <Home className="w-5 h-5 text-emerald-600 mr-1" />
                  <span className="font-medium">{property.area} м²</span>
                </div>
              )}
            </div>
            
            <p className="text-gray-600 line-clamp-2 mb-2">
              {displayDescription || 'Описание недоступно'}
            </p>
          </div>
          
          {/* Features and view button */}
          <div className="flex items-center justify-between mt-auto">
            <div className="flex flex-wrap gap-2">
              {property.bedrooms && (
                <span className="bg-gray-100 text-gray-700 px-2 py-1 text-sm rounded-md">
                  {property.bedrooms} спальни
                </span>
              )}
              {property.bathrooms && (
                <span className="bg-gray-100 text-gray-700 px-2 py-1 text-sm rounded-md">
                  {property.bathrooms} ванные
                </span>
              )}
              {property.type && (
                <span className="bg-gray-100 text-gray-700 px-2 py-1 text-sm rounded-md">
                  {property.type}
                </span>
              )}
            </div>
            
            <Link 
              to={getPropertyUrl(property, locations)} 
              className="text-emerald-600 hover:text-emerald-800 font-medium"
            >
              Подробнее
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const AllPropertiesPage = () => {
  const { realEstates, locations, isLoading } = useData();
  const { t, language } = useLanguage();
  
  // State for filters
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('default');
  const [priceRange, setPriceRange] = useState({ min: '', max: '' });
  const [showFilters, setShowFilters] = useState(false);
  const [filteredProperties, setFilteredProperties] = useState([]);
  
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [displayedProperties, setDisplayedProperties] = useState([]);
  
  // Apply filters and sort
  useEffect(() => {
    if (!isLoading && realEstates.length > 0) {
      let result = [...realEstates];
      
      // Apply search filter
      if (searchTerm.trim() !== '') {
        const searchLower = searchTerm.toLowerCase();
        result = result.filter(property => {
          const title = property.title || property.name || '';
          const description = property.description || '';
          return (
            title.toLowerCase().includes(searchLower) ||
            description.toLowerCase().includes(searchLower)
          );
        });
      }
      
      // Apply price range filter
      if (priceRange.min !== '') {
        result = result.filter(property => 
          property.price && property.price >= Number(priceRange.min)
        );
      }
      
      if (priceRange.max !== '') {
        result = result.filter(property => 
          property.price && property.price <= Number(priceRange.max)
        );
      }
      
      // Apply sort
      switch (sortOption) {
        case 'price-asc':
          result.sort((a, b) => {
            // Handle properties without price
            if (!a.price) return 1;
            if (!b.price) return -1;
            return a.price - b.price;
          });
          break;
        case 'price-desc':
          result.sort((a, b) => {
            // Handle properties without price
            if (!a.price) return 1;
            if (!b.price) return -1;
            return b.price - a.price;
          });
          break;
        case 'newest':
          // Sort by creation date if available
          result.sort((a, b) => {
            const dateA = a.created_at ? new Date(a.created_at) : new Date(0);
            const dateB = b.created_at ? new Date(b.created_at) : new Date(0);
            return dateB - dateA;
          });
          break;
        default:
          // Default sort - no specific sorting
          break;
      }
      
      setFilteredProperties(result);
      setTotalPages(Math.ceil(result.length / ITEMS_PER_PAGE));
      setCurrentPage(1); // Reset to first page when filters change
    }
  }, [isLoading, realEstates, searchTerm, sortOption, priceRange]);
  
  // Update displayed properties when page or filtered properties change
  useEffect(() => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setDisplayedProperties(filteredProperties.slice(startIndex, endIndex));
  }, [filteredProperties, currentPage]);
  
  // Handle input changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handlePriceChange = (e, type) => {
    const value = e.target.value.trim() === '' ? '' : Number(e.target.value);
    setPriceRange({
      ...priceRange,
      [type]: value
    });
  };
  
  const handleSortChange = (value) => {
    setSortOption(value);
  };
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    // Scroll to top when changing page
    window.scrollTo(0, 0);
  };
  
  const resetFilters = () => {
    setSearchTerm('');
    setSortOption('default');
    setPriceRange({ min: '', max: '' });
  };
  
  // --- Render Logic ---
  
  // 1. Loading State
  if (isLoading) {
    return (
      <div className="min-h-screen pt-16 flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <div className="w-12 h-12 border-4 border-emerald-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-600">{language === 'bg' ? 'Зареждане...' : 'Загрузка...'}</p>
        </div>
      </div>
    );
  }
  
  // Get translated texts with fallbacks
  const getTranslatedText = (key, ruText, bgText) => {
    const translation = t(key);
    if (translation) return translation;
    return language === 'bg' ? bgText : ruText;
  };

  // Translations for key elements
  const pageTitle = getTranslatedText('allProperties', 'Все объекты недвижимости', 'Всички имоти');
  const propertiesFoundText = getTranslatedText('propertiesFound', 'объектов найдено', 'имоти намерени');
  const sortByText = getTranslatedText('sortBy', 'Сортировать по', 'Сортирай по');
  const filtersText = getTranslatedText('filters', 'Фильтры', 'Филтри');
  
  return (
    <div className="min-h-screen pt-16 pb-16 bg-gray-50">
      <div className="container mx-auto px-4">
        {/* Back link */}
        <div className="py-6">
          <Link 
            to="/" 
            className="inline-flex items-center text-emerald-600 hover:text-emerald-800"
          >
            <ChevronLeft size={18} className="mr-1" />
            {language === 'bg' ? 'Към началото' : 'На главную'}
          </Link>
        </div>
        
        {/* Page Header */}
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            {language === 'bg' ? 'Всички имоти' : 'Все объекты недвижимости'}
          </h1>
          <p className="text-gray-600">
            {filteredProperties.length} {language === 'bg' ? 'имоти намерени' : 'объектов найдено'}
          </p>
        </div>
        
        {/* Search and Filter Bar */}
        <div className="bg-white rounded-lg shadow-sm p-4 mb-6">
          <div className="flex flex-col md:flex-row md:items-center gap-4">
            {/* Search */}
            <div className="relative flex-grow">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder={getTranslatedText('searchProperties', 'Поиск недвижимости...', 'Търсене на имоти...')}
                className="w-full px-4 py-2 pl-10 rounded-lg border border-gray-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none"
              />
              <Search size={18} className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400" />
            </div>
            
            {/* Sort Dropdown */}
            <div className="relative min-w-[180px]">
              <select
                value={sortOption}
                onChange={(e) => handleSortChange(e.target.value)}
                className="w-full appearance-none px-4 py-2 pr-10 rounded-lg border border-gray-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none"
              >
                <option value="default">{sortByText}</option>
                <option value="price-asc">{getTranslatedText('priceLowToHigh', 'Цена: от низкой к высокой', 'Цена: от ниска към висока')}</option>
                <option value="price-desc">{getTranslatedText('priceHighToLow', 'Цена: от высокой к низкой', 'Цена: от висока към ниска')}</option>
                <option value="newest">{getTranslatedText('newest', 'Новые', 'Най-нови')}</option>
              </select>
              <ArrowUpDown size={16} className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none" />
            </div>
            
            {/* Filter Toggle Button */}
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center justify-center py-2 px-4 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors"
            >
              <SlidersHorizontal size={18} className="mr-2" />
              <span>{filtersText}</span>
              <ChevronDown 
                size={18} 
                className={`ml-1 transition-transform ${showFilters ? 'rotate-180' : 'rotate-0'}`} 
              />
            </button>
          </div>
          
          {/* Extended Filters */}
          {showFilters && (
            <div className="mt-4 pt-4 border-t">
              <div className="flex flex-col md:flex-row md:items-end gap-4">
                <div className="flex-grow">
                  <label className="block text-sm text-gray-700 mb-1">
                    {getTranslatedText('priceRange', 'Диапазон цен', 'Ценови диапазон')}
                  </label>
                  <div className="flex gap-2 items-center">
                    <input
                      type="number"
                      value={priceRange.min}
                      onChange={(e) => handlePriceChange(e, 'min')}
                      placeholder={getTranslatedText('min', 'От', 'От')}
                      className="flex-1 px-3 py-2 rounded-lg border border-gray-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none"
                      min="0"
                    />
                    <span className="text-gray-500">-</span>
                    <input
                      type="number"
                      value={priceRange.max}
                      onChange={(e) => handlePriceChange(e, 'max')}
                      placeholder={getTranslatedText('max', 'До', 'До')}
                      className="flex-1 px-3 py-2 rounded-lg border border-gray-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none"
                      min="0"
                    />
                    <span className="text-gray-500 ml-1">€</span>
                  </div>
                </div>
                
                <button
                  onClick={resetFilters}
                  className="py-2 px-4 text-gray-600 hover:text-gray-800 transition-colors"
                >
                  {getTranslatedText('resetFilters', 'Сбросить фильтры', 'Изчисти филтрите')}
                </button>
              </div>
            </div>
          )}
        </div>
        
        {/* Properties List */}
        {filteredProperties.length === 0 ? (
          <div className="bg-white rounded-lg shadow-sm p-8 text-center">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">
              {getTranslatedText('noPropertiesFound', 'Объекты не найдены', 'Не са намерени имоти')}
            </h2>
            <p className="text-gray-600 mb-4">
              {getTranslatedText('tryDifferentSearch', 'Попробуйте изменить критерии поиска или сбросить фильтры', 'Опитайте с различни критерии за търсене или нулирайте филтрите')}
            </p>
            <button
              onClick={resetFilters}
              className="py-2 px-4 bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg transition-colors"
            >
              {getTranslatedText('resetFilters', 'Сбросить фильтры', 'Изчисти филтрите')}
            </button>
          </div>
        ) : (
          <>
            <div className="space-y-0">
              {displayedProperties.map(property => (
                <PropertyCard 
                  key={property.id} 
                  property={property} 
                  locations={locations} 
                />
              ))}
            </div>
            
            {/* Pagination */}
            {totalPages > 1 && (
              <div className="flex justify-center mt-8">
                <div className="flex space-x-1">
                  {/* Previous page button */}
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`px-4 py-2 rounded-md ${
                      currentPage === 1
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        : 'bg-white text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    {getTranslatedText('previous', 'Назад', 'Назад')}
                  </button>
                  
                  {/* Page numbers */}
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i + 1}
                      onClick={() => handlePageChange(i + 1)}
                      className={`w-10 h-10 rounded-md ${
                        currentPage === i + 1
                          ? 'bg-emerald-600 text-white'
                          : 'bg-white text-gray-700 hover:bg-gray-50'
                      }`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  
                  {/* Next page button */}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`px-4 py-2 rounded-md ${
                      currentPage === totalPages
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        : 'bg-white text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    {getTranslatedText('next', 'Вперед', 'Напред')}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AllPropertiesPage; 