/**
 * URL utilities for handling slugs and friendly URLs
 */

/**
 * Converts a string to a URL-friendly slug
 * @param {string} text - The text to convert to a slug
 * @param {boolean} preserveCyrillic - Whether to preserve Cyrillic characters (default: true)
 * @returns {string} A URL-friendly slug
 */
export const generateSlug = (text, preserveCyrillic = true) => {
  if (!text) return '';
  
  let result = text
    .toString()
    .toLowerCase()                      // Convert to lowercase
    .trim()                             // Trim whitespace
    .replace(/\s+/g, '-');              // Replace spaces with hyphens
  
  if (preserveCyrillic) {
    // Preserve Cyrillic while removing unsafe URL characters
    result = result
      .replace(/[^\w\u0400-\u04FF-]+/g, '') // Allow Latin, Cyrillic, and hyphens
      .replace(/--+/g, '-')                 // Replace multiple hyphens with a single hyphen
      .replace(/^-+/, '')                   // Remove leading hyphens
      .replace(/-+$/, '');                  // Remove trailing hyphens
  } else {
    // Original behavior: transliterate to Latin
    result = result
      .normalize('NFD')                     // Normalize to decomposed form for handling accents
      .replace(/[\u0300-\u036f]/g, '')      // Remove diacritics/accents
      .replace(/[^\w-]+/g, '')              // Remove non-word characters except hyphens
      .replace(/--+/g, '-')                 // Replace multiple hyphens with a single hyphen
      .replace(/^-+/, '')                   // Remove leading hyphens
      .replace(/-+$/, '');                  // Remove trailing hyphens
  }
  
  return result;
};

/**
 * Generate a location URL based on the location's name
 * @param {Object} location - The location object
 * @returns {string} The URL for the location
 */
export const getLocationUrl = (location) => {
  if (!location || !location.name) return '';
  return `/${generateSlug(location.name)}`;
};

/**
 * Generate a property URL based on the location and property names
 * @param {Object} property - The property object
 * @param {Array} locations - The array of all locations
 * @returns {string} The URL for the property
 */
export const getPropertyUrl = (property, locations) => {
  // Use title if available, otherwise fallback to name
  const propertyIdentifier = property.title || property.name;
  if (!property || !propertyIdentifier) return '';
  
  // Find the parent location - check both locationId and location_id
  const locationId = property.locationId || property.location_id;
  const location = locations.find(loc => loc.id === locationId);
  
  if (!location) return `/${generateSlug(propertyIdentifier)}`;
  
  // Combine location and property slugs
  return `/${generateSlug(location.name)}/${generateSlug(propertyIdentifier)}`;
};

/**
 * Find a location by its slug
 * @param {string} slug - The location slug
 * @param {Array} locations - The array of all locations
 * @returns {Object|null} The location object or null if not found
 */
export const findLocationBySlug = (slug, locations) => {
  return locations.find(location => generateSlug(location.name) === slug) || null;
};

/**
 * Find a property by its slug within a specific location
 * @param {string} locationSlug - The location slug
 * @param {string} propertySlug - The property slug
 * @param {Array} locations - The array of all locations
 * @param {Array} properties - The array of all properties
 * @returns {Object|null} The property object or null if not found
 */
export const findPropertyBySlug = (locationSlug, propertySlug, locations, properties) => {
  console.log(`[findPropertyBySlug] Searching for property with locationSlug: "${locationSlug}", propertySlug: "${propertySlug}"`);
  
  // First find the location
  const location = findLocationBySlug(locationSlug, locations);
  if (!location) {
    console.log('[findPropertyBySlug] Location not found for slug:', locationSlug);
    return null;
  }
  console.log('[findPropertyBySlug] Found location:', location);
  
  // Then find the property that belongs to that location and matches the property slug
  console.log(`[findPropertyBySlug] Searching within ${properties.length} properties for locationId: ${location.id}`);
  
  // Check both title and name for slug matching, and check both locationId and location_id
  const foundProperty = properties.find(property => {
    const propertyIdentifier = property.title || property.name;
    const generatedPropertySlug = generateSlug(propertyIdentifier);
    
    // Check both locationId and location_id fields since both might be used
    const locationMatch = (property.locationId === location.id) || (property.location_id === location.id);
    const isMatch = locationMatch && generatedPropertySlug === propertySlug;
    
    // Log details for each property checked
    console.log(`[findPropertyBySlug] Checking property ID: ${property.id}, locationId: ${property.locationId || property.location_id}, Identifier: "${propertyIdentifier}", Generated Slug: "${generatedPropertySlug}", Target Slug: "${propertySlug}", Location match: ${locationMatch}, Overall Match: ${isMatch}`);
    
    return isMatch;
  });
  
  if (!foundProperty) {
    console.log(`[findPropertyBySlug] Property not found with slug "${propertySlug}" in location "${locationSlug}" (ID: ${location.id})`);
  } else {
    console.log('[findPropertyBySlug] Found matching property:', foundProperty);
  }
  
  return foundProperty || null;
};