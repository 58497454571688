// storageUtils.js - Utility functions for handling file storage

/**
 * Storage keys used by the application
 */
const STORAGE_KEYS = {
  UPLOADED_FILES: 'uploadedFiles',
  LAST_SYNC: 'lastFileSync',
  APP_VERSION: 'appVersion'
};

/**
 * Current app version - increment when storage format changes
 */
const APP_VERSION = '1.0.0';

/**
 * Initialize the storage system with version check
 * This prevents issues when the app is updated
 */
export const initStorage = () => {
  const storedVersion = localStorage.getItem(STORAGE_KEYS.APP_VERSION);
  if (storedVersion !== APP_VERSION) {
    console.log(`Initializing storage for app version ${APP_VERSION}`);
    // Don't clear existing files, just update the version
    localStorage.setItem(STORAGE_KEYS.APP_VERSION, APP_VERSION);
  }
};

/**
 * Save an uploaded file to localStorage for persistence across deployments
 * @param {Object} fileData - File data including path, dataUrl, size, etc.
 */
export const saveFileToStorage = (fileData) => {
  // Get existing uploaded files or initialize empty array
  const existingFiles = getUploadedFiles();
  
  // Check if file with same path already exists
  const fileIndex = existingFiles.findIndex(file => file.path === fileData.path);
  
  if (fileIndex >= 0) {
    // Update existing file entry
    existingFiles[fileIndex] = {
      ...fileData,
      updatedAt: new Date().toISOString()
    };
  } else {
    // Add new file entry with timestamps
    existingFiles.push({
      ...fileData,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    });
  }
  
  // Save updated files list to localStorage
  try {
    localStorage.setItem(STORAGE_KEYS.UPLOADED_FILES, JSON.stringify(existingFiles));
    localStorage.setItem(STORAGE_KEYS.LAST_SYNC, new Date().toISOString());
    console.log(`File saved to storage: ${fileData.path}`);
    return true;
  } catch (error) {
    console.error('Error saving file to storage:', error);
    
    // If storage is full, try removing dataURLs from older files
    if (error.name === 'QuotaExceededError') {
      const compressedFiles = existingFiles.map((file, idx) => {
        // Keep the current file's dataURL, but remove from others
        if (idx === fileIndex || (fileIndex === -1 && file.path === fileData.path)) {
          return file;
        }
        // Remove dataURL from other files to save space
        const { dataUrl, ...fileWithoutData } = file;
        return fileWithoutData;
      });
      
      try {
        localStorage.setItem(STORAGE_KEYS.UPLOADED_FILES, JSON.stringify(compressedFiles));
        localStorage.setItem(STORAGE_KEYS.LAST_SYNC, new Date().toISOString());
        console.log('Saved files with compressed data to reduce storage usage');
        return true;
      } catch (innerError) {
        console.error('Failed to save even with compressed data:', innerError);
        return false;
      }
    }
    return false;
  }
};

/**
 * Get all uploaded files from localStorage
 * @returns {Array} Array of file objects
 */
export const getUploadedFiles = () => {
  try {
    const filesJson = localStorage.getItem(STORAGE_KEYS.UPLOADED_FILES);
    return filesJson ? JSON.parse(filesJson) : [];
  } catch (error) {
    console.error('Error retrieving files from storage:', error);
    return [];
  }
};

/**
 * Get a file by its path
 * @param {string} filePath - Path of the file to retrieve
 * @returns {Object|null} File object if found, null otherwise
 */
export const getFileByPath = (filePath) => {
  if (!filePath) return null;
  
  try {
    const files = getUploadedFiles();
    // Normalize paths for comparison
    const normalizedPath = filePath.startsWith('/') ? filePath : `/${filePath}`;
    return files.find(file => {
      const thisPath = file.path && file.path.startsWith('/') ? file.path : `/${file.path || ''}`;
      return thisPath === normalizedPath;
    }) || null;
  } catch (error) {
    console.error('Error getting file by path:', error);
    return null;
  }
};

/**
 * Get the URL for a file - handles development vs production environments
 * @param {string} filePath - Path of the file
 * @returns {string} URL to use for the file
 */
export const getFileUrl = (filePath) => {
  if (!filePath) return '';
  
  // If it's already a complete URL (http://, https://, data:), return as is
  if (filePath.startsWith('http://') || 
      filePath.startsWith('https://') || 
      filePath.startsWith('data:')) {
    return filePath;
  }
  
  // Ensure path has a consistent format - should always start with /
  const normalizedPath = filePath.startsWith('/') ? filePath : `/${filePath}`;
  
  // Check if this is a Supabase storage path
  if (normalizedPath.includes('supabase') || 
      normalizedPath.startsWith('/locations/') ||
      normalizedPath.startsWith('/properties/')) {
    // Import Supabase here to avoid circular dependencies
    const { supabase, STORAGE_BUCKET } = require('./supabaseClient');
    
    try {
      // Get the public URL from Supabase
      const path = normalizedPath.startsWith('/') ? normalizedPath.substring(1) : normalizedPath;
      const { data } = supabase.storage.from(STORAGE_BUCKET).getPublicUrl(path);
      
      if (data?.publicUrl) {
        return data.publicUrl;
      }
    } catch (error) {
      console.error('Error getting Supabase URL:', error);
    }
  }
  
  // For development, check if we have a data URL stored in localStorage
  if (process.env.NODE_ENV === 'development') {
    const fileData = getFileByPath(normalizedPath);
    if (fileData?.dataUrl) {
      return fileData.dataUrl;
    }
  }
  
  // For production, the file should be in the static files
  // If the path includes 'src/', remove it as it's not present in production build
  let productionPath = normalizedPath;
  if (productionPath.startsWith('/src/')) {
    productionPath = productionPath.replace('/src/', '/');
  }
  
  // Return the normalized path for the browser to load from the server
  return productionPath;
};

/**
 * Delete a file by path
 * @param {string} filePath - Path of the file to delete
 * @returns {boolean} True if deleted, false otherwise
 */
export const deleteFile = (filePath) => {
  if (!filePath) return false;
  
  try {
    const files = getUploadedFiles();
    const initialLength = files.length;
    
    // Normalize the path for comparison
    const normalizedPath = filePath.startsWith('/') ? filePath : `/${filePath}`;
    
    const updatedFiles = files.filter(file => {
      const thisPath = file.path && file.path.startsWith('/') ? file.path : `/${file.path || ''}`;
      return thisPath !== normalizedPath;
    });
    
    if (updatedFiles.length !== initialLength) {
      localStorage.setItem(STORAGE_KEYS.UPLOADED_FILES, JSON.stringify(updatedFiles));
      localStorage.setItem(STORAGE_KEYS.LAST_SYNC, new Date().toISOString());
      return true;
    }
    
    return false;
  } catch (error) {
    console.error('Error deleting file:', error);
    return false;
  }
};

/**
 * Load exported files data from server
 * Useful to sync server-side files with client storage
 * @returns {Promise<Array>} Array of file objects
 */
export const loadExportedFiles = async () => {
  try {
    const response = await fetch('/exported-files.json');
    if (response.ok) {
      const files = await response.json();
      return files;
    }
  } catch (error) {
    console.error('Error loading exported files:', error);
  }
  return [];
};

/**
 * Merge local and server file data, keeping the most up-to-date info
 * @param {Array} serverFiles - Array of file objects from server
 * @returns {Array} Merged array of file objects
 */
export const mergeWithServerFiles = async (serverFiles = []) => {
  if (!serverFiles || !serverFiles.length) {
    try {
      serverFiles = await loadExportedFiles();
    } catch (error) {
      console.error('Error loading server files:', error);
      return getUploadedFiles();
    }
  }
  
  if (!serverFiles.length) {
    return getUploadedFiles();
  }
  
  const localFiles = getUploadedFiles();
  
  // Create a map for faster lookups
  const localFileMap = new Map();
  localFiles.forEach(file => {
    if (file.path) {
      localFileMap.set(file.path, file);
    }
  });
  
  const mergedFiles = [...localFiles]; // Start with all local files
  
  // Add or update with server files
  serverFiles.forEach(serverFile => {
    if (!serverFile.path) return;
    
    const localFile = localFileMap.get(serverFile.path);
    
    if (!localFile) {
      // New file from server, add it
      mergedFiles.push(serverFile);
    } else {
      // Existing file - check which is newer
      const serverDate = serverFile.updatedAt ? new Date(serverFile.updatedAt) : new Date(0);
      const localDate = localFile.updatedAt ? new Date(localFile.updatedAt) : new Date(0);
      
      if (serverDate > localDate) {
        // Server file is newer, update the local copy
        const index = mergedFiles.findIndex(f => f.path === serverFile.path);
        if (index >= 0) {
          // Preserve local dataUrl if server doesn't have one
          if (!serverFile.dataUrl && localFile.dataUrl) {
            serverFile.dataUrl = localFile.dataUrl;
          }
          mergedFiles[index] = serverFile;
        }
      }
    }
  });
  
  // Save the merged files
  localStorage.setItem(STORAGE_KEYS.UPLOADED_FILES, JSON.stringify(mergedFiles));
  localStorage.setItem(STORAGE_KEYS.LAST_SYNC, new Date().toISOString());
  
  return mergedFiles;
}; 