import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  ChevronLeft, 
  MapPin, 
  Phone, 
  Mail, 
  ChevronRight, 
  ChevronLeft as ChevronLeftIcon, 
  Maximize, 
  Calendar, 
  Home, 
  Bath, 
  BedDouble, 
  Square, 
  Wifi, 
  ParkingCircle,
  Navigation,
  Building,
  CheckCircle2
} from 'lucide-react';
import { useData } from '../hooks/useData';
import { useLanguage } from '../hooks/useLanguage';
import { getImageUrl, getPlaceholderImage } from '../utils/imageUtils';
import { findPropertyBySlug, getLocationUrl, getPropertyUrl } from '../utils/urlUtils';
import ImageGalleryModal from '../components/ImageGalleryModal';

// RelatedPropertyCard component for showing similar listings
const RelatedPropertyCard = ({ property, locations }) => {
  const { language } = useLanguage();
  
  const displayName = language === 'bg' && property.name_bg ? property.name_bg : (property.title || property.name);
  const displayPrice = property.price ? `${property.price} €` : '';
  
  return (
    <Link to={getPropertyUrl(property, locations)} className="block group">
      <div className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-all duration-300">
        <div className="relative h-40 overflow-hidden">
          {property.images && property.images.length > 0 ? (
            <img 
              src={getImageUrl(property.images[0])} 
              alt={displayName} 
              className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
              onError={(e) => {
                e.target.src = getPlaceholderImage('properties');
              }}
            />
          ) : (
            <div className="w-full h-full bg-gray-200 flex items-center justify-center">
              <span className="text-gray-400">No image</span>
            </div>
          )}
        </div>
        <div className="p-3">
          <h3 className="font-medium text-gray-800 line-clamp-1">{displayName}</h3>
          <p className="text-emerald-600 font-semibold mt-1">{displayPrice}</p>
        </div>
      </div>
    </Link>
  );
};

const RealEstateDetailPage = () => {
  const { locationSlug, propertySlug } = useParams();
  const { realEstates, locations, getRealEstatesByLocation, isLoading } = useData();
  const { t, language } = useLanguage();
  
  const [estate, setEstate] = useState(null);
  const [location, setLocation] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showFullscreenGallery, setShowFullscreenGallery] = useState(false);
  const [relatedProperties, setRelatedProperties] = useState([]);

  useEffect(() => {
    // Only attempt to find the property once data has loaded
    if (!isLoading) {
      if (locationSlug && propertySlug && locations.length > 0 && realEstates.length > 0) {
        // Use the utility function to find the property by slugs
        const foundEstate = findPropertyBySlug(locationSlug, propertySlug, locations, realEstates);
        
        if (foundEstate) {
          setEstate(foundEstate);
          // Find the associated location once the estate is found
          const foundLocation = locations.find(loc => loc.id === foundEstate.locationId || loc.id === foundEstate.location_id);
          setLocation(foundLocation);
          
          // Find related properties in the same location, excluding current property
          if (foundLocation) {
            const locationProperties = getRealEstatesByLocation(foundLocation.id)
              .filter(property => property.id !== foundEstate.id)
              .slice(0, 3); // Only take up to 3 related properties
            
            setRelatedProperties(locationProperties);
          }
        } else {
          // If no estate is found for these slugs, explicitly set to null
          setEstate(null);
          setLocation(null);
          setRelatedProperties([]);
        }
      } else {
        // If slugs or data are missing, ensure state is null
        setEstate(null);
        setLocation(null);
        setRelatedProperties([]);
      }
    }
  }, [locationSlug, propertySlug, locations, realEstates, getRealEstatesByLocation, isLoading]);

  const handlePrevImage = () => {
    if (!estate || !estate.images || estate.images.length === 0) return;
    setCurrentImageIndex(prevIndex => 
      prevIndex === 0 ? estate.images.length - 1 : prevIndex - 1
    );
  };

  const handleNextImage = () => {
    if (!estate || !estate.images || estate.images.length === 0) return;
    setCurrentImageIndex(prevIndex => 
      prevIndex === estate.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const openFullscreenGallery = () => {
    if (estate?.images?.length > 0) {
      setShowFullscreenGallery(true);
    }
  };

  const closeFullscreenGallery = () => {
    setShowFullscreenGallery(false);
  };

  // --- Render Logic ---

  // 1. Loading State
  if (isLoading) {
    return (
      <div className="min-h-screen pt-16 flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <div className="w-12 h-12 border-4 border-emerald-500 border-t-transparent rounded-full animate-spin mx-auto mb-4"></div>
          <p className="text-gray-600">{t('loading') || 'Loading...'}</p>
        </div>
      </div>
    );
  }

  // 2. Not Found State (After loading is complete)
  if (!estate) {
    return (
      <div className="min-h-screen pt-16 flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">{t('propertyNotFound') || 'Property not found'}</h2>
          <Link 
            to="/" 
            className="inline-flex items-center text-emerald-600 hover:text-emerald-800"
          >
            <ChevronLeft size={18} className="mr-1" />
            {t('backToLocations') || 'Back to locations'}
          </Link>
        </div>
      </div>
    );
  }

  // 3. Property Found - Display Details
  const displayTitle = language === 'bg' && estate.title_bg ? estate.title_bg : (estate.title || estate.name);
  const displayDescription = language === 'bg' && estate.description_bg ? estate.description_bg : estate.description;
  const displayLocationName = location ? (language === 'bg' && location.name_bg ? location.name_bg : location.name) : t('unknownLocation');

  // Extract property features
  const hasFeatures = estate.features || estate.bedrooms || estate.bathrooms || estate.area || estate.year_built;
  
  return (
    <div className="min-h-screen pb-16 bg-gray-50">
      {/* Fullscreen gallery modal */}
      {showFullscreenGallery && estate.images && estate.images.length > 0 && (
        <ImageGalleryModal
          images={estate.images}
          initialIndex={currentImageIndex}
          onClose={closeFullscreenGallery}
          altPrefix={displayTitle}
        />
      )}
      
      <div className="container mx-auto px-4">
        {/* Breadcrumbs & Back Link */}
        <div className="py-6">
          <div className="flex items-center text-sm text-gray-500 mb-4">
            <Link to="/" className="hover:text-emerald-600">Home</Link>
            <span className="mx-2">›</span>
            {location && (
              <>
                <Link to={getLocationUrl(location)} className="hover:text-emerald-600">
                  {displayLocationName}
                </Link>
                <span className="mx-2">›</span>
              </>
            )}
            <span className="text-gray-800 line-clamp-1">{displayTitle}</span>
          </div>
          
          <Link 
            to={location ? getLocationUrl(location) : "/"} 
            className="inline-flex items-center text-emerald-600 hover:text-emerald-800"
          >
            <ChevronLeft size={18} className="mr-1" />
            {location ? `${t('backTo')} ${displayLocationName}` : t('backToLocations')}
          </Link>
        </div>

        {/* Main Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-12">
          {/* Left Column (2/3): Gallery & Details */}
          <div className="lg:col-span-2 space-y-6">
            {/* Image Gallery Section */}
            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="relative h-80 md:h-[60vh] max-h-[600px]">
                {estate.images && estate.images.length > 0 ? (
                  <>
                    <img 
                      src={getImageUrl(estate.images[currentImageIndex])} 
                      alt={displayTitle} 
                      className="w-full h-full object-cover cursor-pointer"
                      onClick={openFullscreenGallery}
                      onError={(e) => {
                        e.target.src = getPlaceholderImage('properties');
                      }}
                    />
                    
                    {/* Fullscreen button */}
                    <button 
                      onClick={openFullscreenGallery}
                      className="absolute top-4 right-4 p-2 rounded-full bg-white/80 hover:bg-white text-gray-800 shadow-sm z-10 transition-all"
                      aria-label={t('viewFullScreenGallery') || 'View full-screen gallery'}
                    >
                      <Maximize size={20} />
                    </button>
                    
                    {/* Navigation for multiple images */}
                    {estate.images.length > 1 && (
                      <>
                        <button 
                          onClick={handlePrevImage}
                          className="absolute left-4 top-1/2 -translate-y-1/2 p-3 rounded-full bg-white/80 hover:bg-white text-gray-800 shadow-sm z-10 transition-all"
                          aria-label={t('previousImage') || 'Previous image'}
                        >
                          <ChevronLeftIcon size={20} />
                        </button>
                        <button 
                          onClick={handleNextImage}
                          className="absolute right-4 top-1/2 -translate-y-1/2 p-3 rounded-full bg-white/80 hover:bg-white text-gray-800 shadow-sm z-10 transition-all"
                          aria-label={t('nextImage') || 'Next image'}
                        >
                          <ChevronRight size={20} />
                        </button>
                        
                        {/* Image counter */}
                        <div className="absolute bottom-4 right-4 bg-black/60 text-white text-sm px-3 py-1 rounded-full">
                          {currentImageIndex + 1} / {estate.images.length}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  // Placeholder if no images
                  <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                    <span className="text-gray-400">{t('noImageAvailable') || 'No image available'}</span>
                  </div>
                )}
              </div>
              
              {/* Thumbnail Gallery */}
              {estate.images && estate.images.length > 1 && (
                <div className="p-4 border-t">
                  <div className="flex overflow-x-auto gap-2 snap-x scrollbar-hide">
                    {estate.images.map((image, index) => (
                      <div 
                        key={index}
                        onClick={() => setCurrentImageIndex(index)}
                        className={`w-20 h-20 flex-shrink-0 rounded-md overflow-hidden cursor-pointer snap-start
                          ${index === currentImageIndex 
                            ? 'ring-2 ring-emerald-500' 
                            : 'ring-1 ring-gray-200 hover:ring-gray-300'
                          }`}
                      >
                        <img 
                          src={getImageUrl(image)} 
                          alt={`${displayTitle} - thumbnail ${index + 1}`} 
                          className="w-full h-full object-cover"
                          onError={(e) => {
                            e.target.src = getPlaceholderImage('properties');
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            
            {/* Main Content Section (Integrated) */}
            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
              {/* Property Title & Price - Modified to center the title */}
              <div className="p-6 border-b">
                <div className="flex flex-col items-center text-center">
                  <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-2">
                    {displayTitle}
                  </h1>
                  {location && (
                    <div className="flex items-center text-gray-600 mt-1 text-sm md:text-base">
                      <MapPin size={16} className="mr-2 flex-shrink-0" />
                      <span>{displayLocationName}</span>
                    </div>
                  )}
                </div>
              </div>
              
              <div className="p-6">
                {/* Key Features Bar with Price and Area positioned at ends */}
                <div className="flex flex-wrap items-center justify-between mb-6 pb-6 border-b">
                  {/* Price moved here from the title section */}
                  <div className="text-2xl md:text-3xl font-semibold text-emerald-600 flex-shrink-0 order-1">
                    {estate.price ? `${estate.price} €` : t('priceOnRequest') || 'Price on request'}
                  </div>
                  
                  {/* Middle features section */}
                  <div className="flex flex-wrap gap-6 justify-center order-2 mx-auto">
                    {estate.bedrooms && (
                      <div className="flex items-center">
                        <BedDouble className="w-6 h-6 text-emerald-600 mr-2" />
                        <div>
                          <div className="font-medium">{estate.bedrooms}</div>
                          <div className="text-sm text-gray-500">{t('bedrooms') || 'Bedrooms'}</div>
                        </div>
                      </div>
                    )}
                    
                    {estate.bathrooms && (
                      <div className="flex items-center">
                        <Bath className="w-6 h-6 text-emerald-600 mr-2" />
                        <div>
                          <div className="font-medium">{estate.bathrooms}</div>
                          <div className="text-sm text-gray-500">{t('bathrooms') || 'Bathrooms'}</div>
                        </div>
                      </div>
                    )}
                    
                    {estate.year_built && (
                      <div className="flex items-center">
                        <Calendar className="w-6 h-6 text-emerald-600 mr-2" />
                        <div>
                          <div className="font-medium">{estate.year_built}</div>
                          <div className="text-sm text-gray-500">{t('yearBuilt') || 'Year built'}</div>
                        </div>
                      </div>
                    )}
                    
                    {estate.type && (
                      <div className="flex items-center">
                        <Building className="w-6 h-6 text-emerald-600 mr-2" />
                        <div>
                          <div className="font-medium">{estate.type}</div>
                          <div className="text-sm text-gray-500">{t('propertyType') || 'Type'}</div>
                        </div>
                      </div>
                    )}
                  </div>
                  
                  {/* Area feature moved to the right */}
                  {estate.area && (
                    <div className="flex items-center order-3">
                      <Home className="w-6 h-6 text-emerald-600 mr-2" />
                      <div>
                        <div className="font-medium">{estate.area} m²</div>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Description Content */}
                <div className="space-y-6">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">{t('description') || 'Описание'}</h2>
                    <div className="text-gray-700 space-y-4 prose max-w-none" style={{ whiteSpace: 'pre-line' }}>
                      {displayDescription ? (
                        displayDescription
                      ) : (
                        <p className="text-gray-500 italic">{t('noDescriptionAvailable') || 'No description available'}</p>
                      )}
                    </div>
                  </div>
                  
                  {/* Additional features list */}
                  {estate.features && estate.features.length > 0 && (
                    <div className="mt-8">
                      <h3 className="font-medium text-gray-800 mb-4">{t('additionalFeatures') || 'Additional Features'}</h3>
                      <ul className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-4">
                        {estate.features.map((feature, index) => (
                          <li key={index} className="flex items-start">
                            <CheckCircle2 className="w-5 h-5 text-emerald-500 mr-2 flex-shrink-0 mt-0.5" />
                            <span className="text-gray-700">{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          
          {/* Right Column (1/3): Contact & Related Properties */}
          <div className="space-y-6">
            {/* Contact Box */}
            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">
                  {t('contactUs') || 'Contact Us'}
                </h2>
                
                <div className="space-y-4">
                  <div className="flex items-center">
                    <Phone className="w-5 h-5 text-emerald-600 mr-3 flex-shrink-0" />
                    <span className="text-gray-700">+359 889 224 687</span>
                  </div>
                  
                  <div className="flex items-center">
                    <Mail className="w-5 h-5 text-emerald-600 mr-3 flex-shrink-0" />
                    <span className="text-gray-700 break-all">irinabervina@gmail.com</span>
                  </div>
                </div>
                
                <div className="mt-6 flex flex-col space-y-3">
                  <a 
                    href="tel:+359889224687" 
                    className="flex items-center justify-center py-2.5 px-4 bg-emerald-600 hover:bg-emerald-700 text-white rounded-lg transition-colors"
                  >
                    <Phone size={16} className="mr-2" />
                    {t('callNow') || 'Позвонить'}
                  </a>
                  
                  <a 
                    href="mailto:irinabervina@gmail.com" 
                    className="flex items-center justify-center py-2.5 px-4 border border-emerald-600 text-emerald-600 hover:bg-emerald-50 rounded-lg transition-colors"
                  >
                    <Mail size={16} className="mr-2" />
                    {t('sendEmail') || 'Отправить письмо'}
                  </a>
                </div>
              </div>
            </div>
            
            {/* Related Properties */}
            {relatedProperties.length > 0 && (
              <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                <div className="p-6">
                  <h2 className="text-xl font-semibold text-gray-800 mb-4">
                    {t('relatedProperties') || 'More Properties in this Area'}
                  </h2>
                  
                  <div className="space-y-4">
                    {relatedProperties.map(property => (
                      <RelatedPropertyCard 
                        key={property.id} 
                        property={property} 
                        locations={locations} 
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateDetailPage; 