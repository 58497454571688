import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useData } from '../../hooks/useData';
import { useLanguage } from '../../hooks/useLanguage';
import { AlertTriangle, Check, Trash2, Mail, MailOpen, Eye } from 'lucide-react';

const ContactSubmissionsPage = () => {
  const { contactSubmissions, markContactSubmissionAsRead, deleteContactSubmission } = useData();
  const { t } = useLanguage();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [filter, setFilter] = useState('all'); // 'all', 'unread'

  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ru-RU', {
      day: '2-digit',
      month: '2-digit', 
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  // Handle marking a submission as read
  const handleMarkAsRead = (id) => {
    markContactSubmissionAsRead(id);
  };

  // Handle showing the delete confirmation modal
  const handleShowDeleteModal = (id) => {
    const submission = contactSubmissions.find(sub => sub.id === id);
    setSelectedSubmission(submission);
    setShowDeleteModal(true);
  };

  // Handle showing the view modal
  const handleViewSubmission = (id) => {
    const submission = contactSubmissions.find(sub => sub.id === id);
    setSelectedSubmission(submission);
    setShowViewModal(true);
    
    // Mark as read if it's unread
    if (submission && !submission.isRead) {
      markContactSubmissionAsRead(id);
    }
  };

  // Handle deleting a submission
  const handleDeleteSubmission = () => {
    if (selectedSubmission) {
      deleteContactSubmission(selectedSubmission.id);
      setShowDeleteModal(false);
      setSelectedSubmission(null);
    }
  };

  // Close modals
  const closeModals = () => {
    setShowDeleteModal(false);
    setShowViewModal(false);
    setSelectedSubmission(null);
  };

  // Filter submissions based on current filter
  const filteredSubmissions = filter === 'unread' 
    ? contactSubmissions.filter(submission => !submission.isRead)
    : contactSubmissions;

  // Count of unread submissions
  const unreadCount = contactSubmissions.filter(submission => !submission.isRead).length;

  return (
    <div>
      <h1 className="text-2xl font-bold text-gray-800 mb-4">{t('contactSubmissions')}</h1>
      
      <div className="mb-6 flex flex-wrap justify-between items-center">
        <div className="flex items-center space-x-2 mb-4 sm:mb-0">
          <div className="bg-white px-4 py-2 rounded-lg shadow-sm border border-gray-100">
            <span className="font-medium">{t('totalSubmissions')}:</span> {contactSubmissions.length}
          </div>
          {unreadCount > 0 && (
            <div className="bg-amber-50 px-4 py-2 rounded-lg shadow-sm border border-amber-100 flex items-center">
              <AlertTriangle size={16} className="text-amber-500 mr-2" />
              <span><span className="font-medium">{t('newSubmissions')}:</span> {unreadCount}</span>
            </div>
          )}
        </div>
        
        <div className="flex space-x-2">
          <button 
            onClick={() => setFilter('all')} 
            className={`px-3 py-1.5 rounded-lg text-sm ${
              filter === 'all' 
                ? 'bg-emerald-600 text-white' 
                : 'bg-white text-gray-700 border border-gray-200 hover:bg-gray-50'
            }`}
          >
            Все
          </button>
          <button 
            onClick={() => setFilter('unread')} 
            className={`px-3 py-1.5 rounded-lg text-sm ${
              filter === 'unread' 
                ? 'bg-emerald-600 text-white' 
                : 'bg-white text-gray-700 border border-gray-200 hover:bg-gray-50'
            }`}
          >
            Непрочитанные ({unreadCount})
          </button>
        </div>
      </div>
      
      <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden">
        {filteredSubmissions.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('status')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('name')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('email')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('message')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('date')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('actions')}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredSubmissions.map((submission) => (
                  <tr key={submission.id} className={!submission.isRead ? 'bg-emerald-50' : ''}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {submission.isRead ? (
                        <MailOpen size={18} className="text-gray-400" />
                      ) : (
                        <Mail size={18} className="text-emerald-600" />
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="font-medium text-gray-900">{submission.name}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <a href={`mailto:${submission.email}`} className="text-emerald-600 hover:text-emerald-800">
                        {submission.email}
                      </a>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500 max-w-xs truncate" title={submission.message}>
                        {submission.message}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(submission.created_at)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm">
                      <div className="flex justify-end space-x-2">
                        <button
                          onClick={() => handleViewSubmission(submission.id)}
                          className="text-blue-600 hover:text-blue-800 p-1 rounded-md hover:bg-blue-50"
                          title="Просмотреть"
                        >
                          <Eye size={18} />
                        </button>
                        {!submission.isRead && (
                          <button
                            onClick={() => handleMarkAsRead(submission.id)}
                            className="text-emerald-600 hover:text-emerald-800 p-1 rounded-md hover:bg-emerald-50"
                            title={t('markAsRead')}
                          >
                            <Check size={18} />
                          </button>
                        )}
                        <button
                          onClick={() => handleShowDeleteModal(submission.id)}
                          className="text-red-600 hover:text-red-800 p-1 rounded-md hover:bg-red-50"
                          title={t('delete')}
                        >
                          <Trash2 size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="p-6 text-center text-gray-500">
            {t('noSubmissions')}
          </div>
        )}
      </div>
      
      {/* View Message Modal */}
      {showViewModal && selectedSubmission && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start mb-3">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <Mail className="h-6 w-6 text-blue-600" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-1">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 flex justify-between items-center">
                      <span>Сообщение от {selectedSubmission.name}</span>
                      {!selectedSubmission.isRead && (
                        <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
                          Новое
                        </span>
                      )}
                    </h3>
                    <div className="mt-2">
                      <div className="mb-3">
                        <p className="text-sm text-gray-500">Email:</p>
                        <p className="text-sm font-medium text-gray-900">
                          <a href={`mailto:${selectedSubmission.email}`} className="text-blue-600 hover:underline">
                            {selectedSubmission.email}
                          </a>
                        </p>
                      </div>
                      <div className="mb-3">
                        <p className="text-sm text-gray-500">Дата:</p>
                        <p className="text-sm font-medium text-gray-900">{formatDate(selectedSubmission.created_at)}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Сообщение:</p>
                        <div className="mt-1 p-3 bg-gray-50 rounded border border-gray-200">
                          <p className="text-sm text-gray-900 whitespace-pre-wrap">{selectedSubmission.message}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={closeModals}
                  className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Закрыть
                </button>
                <a
                  href={`mailto:${selectedSubmission.email}`}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Ответить по email
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Delete Confirmation Modal */}
      {showDeleteModal && selectedSubmission && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <AlertTriangle className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {t('confirmDelete')}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={handleDeleteSubmission}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {t('delete')}
                </button>
                <button
                  type="button"
                  onClick={closeModals}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {t('cancel')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactSubmissionsPage; 