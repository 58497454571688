import { v4 as uuidv4 } from 'uuid';
import { supabase, STORAGE_BUCKET, uploadImage, deleteImage } from './supabaseClient';

// Keep track of ongoing uploads to prevent duplicates
const ongoingUploads = new Map();

// Keep track of uploaded files for compatibility with existing code
let cachedUploadedFiles = [];

/**
 * Upload a file to Supabase Storage
 * @param {File} file - The file to upload
 * @param {string} path - Optional folder and file path
 * @returns {Promise<Object>} Upload result with URL and metadata
 */
export const uploadFile = async (file, path = '') => {
  if (!file) {
    return Promise.reject(new Error('Invalid file'));
  }

  try {
    // Ensure path is properly formatted
    const filePath = path ? path : `${uuidv4()}.${file.name.split('.').pop()}`;
    
    // Create a unique key for this upload
    const uploadKey = `${filePath}_${Date.now()}`;
    
    // Check if this exact upload is already in progress
    if (ongoingUploads.has(uploadKey)) {
      return ongoingUploads.get(uploadKey);
    }
    
    // Check internet connection
    if (typeof navigator !== 'undefined' && navigator.onLine === false) {
      throw new Error('Отсутствует подключение к интернету. Проверьте соединение и попробуйте снова.');
    }
    
    // Create a promise for this upload
    const uploadPromise = (async () => {
      try {
        console.log(`Starting upload for ${filePath}...`);
        // Upload to Supabase Storage
        const publicUrl = await uploadImage(file, filePath);
        
        if (!publicUrl) {
          throw new Error('Failed to upload file to Supabase storage');
        }
        
        console.log(`Upload successful, got public URL: ${publicUrl}`);
        
        const result = {
          path: filePath,
          url: publicUrl,
          size: file.size,
          type: file.type,
          name: file.name,
          uploadedAt: new Date().toISOString()
        };
        
        return result;
      } catch (uploadError) {
        // Properly format and propagate the error
        console.error('Error in upload promise:', uploadError);
        
        // Preserve original error details when possible
        if (uploadError instanceof Error) {
          throw uploadError;
        } else {
          throw new Error(typeof uploadError === 'string' ? 
            uploadError : 'Ошибка загрузки файла. Пожалуйста, попробуйте еще раз.');
        }
      } finally {
        // Remove from ongoing uploads when done
        ongoingUploads.delete(uploadKey);
      }
    })();
    
    // Store the promise so we can return it for duplicate calls
    ongoingUploads.set(uploadKey, uploadPromise);
    
    return uploadPromise;
  } catch (error) {
    console.error('Error in uploadFile:', error);
    throw error; // Propagate the error
  }
};

/**
 * Upload multiple files to Supabase storage
 * @param {File[]} files - Array of files to upload
 * @param {string} prefix - Optional folder prefix
 * @returns {Promise<Object[]>} Array of upload results
 */
export const uploadMultipleFiles = async (files, path = '') => {
  const uploadPromises = Array.from(files).map(file => uploadFile(file, path));
  return Promise.all(uploadPromises);
};

/**
 * Delete a file from Supabase storage
 * @param {string} filePath - Path of the file to delete
 * @returns {Promise<boolean>} True if deleted, false otherwise
 */
export const deleteFile = async (filePath) => {
  if (!filePath) return false;
  
  try {
    // Extract just the file path if a full URL is provided
    const path = filePath.includes('storage/v1/object/public')
      ? filePath.split(`${STORAGE_BUCKET}/`)[1]
      : filePath;
    
    const success = await deleteImage(path);
    
    // Update cached files
    if (success) {
      cachedUploadedFiles = cachedUploadedFiles.filter(file => file.path !== filePath);
    }
    
    return success;
  } catch (error) {
    console.error('Error deleting file:', error);
    return false;
  }
};

/**
 * Get a file's public URL
 * @param {string} filePath - Path of the file
 * @returns {string} Public URL of the file
 */
export const getFileUrl = (filePath) => {
  if (!filePath) return '';
  
  // If it's already a complete URL, return as is
  if (filePath.startsWith('http://') || 
      filePath.startsWith('https://') || 
      filePath.startsWith('data:')) {
    return filePath;
  }
  
  // Get the public URL from Supabase
  const { data } = supabase.storage
    .from(STORAGE_BUCKET)
    .getPublicUrl(filePath);
    
  return data?.publicUrl || '';
};

/**
 * List all files in a folder
 * @param {string} folderPath - Path of the folder
 * @returns {Promise<Object[]>} Array of file objects
 */
export const listFiles = async (folderPath = '') => {
  try {
    const { data, error } = await supabase.storage
      .from(STORAGE_BUCKET)
      .list(folderPath);
      
    if (error) {
      throw error;
    }
    
    // Update our cached files list with the storage list
    if (data && data.length > 0) {
      const updatedFiles = data.map(item => {
        const filePath = folderPath ? `${folderPath}/${item.name}` : item.name;
        const { data: urlData } = supabase.storage
          .from(STORAGE_BUCKET)
          .getPublicUrl(filePath);
        
        return {
          path: filePath,
          name: item.name,
          size: item.metadata?.size || 0,
          type: item.metadata?.mimetype || '',
          url: urlData?.publicUrl || '',
          uploadedAt: item.created_at || new Date().toISOString()
        };
      });
      
      // Merge with cached files (keeping local cached data for files not in the response)
      const pathsInResponse = new Set(updatedFiles.map(file => file.path));
      cachedUploadedFiles = [
        ...updatedFiles,
        ...cachedUploadedFiles.filter(file => !pathsInResponse.has(file.path))
      ];
    }
    
    return data || [];
      } catch (error) {
    console.error('Error listing files:', error);
    return [];
  }
};

/**
 * Get list of uploaded files (for compatibility with existing code)
 * @returns {Array} Array of file objects
 */
export const getUploadedFiles = async () => {
  try {
    // If we haven't fetched files yet, do so now
    if (cachedUploadedFiles.length === 0) {
      await listFiles();
    }
    return cachedUploadedFiles;
  } catch (error) {
    console.error('Error getting uploaded files:', error);
    return cachedUploadedFiles;
  }
};

/**
 * Create a data URL from a file (for local preview)
 * @param {File} file - The file to convert
 * @returns {Promise<string>} Data URL
 */
export const fileToDataUrl = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

/**
 * Check if file is an image
 * @param {string} fileType - MIME type of the file
 * @returns {boolean} True if file is an image
 */
export const isImageFile = (fileType) => {
  return fileType && fileType.startsWith('image/');
};

/**
 * Format file size for display
 * @param {number} bytes - File size in bytes
 * @returns {string} Formatted file size
 */
export const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

/**
 * Extracts the file data from a data URL
 * @param {string} dataUrl - The data URL containing the file data
 * @returns {Blob} - The file data as a Blob
 */
export const dataUrlToBlob = (dataUrl) => {
  if (!dataUrl || typeof dataUrl !== 'string') {
    return new Blob([], { type: 'application/octet-stream' });
  }
  
  try {
    // Extract the MIME type and base64 data
    const [typeInfo, base64Data] = dataUrl.split(',');
    const mimeType = typeInfo.match(/:(.*?);/)[1];
    
    // Convert base64 to binary
    const binaryStr = atob(base64Data);
    const len = binaryStr.length;
    const bytes = new Uint8Array(len);
    
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryStr.charCodeAt(i);
    }
    
    return new Blob([bytes], { type: mimeType });
  } catch (error) {
    console.error('Error converting data URL to blob:', error);
    return new Blob([], { type: 'application/octet-stream' });
  }
}; 