import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { MapPin, Mail, Phone, Menu, X } from 'lucide-react';

// Context Providers
import { AuthProvider } from './context/AuthContext';
import { DataProvider } from './context/DataContext';
import { LanguageProvider } from './context/LanguageContext';

// Language Switcher
import LanguageSwitcher from './components/LanguageSwitcher';

// Admin Components
import AdminLayout from './components/admin/AdminLayout';
import AdminDashboard from './pages/admin/AdminDashboard';
import LocationsPage from './pages/admin/LocationsPage';
import LocationFormPage from './pages/admin/LocationFormPage';
import RealEstatesPage from './pages/admin/RealEstatesPage';
import RealEstateFormPage from './pages/admin/RealEstateFormPage';
import UploadMonitorPage from './pages/admin/UploadMonitorPage';
import ContactSubmissionsPage from './pages/admin/ContactSubmissionsPage';
import LoginPage from './pages/LoginPage';
import ProtectedRoute from './components/ProtectedRoute';

// Public Pages
import LocationDetailPage from './pages/LocationDetailPage';
import RealEstateDetailPage from './pages/RealEstateDetailPage';
import TestSupabasePage from './pages/TestSupabasePage';
import StorageTestPage from './pages/StorageTestPage';
import AllPropertiesPage from './pages/AllPropertiesPage';

// Custom Hooks
import { useData } from './hooks/useData';
import { useLanguage } from './hooks/useLanguage';

// Utils
import { getImageUrl, getPlaceholderImage } from './utils/imageUtils';
import { processStoredFiles, verifyPublicDirectories, exportFilesToBuild } from './utils/buildHelper';
import { addDebugTools } from './utils/devHelper';
import { getLocationUrl, getPropertyUrl } from './utils/urlUtils';
import { initializeDatabase } from './utils/initializeDatabase';

// Add this at the beginning of the file, right after the imports
// This is a client-side check to make sure the image directories exist
// In a production app, this would be handled by the server
const initializeImageDirectories = () => {
  // Define our image directory structure
  const imageDirectories = [
    '/upload/locations',
    '/upload/properties'
  ];
  
  // Log information about directories being monitored
  console.log('Image directories tracked for uploads:', imageDirectories);
  
  // Initialize localStorage structure if not already present
  if (!localStorage.getItem('uploadedFiles')) {
    localStorage.setItem('uploadedFiles', JSON.stringify([]));
    console.log('Image upload tracking initialized in localStorage');
  }
  
  // Check if we already have directories information stored
  const existingDirs = new Set();
  try {
    const uploadedFiles = JSON.parse(localStorage.getItem('uploadedFiles') || '[]');
    uploadedFiles.forEach(file => {
      if (file.directory) existingDirs.add(file.directory);
    });
    console.log('Existing image directories:', [...existingDirs]);
  } catch (error) {
    console.error('Error reading directory information:', error);
  }
  
  // Process any stored files according to our environment
  processStoredFiles();
  
  // Verify the public directories exist
  verifyPublicDirectories();
  
  // If we're in development, add a button to manually export files
  if (process.env.NODE_ENV === 'development') {
    // Add a hidden button that can be triggered via developer console
    const exportBtn = document.createElement('button');
    exportBtn.id = 'export-files-btn';
    exportBtn.style.position = 'fixed';
    exportBtn.style.bottom = '10px';
    exportBtn.style.right = '10px';
    exportBtn.style.zIndex = '9999';
    exportBtn.style.display = 'none';
    exportBtn.textContent = 'Export Files';
    exportBtn.onclick = exportFilesToBuild;
    
    // Add to body after it's available
    window.addEventListener('DOMContentLoaded', () => {
      document.body.appendChild(exportBtn);
      console.log('File export button added (hidden). Run this to show it:');
      console.log('document.getElementById("export-files-btn").style.display = "block"');
    });
  }

  // In development, add debug tools to help with image issues
  if (process.env.NODE_ENV === 'development') {
    setTimeout(() => {
      addDebugTools();
    }, 1000); // Slight delay to ensure the DOM is ready
  }
};

const HomePage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { locations } = useData();
  const { t, language } = useLanguage();
  const { addContactSubmission } = useData();
  
  // State for the contact form
  const [contactForm, setContactForm] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  
  // Handle form input changes
  const handleContactInputChange = (e) => {
    const { name, value } = e.target;
    setContactForm(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when field is being edited
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  // Validate contact form
  const validateContactForm = () => {
    const errors = {};
    
    if (!contactForm.name.trim()) {
      errors.name = 'Имя обязательно';
    }
    
    if (!contactForm.email.trim()) {
      errors.email = 'Email обязателен';
    } else if (!/\S+@\S+\.\S+/.test(contactForm.email)) {
      errors.email = 'Пожалуйста, введите действительный email';
    }
    
    if (!contactForm.message.trim()) {
      errors.message = 'Сообщение обязательно';
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  // Handle contact form submission
  const handleContactSubmit = (e) => {
    e.preventDefault();
    
    if (validateContactForm()) {
      console.log('Submitting contact form:', contactForm);
      
      // Add submission to the data store
      addContactSubmission(contactForm)
        .then(result => {
          console.log('Contact form submission result:', result);
          
          // Reset form
          setContactForm({
            name: '',
            email: '',
            message: ''
          });
          
          // Show success message
          setFormSubmitted(true);
          
          // Hide success message after a few seconds
          setTimeout(() => {
            setFormSubmitted(false);
          }, 5000);
        })
        .catch(error => {
          console.error('Error submitting contact form:', error);
          alert('Произошла ошибка при отправке формы. Пожалуйста, попробуйте еще раз.');
        });
    }
  };

  const LocationCard = ({ image, name, name_bg, id }) => {
    // Create slug-based URL
    const locationUrl = getLocationUrl({ name });
    const { language } = useLanguage();
    
    // Use Bulgarian name if available and language is set to Bulgarian
    const displayName = language === 'bg' && name_bg ? name_bg : name;

    return (
      <Link to={locationUrl} className="block relative h-56 sm:h-64 rounded-lg overflow-hidden group shadow-sm hover:shadow-md transition-shadow">
        {image ? (
          <img 
            src={getImageUrl(image)} 
            alt={displayName} 
            className="w-full h-full object-cover transition-transform group-hover:scale-105"
            onError={(e) => {
              e.target.src = getPlaceholderImage('location');
            }}
          />
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center">
            <span className="text-gray-400">Нет изображения</span>
          </div>
        )}
        <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent">
          <div className="absolute bottom-4 sm:bottom-6 left-4 sm:left-6 text-white">
            <h3 className="text-xl sm:text-2xl font-semibold group-hover:text-emerald-300 transition-colors">{displayName}</h3>
            <div className="flex items-center mt-2 text-white/80">
              <MapPin className="w-4 h-4 mr-2" />
              <span>{t('viewProperties')}</span>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className="min-h-screen">
      {/* Header */}
      <header className="fixed w-full bg-white/90 backdrop-blur-sm z-50 shadow-sm">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <a href="#" className="font-lobster text-2xl text-emerald-600">
              BUILDVIND
            </a>

            <nav className="hidden md:flex items-center space-x-8">
              <Link to="/properties" className="text-gray-600 hover:text-emerald-600">
                {language === 'bg' ? 'Всички имоти' : 'Все объекты недвижимости'}
              </Link>
              <LanguageSwitcher />
            </nav>

            <div className="flex items-center md:hidden">
              <LanguageSwitcher />
              <Link to="/properties" className="ml-4 text-gray-600 hover:text-emerald-600">
                {language === 'bg' ? 'Всички имоти' : 'Все объекты недвижимости'}
              </Link>
            </div>
          </div>
        </div>
      </header>

      {/* Hero */}
      <div className="relative h-[calc(100vh-4rem)] min-h-[500px]">
        <img 
          src="https://i.imghippo.com/files/ZT6155RpY.jpeg"
          alt="Bulgaria Real Estate"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/40">
          <div className="container mx-auto px-4 h-full flex flex-col justify-center">
            <div className="max-w-3xl">
              <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold text-white mb-4 sm:mb-6 font-lobster">
                BUILDVIND
              </h1>
              <p className="text-xl sm:text-2xl text-white/90 mb-6 sm:mb-8">
                {t('realEstateInBulgaria')}
              </p>
              <a 
                href="#locations" 
                className="inline-block bg-emerald-600 hover:bg-emerald-700 text-white px-6 sm:px-8 py-3 rounded-lg font-semibold transition-all duration-300 transform hover:-translate-y-1"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById('locations').scrollIntoView({ behavior: 'smooth' });
                }}
              >
                {t('viewProperties')}
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Locations */}
      <section id="locations" className="py-12 sm:py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-8 sm:mb-12">{t('locations')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
            {locations.map((location) => (
              <LocationCard key={location.id} {...location} />
            ))}
          </div>
        </div>
      </section>

      {/* About */}
      <section id="about" className="py-12 sm:py-20 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="max-w-5xl mx-auto">
            <div className="flex flex-col md:flex-row gap-8 md:gap-12 mb-12">
              <div className="md:w-1/3">
                <img 
                  src="https://i.imghippo.com/files/nHW5806WjM.png" 
                  alt={t('aboutPerson')} 
                  className="w-full max-w-xs mx-auto md:max-w-none rounded-lg shadow-lg mb-4"
                />
                <h3 className="text-xl font-semibold text-center text-gray-800">{t('aboutPerson')}</h3>
              </div>
              <div className="md:w-2/3">
                <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6">{t('about')}:</h2>
                <div className="prose max-w-none text-gray-600 space-y-4">
                  <p>{t('aboutContentP1')}</p>
                  <p>{t('aboutContentP2')}</p>
                  <p>{t('aboutContentP3')}</p>
                </div>
              </div>
            </div>
            
            <div className="grid grid-cols-3 gap-4 sm:gap-8 text-center">
              <div>
                <div className="text-emerald-600 font-bold text-2xl sm:text-3xl mb-2">13+</div>
                <div className="text-gray-600 text-sm sm:text-base">{t('yearsOfExperience')}</div>
              </div>
              <div>
                <div className="text-emerald-600 font-bold text-2xl sm:text-3xl mb-2">6</div>
                <div className="text-gray-600 text-sm sm:text-base">{t('locationsCount')}</div>
              </div>
              <div>
                <div className="text-emerald-600 font-bold text-2xl sm:text-3xl mb-2">100+</div>
                <div className="text-gray-600 text-sm sm:text-base">{t('propertiesCount')}</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact */}
      <section id="contact" className="py-12 sm:py-20">
        <div className="container mx-auto px-4">
          <div className="max-w-4xl mx-auto bg-white rounded-lg sm:rounded-xl shadow-md sm:shadow-lg p-6 sm:p-8">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-6 sm:mb-8 text-center">{t('contactUs')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
              <div>
                <div className="space-y-4 mb-6 sm:mb-8">
                  <div className="flex items-center text-gray-600">
                    <Phone className="w-5 h-5 mr-3 text-emerald-600 flex-shrink-0" />
                    <span>+359 889 224 687</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <Mail className="w-5 h-5 mr-3 text-emerald-600 flex-shrink-0" />
                    <span className="break-all">irinabervina@gmail.com</span>
                  </div>
                </div>
                <img 
                  src="https://i.imghippo.com/files/HGV4010.png"
                  alt="Contact us"
                  className="rounded-lg w-full h-40 sm:h-48 object-cover"
                />
              </div>
              <form className="space-y-4" onSubmit={handleContactSubmit}>
                {formSubmitted && (
                  <div className="p-3 bg-emerald-50 text-emerald-700 rounded-lg text-sm">
                    Спасибо! Ваше сообщение отправлено.
                  </div>
                )}
                <div>
                  <input 
                    type="text" 
                    name="name"
                    value={contactForm.name}
                    onChange={handleContactInputChange}
                    placeholder={t('yourName')}
                    className={`w-full px-4 py-3 rounded-lg border ${
                      formErrors.name ? 'border-red-500' : 'border-gray-200'
                    } focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none`}
                  />
                  {formErrors.name && (
                    <p className="mt-1 text-red-500 text-sm">{formErrors.name}</p>
                  )}
                </div>
                <div>
                  <input 
                    type="email" 
                    name="email"
                    value={contactForm.email}
                    onChange={handleContactInputChange}
                    placeholder={t('yourEmail')}
                    className={`w-full px-4 py-3 rounded-lg border ${
                      formErrors.email ? 'border-red-500' : 'border-gray-200'
                    } focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none`}
                  />
                  {formErrors.email && (
                    <p className="mt-1 text-red-500 text-sm">{formErrors.email}</p>
                  )}
                </div>
                <div>
                  <textarea 
                    rows="4" 
                    name="message"
                    value={contactForm.message}
                    onChange={handleContactInputChange}
                    placeholder={t('yourMessage')}
                    className={`w-full px-4 py-3 rounded-lg border ${
                      formErrors.message ? 'border-red-500' : 'border-gray-200'
                    } focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none`}
                  ></textarea>
                  {formErrors.message && (
                    <p className="mt-1 text-red-500 text-sm">{formErrors.message}</p>
                  )}
                </div>
                <button 
                  type="submit"
                  className="w-full bg-emerald-600 hover:bg-emerald-700 text-white py-3 rounded-lg font-semibold transition-colors"
                >
                  {t('send')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-8 sm:py-10">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="mb-6 md:mb-0 text-center md:text-left">
              <h2 className="font-lobster text-2xl text-emerald-400 mb-2">BUILDVIND</h2>
              <p className="text-white/70 text-sm">{t('realEstateInBulgaria')}</p>
            </div>
            <div className="flex flex-wrap justify-center gap-4 sm:gap-8">
              <a href="#" className="text-white/80 hover:text-emerald-400">{t('home')}</a>
              <a href="#locations" className="text-white/80 hover:text-emerald-400">{t('locations')}</a>
              <a href="#about" className="text-white/80 hover:text-emerald-400">{t('about')}</a>
              <a href="#contact" className="text-white/80 hover:text-emerald-400">{t('contact')}</a>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-6 sm:mt-8 pt-6 sm:pt-8 text-center text-white/60 text-sm">
            &copy; {new Date().getFullYear()} BUILDVIND. {t('allRightsReserved')}.
          </div>
        </div>
      </footer>
    </div>
  );
};

function App() {
  // Initialize image directories when the app loads
  useEffect(() => {
    initializeImageDirectories();
    
    // Initialize database if needed
    initializeDatabase().then(success => {
      if (success) {
        console.log('Database initialization check completed.');
      } else {
        console.warn('Database initialization check failed.');
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <DataProvider>
          <LanguageProvider>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<HomePage />} />
              <Route path="/properties" element={<AllPropertiesPage />} />
              
              {/* Test Routes */}
              <Route path="/testsupabase" element={<TestSupabasePage />} />
              <Route path="/test-storage" element={<StorageTestPage />} />
              
              {/* Content Routes */}
              <Route path="/:locationSlug" element={<LocationDetailPage />} />
              <Route path="/:locationSlug/:propertySlug" element={<RealEstateDetailPage />} />
              
              {/* Explicit ID Routes */}
              <Route path="/locations/:id" element={<LocationDetailPage />} />
              <Route path="/properties/:id" element={<RealEstateDetailPage />} />
              
              {/* Auth Routes */}
              <Route path="/admin/login" element={<LoginPage />} />
              
              {/* Protected Admin Routes */}
              <Route path="/admin" element={
                <ProtectedRoute>
                  <AdminLayout />
                </ProtectedRoute>
              }>
                <Route index element={<AdminDashboard />} />
                <Route path="locations" element={<LocationsPage />} />
                <Route path="locations/add" element={<LocationFormPage />} />
                <Route path="locations/edit/:id" element={<LocationFormPage />} />
                <Route path="real-estates" element={<RealEstatesPage />} />
                <Route path="real-estates/add" element={<RealEstateFormPage />} />
                <Route path="real-estates/edit/:id" element={<RealEstateFormPage />} />
                <Route path="uploads" element={<UploadMonitorPage />} />
                <Route path="contact-submissions" element={<ContactSubmissionsPage />} />
              </Route>
            </Routes>
          </LanguageProvider>
        </DataProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;