import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { preloadImages, refreshImages } from './utils/deploymentUtils';
import { initStorage, mergeWithServerFiles } from './utils/storageUtils';

// Add additional CSS for our app
const style = document.createElement('style');
style.textContent = `
  @import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
  
  .font-lobster {
    font-family: 'Lobster', cursive;
  }
  
  .line-clamp-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  /* Language switcher styles */
  .lang-switch {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    transition: all 0.2s;
  }
  
  .lang-switch:hover {
    transform: translateY(-1px);
  }
  
  /* Add styling for admin panel */
  .admin-panel {
    font-family: 'Roboto', 'Arial', sans-serif;
  }
`;
document.head.appendChild(style);

// Initialize the app with stored file data
const initializeAppData = async () => {
  console.log(`Initializing app in ${process.env.NODE_ENV} mode`);
  
  // Initialize storage system first
  initStorage();
  
  try {
    let serverFiles = [];
    let appDataLoaded = false;
    
    // Try to load files from different sources based on environment
    if (process.env.NODE_ENV === 'production') {
      try {
        // First try to load the comprehensive app-data.json
        const appDataResponse = await fetch('/app-data.json');
        
        if (appDataResponse.ok) {
          const appData = await appDataResponse.json();
          console.log(`Loaded app data version ${appData.version} with ${appData.files.length} files`);
          
          // This combined file has everything we need
          serverFiles = appData.files || [];
          appDataLoaded = true;
        } else {
          console.warn('Could not load app-data.json, trying alternative sources');
          
          // Fall back to loading from separate files
          
          // First try to load the file manifest which contains production paths
          const manifestResponse = await fetch('/file-manifest.json');
          
          if (manifestResponse.ok) {
            const fileManifest = await manifestResponse.json();
            console.log(`Loaded file manifest with ${fileManifest.length} files`);
            
            // Now try to load the full exported files with data URLs
            const filesResponse = await fetch('/exported-files.json');
            
            if (filesResponse.ok) {
              const exportedFiles = await filesResponse.json();
              console.log(`Loaded ${exportedFiles.length} files with data`);
              serverFiles = exportedFiles;
            } else {
              console.warn('Could not load exported files data, using manifest only');
              
              // If we only have the manifest, convert it to a format usable by our utilities
              serverFiles = fileManifest.map(item => ({
                path: item.originalPath,
                productionPath: item.productionPath,
                name: item.name,
                type: item.type,
                size: item.size,
                fileExists: item.fileExists,
                updatedAt: item.updatedAt
              }));
            }
          } else {
            console.warn('No file manifest found, trying exported-files.json directly');
            
            // Fallback to just loading exported-files.json
            const filesResponse = await fetch('/exported-files.json');
            if (filesResponse.ok) {
              serverFiles = await filesResponse.json();
              console.log(`Loaded ${serverFiles.length} files directly`);
            }
          }
        }
      } catch (error) {
        console.warn('Could not load files data:', error);
      }
    }
    
    // Merge server files with local storage, this ensures we don't lose data
    // and gives users a shared experience since they all get the same server files
    const mergedFiles = await mergeWithServerFiles(serverFiles);
    console.log(`App initialized with ${mergedFiles.length} files`);
    
    // Preload all images to ensure they're available
    preloadImages();
    
    // Schedule periodic refresh of images to ensure they stay loaded
    if (process.env.NODE_ENV === 'production') {
      setInterval(() => {
        refreshImages();
      }, 60000); // Refresh every minute
    }
    
    // Add a check to prevent unexpected data loss
    window.addEventListener('beforeunload', () => {
      // Make sure we preserve a copy of the files in localStorage
      const currentFiles = localStorage.getItem('uploadedFiles');
      if (!currentFiles) {
        console.warn('Files data missing, restoring from backup');
        const backup = sessionStorage.getItem('uploadedFiles_backup');
        if (backup) {
          localStorage.setItem('uploadedFiles', backup);
        }
      }
    });
    
    // Create a backup copy in sessionStorage
    sessionStorage.setItem('uploadedFiles_backup', localStorage.getItem('uploadedFiles') || '[]');
    
    // Add a periodic backup to sessionStorage
    setInterval(() => {
      const currentFiles = localStorage.getItem('uploadedFiles');
      if (currentFiles) {
        sessionStorage.setItem('uploadedFiles_backup', currentFiles);
        console.log('Created backup of file data');
      }
    }, 300000); // Every 5 minutes
  } catch (error) {
    console.error('Error during app initialization:', error);
  }
};

// Run initialization
initializeAppData().catch(error => {
  console.error('Error during app initialization:', error);
});

// Error handling
const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    const handleError = () => setHasError(true);
    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  if (hasError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-6 bg-gray-50 text-center">
        <h2 className="text-2xl font-bold text-red-600 mb-4">Что-то пошло не так</h2>
        <p className="text-gray-600 mb-6">Произошла ошибка при загрузке приложения.</p>
        <button 
          onClick={() => window.location.reload()}
          className="px-6 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700"
        >
          Обновить страницу
        </button>
      </div>
    );
  }

  return children;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);