import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { useData } from '../../hooks/useData';
import { v4 as uuidv4 } from 'uuid';
import FileUploader from '../../components/FileUploader';
import { useLanguage } from '../../hooks/useLanguage';

const LocationFormPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { locations, addLocation, updateLocation } = useData();
  const { t } = useLanguage();
  
  const [formData, setFormData] = useState({
    name: '',
    name_bg: '', // Bulgarian name
    image: ''
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePath, setImagePath] = useState('');
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const isEditMode = !!id;
  
  useEffect(() => {
    if (isEditMode) {
      const locationToEdit = locations.find(loc => loc.id === id);
      if (locationToEdit) {
        setFormData({
          name: locationToEdit.name,
          name_bg: locationToEdit.name_bg || '', // Get Bulgarian name if exists
          image: locationToEdit.image || ''
        });
      } else {
        navigate('/admin/locations', { replace: true });
      }
    }
  }, [id, locations, isEditMode, navigate]);
  
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Название обязательно';
    }
    
    if (!isEditMode && !selectedImage && !formData.image) {
      newErrors.image = 'Изображение обязательно';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when field is being edited
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };
  
  const handleFileSelect = (file, path) => {
    setSelectedImage(file);
    setImagePath(path);
    
    // Update the form data with the new image path
    setFormData(prev => ({
      ...prev,
      image: path
    }));
    
    // Clear any image error
    if (errors.image) {
      setErrors(prev => ({
        ...prev,
        image: ''
      }));
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (validateForm()) {
      setIsSubmitting(true);
      
      try {
        // First check if file upload is still in progress
        const fileUploadInProgress = document.querySelector('button:disabled[type="button"]');
        if (fileUploadInProgress) {
          console.log('Waiting for file upload to complete...');
          setErrors(prev => ({
            ...prev,
            image: 'Дождитесь завершения загрузки изображения'
          }));
          setIsSubmitting(false);
          return;
        }
        
        // Ensure image path is properly formatted
        let formattedImage = formData.image;
        if (formattedImage && !formattedImage.startsWith('/') && !formattedImage.startsWith('http') && !formattedImage.startsWith('data:')) {
          formattedImage = `/${formattedImage}`;
        }
        
        // Create the data to save
        const updatedData = {
          ...formData,
          image: formattedImage
        };
        
        console.log('Saving location with data:', updatedData);
        
        // Save the location and store the result
        let result;
        if (isEditMode) {
          result = await updateLocation(id, updatedData);
        } else {
          result = await addLocation(updatedData);
        }
        
        // Check if the save was successful
        if (!result) {
          console.error('Failed to save location, received null result');
          setErrors(prev => ({
            ...prev,
            form: 'Ошибка сохранения. Пожалуйста, попробуйте еще раз.'
          }));
          setIsSubmitting(false);
          return;
        }
        
        console.log('Location saved successfully:', result);
        
        // Redirect to locations list
        navigate('/admin/locations');
      } catch (error) {
        console.error('Error saving location:', error);
        setErrors(prev => ({
          ...prev,
          form: `Ошибка сохранения: ${error.message || 'Пожалуйста, попробуйте еще раз.'}`
        }));
        setIsSubmitting(false);
      }
    }
  };
  
  return (
    <div>
      <div className="mb-8">
        <Link 
          to="/admin/locations" 
          className="inline-flex items-center text-emerald-600 hover:text-emerald-800"
        >
          <ChevronLeft size={16} className="mr-1" />
          {t('back')}
        </Link>
      </div>
      
      <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-100">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">
          {isEditMode ? t('editLocation') : t('addNewLocation')}
        </h1>
        
        <form onSubmit={handleSubmit}>
          {errors.form && (
            <div className="mb-4 p-4 bg-red-50 text-red-600 rounded-md text-sm">
              {errors.form}
            </div>
          )}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="name">
              {t('locationName')} (RU)*
            </label>
            <input
              id="name"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
              className={`w-full px-4 py-2 rounded-lg border ${
                errors.name ? 'border-red-500' : 'border-gray-200'
              } focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none`}
              placeholder="Например: Созополь"
            />
            {errors.name && (
              <p className="mt-1 text-red-500 text-sm">{errors.name}</p>
            )}
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="name_bg">
              {t('locationName')} (БГ)
            </label>
            <input
              id="name_bg"
              name="name_bg"
              type="text"
              value={formData.name_bg}
              onChange={handleChange}
              className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 outline-none"
              placeholder="Например: Созопол (незадължително)"
            />
            <p className="mt-1 text-xs text-gray-500">
              Оставьте пустым, чтобы использовать русское название для болгарской версии
            </p>
          </div>
          
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="image">
              {t('image')}*
            </label>
            <FileUploader 
              onFileSelect={handleFileSelect}
              initialImage={formData.image}
              type="locations"
            />
            {errors.image && (
              <p className="mt-1 text-red-500 text-sm">{errors.image}</p>
            )}
          </div>
          
          <div className="flex justify-end space-x-3">
            <Link
              to="/admin/locations"
              className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
              onClick={(e) => {
                // Prevent default if the form is in process of submitting
                if (isSubmitting) {
                  e.preventDefault();
                }
              }}
            >
              {t('cancel')}
            </Link>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors disabled:bg-emerald-300 disabled:cursor-not-allowed"
            >
              {isSubmitting ? t('saving') : isEditMode ? t('save') : t('addLocation')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LocationFormPage; 