/**
 * Utility functions for handling image paths and URLs
 */
import { getFileUrl, getFileByPath } from './storageUtils';
import { findWorkingImagePath } from './deploymentUtils';
import { supabase, STORAGE_BUCKET } from './supabaseClient';

// In-memory cache for image paths that have been resolved
const resolvedPathCache = new Map();

/**
 * Normalize an image path to ensure it's usable as a URL
 * 
 * @param {string} imagePath - The path to the image
 * @returns {string|null} - The normalized URL or null if no path was provided
 */
export const getImageUrl = (imagePath) => {
  if (!imagePath) return null;
  
  // If it's already an absolute URL or data URL, return as is
  if (imagePath.startsWith('http://') || 
      imagePath.startsWith('https://') ||
      imagePath.startsWith('data:')) {
    return imagePath;
  }
  
  // Check cache first
  if (resolvedPathCache.has(imagePath)) {
    return resolvedPathCache.get(imagePath);
  }

  // Assume it's a Supabase path and try to get the public URL
  // Ensure path doesn't start with / for Supabase URL generation
  const supabasePath = imagePath.startsWith('/') ? imagePath.substring(1) : imagePath;
  
  try {
    const { data } = supabase.storage
      .from(STORAGE_BUCKET)
      .getPublicUrl(supabasePath);
      
    if (data?.publicUrl) {
      resolvedPathCache.set(imagePath, data.publicUrl);
      return data.publicUrl;
    }
  } catch (error) {
    // Log error but don't crash, maybe return the original path as fallback
    console.error(`Error getting Supabase public URL for ${supabasePath}:`, error);
  }
  
  // If Supabase URL fails, return the original path as a last resort
  // It might be a relative path usable in development or specific setups
  console.warn(`Could not resolve Supabase URL for ${imagePath}, returning original path.`);
  resolvedPathCache.set(imagePath, imagePath); // Cache the original path to avoid retrying Supabase
  return imagePath;
};

/**
 * Get a placeholder image when the actual image isn't available
 * 
 * @param {string} type - The type of image ('locations' or 'properties')
 * @returns {string} - The URL of a placeholder image
 */
export const getPlaceholderImage = (type = 'property') => {
  // Return placeholder images appropriate for each type
  if (type === 'location') {
    return 'https://lh3.googleusercontent.com/85zcbsSrrLjfjRH2NQfAorOFtYVRuVlGbgIPKBPg4AteD17CZgeFnsBQ_oerldYCmvY7OHLVWUQB6Tam_DI0oO35QTzGl0NjR21ljAHLvujfSj_0';
  } else {
    return 'https://lh3.googleusercontent.com/85zcbsSrrLjfjRH2NQfAorOFtYVRuVlGbgIPKBPg4AteD17CZgeFnsBQ_oerldYCmvY7OHLVWUQB6Tam_DI0oO35QTzGl0NjR21ljAHLvujfSj_0';
  }
};

/**
 * Create a srcSet for responsive images
 * 
 * @param {string} imagePath - The base image path
 * @returns {string} - The srcSet attribute value
 */
export const createSrcSet = (imagePath) => {
  if (!imagePath) return '';
  
  const baseUrl = getImageUrl(imagePath);
  if (!baseUrl) return '';
  
  // For data URLs we don't need a srcSet
  if (baseUrl.startsWith('data:')) {
    return baseUrl;
  }
  
  // In a real application, you would have different sized images
  // This is just a placeholder for demonstration
  return `${baseUrl} 1x, ${baseUrl} 2x`;
};

/**
 * Generate a placeholder image element
 * 
 * @param {string} text - The text to display on the placeholder
 * @param {string} className - CSS classes to apply to the div
 * @returns {JSX.Element} - A React element for the placeholder
 */
export const ImagePlaceholder = ({ text = 'Нет изображения', className = 'w-full h-full bg-gray-200' }) => {
  return (
    <div className={`${className} flex items-center justify-center`}>
      <span className="text-gray-400">{text}</span>
    </div>
  );
}; 