import React, { useState } from 'react';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import { Home, MapPin, Building, LogOut, Menu, X, UploadCloud, Mail } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { useLanguage } from '../../hooks/useLanguage';
import { useData } from '../../hooks/useData';

const AdminLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { logout } = useAuth();
  const { t } = useLanguage();
  const { contactSubmissions } = useData();
  const navigate = useNavigate();
  
  // Count unread contact submissions
  const unreadCount = contactSubmissions.filter(submission => !submission.isRead).length;

  const handleLogout = () => {
    logout();
    navigate('/admin/login');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      {/* Mobile sidebar toggle */}
      <div className="md:hidden fixed top-4 left-4 z-50">
        <button 
          onClick={toggleSidebar}
          className="p-2 rounded-md bg-white shadow-md"
        >
          {isSidebarOpen ? <X size={20} /> : <Menu size={20} />}
        </button>
      </div>

      {/* Sidebar overlay for mobile */}
      {isSidebarOpen && (
        <div 
          className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}

      {/* Sidebar */}
      <aside 
        className={`fixed md:relative z-50 md:z-auto w-72 h-full bg-white shadow-lg transition-all duration-300 ease-in-out ${
          isSidebarOpen ? 'left-0' : '-left-72 md:left-0'
        }`}
      >
        <div className="p-6 border-b border-gray-100">
          <h1 className="text-2xl font-bold text-emerald-600">BUILDVIND</h1>
          <p className="text-gray-500 text-sm mt-2">{t('adminPanel')}</p>
        </div>
        
        <nav className="mt-8">
          <ul className="space-y-2 px-4 pb-20">
            <li>
              <NavLink 
                to="/admin" 
                end
                className={({ isActive }) => `
                  flex items-center px-5 py-3 rounded-lg transition-colors
                  ${isActive ? 'bg-emerald-50 text-emerald-600' : 'text-gray-600 hover:bg-gray-50'}
                `}
                onClick={() => setIsSidebarOpen(false)}
              >
                <Home size={18} className="mr-3" />
                <span>{t('dashboard')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/admin/locations" 
                className={({ isActive }) => `
                  flex items-center px-5 py-3 rounded-lg transition-colors
                  ${isActive ? 'bg-emerald-50 text-emerald-600' : 'text-gray-600 hover:bg-gray-50'}
                `}
                onClick={() => setIsSidebarOpen(false)}
              >
                <MapPin size={18} className="mr-3" />
                <span>{t('locations')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/admin/real-estates" 
                className={({ isActive }) => `
                  flex items-center px-5 py-3 rounded-lg transition-colors
                  ${isActive ? 'bg-emerald-50 text-emerald-600' : 'text-gray-600 hover:bg-gray-50'}
                `}
                onClick={() => setIsSidebarOpen(false)}
              >
                <Building size={18} className="mr-3" />
                <span>{t('viewProperties')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/admin/uploads" 
                className={({ isActive }) => `
                  flex items-center px-5 py-3 rounded-lg transition-colors
                  ${isActive ? 'bg-emerald-50 text-emerald-600' : 'text-gray-600 hover:bg-gray-50'}
                `}
                onClick={() => setIsSidebarOpen(false)}
              >
                <UploadCloud size={18} className="mr-3" />
                <span>{t('uploads')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/admin/contact-submissions" 
                className={({ isActive }) => `
                  flex items-center justify-between px-5 py-3 rounded-lg transition-colors
                  ${isActive ? 'bg-emerald-50 text-emerald-600' : 'text-gray-600 hover:bg-gray-50'}
                `}
                onClick={() => setIsSidebarOpen(false)}
              >
                <div className="flex items-center">
                  <Mail size={18} className="mr-3" />
                  <span>{t('contactSubmissions')}</span>
                </div>
                {unreadCount > 0 && (
                  <span className="bg-amber-500 text-white text-xs py-1 px-2 rounded-full">
                    {unreadCount}
                  </span>
                )}
              </NavLink>
            </li>
          </ul>
        </nav>
        
        <div className="absolute bottom-0 w-full p-5 border-t border-gray-100 bg-white">
          <button 
            onClick={handleLogout}
            className="flex items-center justify-center w-full px-4 py-2.5 text-emerald-600 hover:bg-emerald-50 rounded-lg transition-colors"
          >
            <LogOut size={18} className="mr-2" />
            <span>{t('logout')}</span>
          </button>
        </div>
      </aside>
      
      {/* Main content */}
      <main className="flex-1 p-0 ml-0 md:ml-72 overflow-x-hidden max-w-full">
        <div className="p-6 lg:p-8 max-w-7xl mx-auto">
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default AdminLayout; 