import React, { useState, useRef, useEffect } from 'react';
import { Upload, X, CheckCircle } from 'lucide-react';
import { uploadFile } from '../utils/fileUploadService';
import { getImageUrl } from '../utils/imageUtils';

/**
 * Component for handling file uploads with preview
 * 
 * @param {Object} props
 * @param {Function} props.onFileSelect - Callback when file is selected
 * @param {string} props.initialImage - Initial image URL (optional)
 * @param {string} props.type - Type of image ('locations' or 'properties')
 * @param {Function} props.onRemove - Callback when remove button is clicked (optional)
 * @param {boolean} props.showRemoveButton - Whether to show remove button
 * @param {Function} props.onUploadStart - Callback when upload starts (optional)
 * @param {Function} props.onUploadError - Callback when upload fails (optional)
 * @param {boolean} props.isDisabled - Whether the uploader is disabled due to other uploads in progress
 */
const FileUploader = ({ 
  onFileSelect, 
  initialImage = '', 
  type = 'locations',
  onRemove,
  showRemoveButton = false,
  onUploadStart,
  onUploadError,
  isDisabled = false
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(initialImage || '');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const fileInputRef = useRef(null);

  // At the beginning of the component, add this effect to handle initial images
  useEffect(() => {
    if (initialImage) {
      // Make sure we display the correct URL for the initial image
      const imageUrl = getImageUrl(initialImage);
      if (imageUrl && imageUrl !== preview) {
        setPreview(imageUrl);
      }
    }
  }, [initialImage]);

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Reset states
    setUploadError('');
    setUploadSuccess(false);
    
    // Validate file type
    const fileType = file.type.toLowerCase();
    if (!fileType.startsWith('image/')) {
      setUploadError('Пожалуйста, выберите изображение (JPEG, PNG, GIF и т.д.)');
      return;
    }
    
    // Validate file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setUploadError('Файл слишком большой. Максимальный размер 5MB.');
      return;
    }

    // Set preview immediately using FileReader
    const reader = new FileReader();
    reader.onload = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);

    // Start upload process
    uploadImageFile(file);
  };

  // Upload the file
  const uploadImageFile = async (file) => {
    try {
      // Set uploading state and notify parent
      setIsUploading(true);
      if (onUploadStart) {
        onUploadStart();
      }

      // Create unique path
      const extension = file.name.split('.').pop().toLowerCase();
      const timestamp = new Date().getTime();
      const imageName = `${type}-${timestamp}-${Math.random().toString(36).substr(2, 5)}.${extension}`;
      const path = `${type}/${imageName}`;
      
      console.log(`Starting upload for ${path}...`);
      
      // Upload the file and get the result
      const result = await uploadFile(file, path);
      
      if (result && result.url) {
        setSelectedFile(file);
        setUploadSuccess(true);
        
        // Update the preview with the actual URL from Supabase
        setPreview(result.url);
        
        console.log(`Upload successful, URL: ${result.url}`);
        
        // Call the callback with file and path
        onFileSelect(file, result.url);
      } else {
        const error = 'Ошибка загрузки. Пожалуйста, попробуйте еще раз.';
        console.error('Upload failed with no result:', error);
        setUploadError(error);
        if (onUploadError) onUploadError(error);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      
      // Extract the specific error message if available
      let errorMsg = 'Ошибка загрузки файла. Пожалуйста, попробуйте еще раз.';
      
      if (error instanceof Error) {
        // If error already contains a more specific message, use it
        errorMsg = error.message;
      } else if (typeof error === 'string') {
        errorMsg = error;
      }
      
      // Add troubleshooting suggestion for common errors
      if (navigator.onLine === false) {
        errorMsg = 'Отсутствует подключение к интернету. Проверьте соединение и попробуйте снова.';
      } else if (errorMsg.includes('timeout') || errorMsg.includes('timed out')) {
        errorMsg = 'Время ожидания истекло. Проверьте скорость подключения и попробуйте снова.';
      } else if (errorMsg.includes('limit') || errorMsg.includes('size')) {
        errorMsg = 'Превышен лимит размера файла. Пожалуйста, используйте изображение меньшего размера.';
      } else if (errorMsg.includes('bucket') || errorMsg.includes('storage')) {
        errorMsg = 'Ошибка хранилища. Пожалуйста, попробуйте еще раз или обратитесь к администратору.';
      } else if (errorMsg.includes('permissions') || errorMsg.includes('permission') || errorMsg.includes('доступ')) {
        errorMsg = 'Недостаточно прав для загрузки файла. Пожалуйста, обратитесь к администратору.';
      } else if (type === 'locations') {
        // Special handling for location images
        errorMsg = 'Ошибка загрузки изображения локации. Проверьте подключение и попробуйте еще раз.';
      }
      
      console.error(`Upload error converted to user message: ${errorMsg}`);
      setUploadError(errorMsg);
      if (onUploadError) onUploadError(errorMsg);
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemove = () => {
    if (isUploading) return;
    
    setSelectedFile(null);
    setPreview('');
    setUploadError('');
    setUploadSuccess(false);
    
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    
    if (onRemove) {
      onRemove();
    }
  };

  return (
    <div className="w-full">
      <div className="flex items-center space-x-3 mb-2">
        <button
          type="button"
          onClick={() => fileInputRef.current?.click()}
          className={`px-4 py-2 border border-gray-300 rounded-lg flex items-center hover:bg-gray-50 transition-colors ${(isUploading || isDisabled) ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isUploading || isDisabled}
        >
          <Upload size={16} className="mr-2" />
          {isUploading ? 'Загрузка...' : 'Выбрать файл'}
        </button>
        {selectedFile && (
          <span className="text-sm text-gray-600 truncate">
            {selectedFile.name}
          </span>
        )}
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="hidden"
          disabled={isUploading || isDisabled}
        />
        
        {showRemoveButton && preview && (
          <button
            type="button"
            onClick={handleRemove}
            className="p-2 rounded-md hover:bg-gray-100"
            disabled={isUploading || isDisabled}
          >
            <X size={18} className="text-gray-500" />
          </button>
        )}
        
        {uploadSuccess && (
          <div className="flex items-center text-green-600">
            <CheckCircle size={16} className="mr-1" />
            <span className="text-sm">Загружено</span>
          </div>
        )}
      </div>

      {uploadError && (
        <p className="mt-1 text-red-500 text-sm">{uploadError}</p>
      )}

      {preview && (
        <div className="mt-2 border border-gray-200 rounded-lg overflow-hidden h-48 w-full">
          <img 
            src={preview}
            alt="Preview" 
            className="w-full h-full object-cover"
            onError={(e) => {
              e.target.src = 'https://via.placeholder.com/400x250?text=Изображение+недоступно';
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FileUploader; 